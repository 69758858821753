import "./comment.scss";

import { Button, message, Input } from "antd";
import { post } from "../../common/fetch";
import { useEffect, useState, useRef } from "react";
import { useQuery, intToChinese } from "../../common/utils";

import {
  PlusEvaluateItem,
  MinusEvaluateItem,
  plusScoreItem,
  minusScoreItem,
} from "./components/evaluateItem/evaluateItem";

interface evaluateFormProps {
  courseId: number;
  courseName: string;
  createTime: string;
  creator: number;
  evaluateType: number;
  formName: string;
  id: number;
  instituteId: string;
  reviser: number;
  scoreValue: number;
  status: number;
  updateTime: string;
  version: number;
}
interface evaluateItemProps {
  formId: number;
  fullItemName: string;
  id: number;
  name: string;
  plusScoreList?: plusScoreItem[];
  minusScoreList?: minusScoreItem[];
  scoreCalculateType: number;
  scoreValue: number;
  scoreValueText: string;
  showOrderInForm: number;
}

const { TextArea } = Input;

const Comment: React.FC = () => {
  const [totalScore, setTotalScore] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [evaluateFormInfo, setEvaluateFormInfo] = useState<evaluateFormProps>();
  const [evaluateListInfo, setEvaluateListInfo] = useState<evaluateItemProps[]>(
    []
  );

  let query = useQuery();
  const topicId = query.get("topicId");
  const projectId = query.get("projectId");
  const courseId = query.get("courseId");
  const evaluateFormId = query.get("evaluateFormId");

  const appraise: any = useRef(null);

  useEffect(() => {
    post(`/shop/api/evaluate/${courseId}/${evaluateFormId}`, {}, true).then(
      (res) => {
        if (res.code === 0) {
          const { courseId, courseName, evaluateInfo } = res.data;
          const { evaluateForm, evaluateItemList } = evaluateInfo;
          setEvaluateFormInfo({ ...evaluateForm, courseId, courseName });

          evaluateItemList.forEach((item: evaluateItemProps) => {
            if (item.scoreCalculateType === 1) {
              item.plusScoreList?.forEach((plusScore: plusScoreItem) => {
                plusScore.value = plusScore.scoreValue;
              });
            } else {
              item.minusScoreList?.forEach((minusScore: minusScoreItem) => {
                minusScore.value = 0;
              });
            }
          });
          calcScore(evaluateItemList);
          setEvaluateListInfo(evaluateItemList);
        }
      }
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    post(`/shop/api/evaluate/${courseId}/${evaluateFormId}`, {}, true).then(
      (res) => {
        if (res.code === 0) {
          const { courseId, courseName, evaluateInfo } = res.data;
          const { evaluateForm, evaluateItemList } = evaluateInfo;
          setEvaluateFormInfo({ ...evaluateForm, courseId, courseName });

          evaluateItemList.forEach((item: evaluateItemProps) => {
            if (item.scoreCalculateType === 1) {
              item.plusScoreList?.forEach((plusScore: plusScoreItem) => {
                plusScore.value = plusScore.scoreValue;
              });
            } else {
              item.minusScoreList?.forEach((minusScore: minusScoreItem) => {
                minusScore.value = 0;
              });
            }
          });
          calcScore(evaluateItemList);
          setEvaluateListInfo(evaluateItemList);
        }
      }
    );
  }, [courseId, evaluateFormId]);

  function calcScore(evaluateList: evaluateItemProps[]): void {
    let score: number = 0;
    evaluateList.forEach((item) => {
      if (item.scoreCalculateType === 1) {
        item.plusScoreList?.forEach((plusEvaluateItem) => {
          score += plusEvaluateItem?.value || 0;
        });
      } else {
        item.minusScoreList?.forEach((minusEvaluateItem) => {
          score -= minusEvaluateItem?.value || 0;
        });
      }
    });

    setTotalScore(score);
  }

  function onChangeValue(
    list: number,
    item: number,
    type: number,
    value: any
  ): void {
    setEvaluateListInfo((prevCount: evaluateItemProps[]) => {
      const newCount: evaluateItemProps[] = [...prevCount];
      let data: any =
        newCount[list].scoreCalculateType === 1
          ? newCount[list].plusScoreList?.[item]
          : newCount[list].minusScoreList?.[item];

      switch (type) {
        case 0:
          data.value = data.scoreValue;
          break;

        case 1:
          data.value = data.value | 0;
          data.value += 1;
          data.value =
            data.value > data.scoreValue ? data.scoreValue : data.value;
          break;

        case 2:
          data.value = data.value | 0;
          data.value -= 1;
          data.value = data.value < 0 ? 0 : data.value;
          break;

        case 3:
          data.value = 0;
          break;

        case 4:
          data.value = value;
          data.value =
            data.value > data.scoreValue ? data.scoreValue : data.value;
          data.value = data.value < 0 ? 0 : data.value;
          break;
      }
      calcScore(newCount);
      return newCount;
    });
  }

  /**
   * 保存按钮点击
   *
   */
  function onSubmitClickHandle() {
    const param: any = {
      evaluateRecord: {
        projectId: projectId || undefined,
        topicId: topicId || undefined,
        courseId: evaluateFormInfo?.courseId,
        formId: evaluateFormInfo?.id,
        comment: appraise.current.resizableTextArea.textArea.value,
      },
      evaluateRecordDetailList: [],
    };

    evaluateListInfo.forEach((item) => {
      if (item.scoreCalculateType === 1) {
        item.plusScoreList?.forEach((plusScore: plusScoreItem) => {
          param.evaluateRecordDetailList.push({
            scoreCalculateType: item.scoreCalculateType,
            itemId: item.id,
            plusScoreId: plusScore.id,
            score: plusScore.value,
          });
        });
      } else {
        item.minusScoreList?.forEach((minusScore: minusScoreItem) => {
          param.evaluateRecordDetailList.push({
            scoreCalculateType: item.scoreCalculateType,
            itemId: item.id,
            minusScoreId: minusScore.id,
            score: minusScore.value,
          });
        });
      }
    });

    setSubmitLoading(true);
    post("/shop/api/evaluate/record/save", param, true)
      .then((res) => {
        if (res.code === 0) {
          message.success("提交成功");
          // setTimeout(() => {
          //   window.opener = null;
          //   window.open("", "_self");
          //   window.close();
          // }, 3000);
        } else {
          message.error(res.msg);
        }
      })
      .catch((err) => {
        message.error("网络错误");
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  }

  return (
    <div className="comment">
      <div className="comment_rateview">
        <div className="comment_rateview_header">
          <p>{evaluateFormInfo?.formName}</p>
          <p>课程：{evaluateFormInfo?.courseName}</p>
        </div>
        <div className="comment_rateview_container">
          {evaluateListInfo.map((item: evaluateItemProps, index: number) => {
            return (
              <div className="comment_rateview_item" key={index}>
                <h5 className="comment_rateview_i_title">
                  {item.fullItemName}
                </h5>
                <ul>
                  {item.scoreCalculateType === 1
                    ? item.plusScoreList?.map(
                        (plusScore: plusScoreItem, plusScoreIndex: number) => {
                          return (
                            <PlusEvaluateItem
                              key={plusScoreIndex}
                              baseInfo={plusScore}
                              listIndex={index}
                              itemIndex={plusScoreIndex}
                              onChangeValue={onChangeValue}
                            />
                          );
                        }
                      )
                    : item.minusScoreList?.map(
                        (
                          minusScore: minusScoreItem,
                          minusScoreIndex: number
                        ) => {
                          return (
                            <MinusEvaluateItem
                              key={minusScoreIndex}
                              baseInfo={minusScore}
                              listIndex={index}
                              itemIndex={minusScoreIndex}
                              onChangeValue={onChangeValue}
                            />
                          );
                        }
                      )}
                </ul>
              </div>
            );
          })}

          <div className="comment_rateview_item">
            <h5 className="comment_rateview_i_title">
              {intToChinese(evaluateListInfo.length + 1)}、评语
            </h5>
            <div>
              <TextArea style={{ height: 100 }} ref={appraise} />
            </div>
          </div>
        </div>
      </div>
      <div className="comment_footer">
        <div className="comment_footer_wrapper">
          <span className="text">总分：{totalScore}分</span>
          <Button
            className="btn"
            type="primary"
            loading={submitLoading}
            onClick={onSubmitClickHandle}
          >
            提交
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Comment;
