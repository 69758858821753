/*
 * @Author: changcheng
 * @LastEditTime: 2022-08-08 13:37:19
 */
import { useEffect, useState } from "react";
import { post } from "../../../common/fetch";
import { Table, Button } from "antd";
interface _listProps {
  trainCertificateName: string; //证书名称
  trainCertificateNumber: string; //证书编号
  gainDate: string; //发放日期
  trainCertificateApplyId: number; //证书id
  projectId: number; //项目Id
}
export default function CertificateDownload(props:any) {
  const [certificateList, setCertificateList] = useState<_listProps[]>([]);
  // 获取证书列表
  const getDownList = () => {
    post("/shop/front/getTrainCertificateList", null, true).then((res) => {
      const { data } = res;
      setCertificateList(data);
    });
  };
  // 下载证书
  const downLoadCertifical = (it: _listProps) => {
    const { projectId, trainCertificateApplyId,trainCertificateName } = it;
    post(
      "/shop/front/getTrainCertificate",
      {
        projectId,
        trainCertificateApplyId,
        trainCertificateName,
        mobile:props.userInfo.mobile || ''
      },
      false
    ).then((res) => {
      const { data } = res;
      let a = document.createElement("a");
      a.setAttribute("download", "证书.png");
      a.href = data;
      a.click();
    });
  };
  const columns = [
    {
      title: "证书名称",
      dataIndex: "trainCertificateName",
      key: "trainCertificateName",
    },
    {
      title: "证书编号",
      dataIndex: "trainCertificateNumber",
      key: "trainCertificateNumber",
    },
    {
      title: "发放日期",
      dataIndex: "gainDate",
      key: "gainDate",
    },
    {
      title: "操作",
      key: "tags",
      render: (record: _listProps) => (
        <Button
          onClick={() => {
            downLoadCertifical(record);
          }}
          type="primary"
        >
          下载
        </Button>
      ),
    },
  ];
  useEffect(() => {
    getDownList();
  }, []);
  return (
    <div
      style={{
        padding: "0 0 20px 0",
      }}
    >
      <Table
        dataSource={certificateList}
        columns={columns}
        pagination={false}
      />
    </div>
  );
}
