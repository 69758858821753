/*
 * @Author: cc
 * @LastEditTime: 2024-04-07 18:18:51
 */
import { useState, useEffect } from "react";
import { Button, Breadcrumb, message, Modal } from "antd";
import project_bg from "../../img/project_bg.png";
import "./project-info.scss";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { FinishCourse } from "../../components/index";
import { IconName } from "@fortawesome/fontawesome-common-types";
import { post } from "../../common/fetch";
import { useQuery } from "../../common/utils";
import { useHistory } from "react-router-dom";
import courseImg from "../../img/course.svg";
import refresherImg from "../../img/refresher.svg";
import scheduleImg from "../../img/schedule.svg";
import SelectCourse from "./component/selectCourse";
import { UserInfoConfirm, UserOrderConfirm } from "../../components/index";
import defaultProjectCover from "../../img/project_default.jpg";
const { confirm } = Modal;
export type IconType = {
  iconStr: IconName;
};
export interface projectListInfo {
  // 课程名称
  title: string;
  // 主讲人
  teacher: string;
  // 单位
  employer: string;
  // 学时
  period: number;
  // 背景图
  cover: string;
  // 课程ID
  id: string;
  // 考试状态
  userStatus: number;
  // 课程类型
  requiredType: number;
  // 考试类型
  examType: number;
  // 数量
  amount: number;
}
interface trainClassInfo {
  // 价格
  amount: number;
  // 是否购买
  buyClassFlag: number;
  //
  certType: number;
  // 描述
  desc: string;
  examType: number;
  // 课程id
  id: string;
  // 名称
  name: string;
  notRequiredPeriod: number;
  noteType: number;
  // 需要完成的学时
  requiredPeriod: number;
  // 用户学习状态
  userStatus: number;
  // 价格
  salePrice: number;
  // 价格
  salePriceText: string;
  // 选修完成时长
  finishedNotRequiredPeriod: number;
  // 必修完成时长
  finishedRequiredPeriod: number;
}
// 考试状态
const examStatusArray = ["未开始", "学习中", "学习中", "已通过"];
// 申请证书状态
const applyCertificateStatusArr = ["审核通过", "待审核", "驳回", "返回修改"];
function ProjectInfo(props: any) {
  const query = useQuery();
  const history = useHistory();
  const courseId = query.get("courseId") as string;
  const [canBuyProjectList, setCanBuyProjectList] = useState(
    [] as projectListInfo[]
  );
  const [showUserConfirmModal, setShowUserConfirmModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [showSelectModal, setShowSelectModal] = useState(false);
  const [projectList, setProjectList] = useState([] as projectListInfo[]);
  const [trainClassInfo, setTrainClassInfo] = useState({} as trainClassInfo);
  const getProjectInfo = async () => {
    const url = `/shop/front/cm/train/class/${courseId}/info`;
    const {
      data: { canBuyProjectList, trainClassInfo },
    } = await post(url, {}, true);
    const { projectList } = trainClassInfo;
    setCanBuyProjectList(canBuyProjectList);
    setTrainClassInfo(trainClassInfo);
    setProjectList(projectList);
  };
  useEffect(() => {
    getProjectInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  /**
   * 检查用户信息是否完善
   */
  const checkUserInfo = async (amount: number) => {
    const { code } = await post(
      "/shop/front/cm/train/user/info/check",
      {},
      false
    );
    if (code === 2000) {
      setShowUserConfirmModal(true);
    } else {
      // 免费参加
      if (amount === 0) {
        joinFreeCourse();
      } else {
        setShowOrderModal(true);
      }
    }
  };
  /**
   * 参加免费课程
   */
  const joinFreeCourse = async () => {
    await post(
      `/shop/front/cm/train/class/${trainClassInfo.id}/free/join`,
      {},
      false
    );
    getProjectInfo();
  };
  /**
   * 申请证书
   */
  const applyCertificate = async () => {
    const { data } = await post(
      `/shop/front/cm/user/get/${trainClassInfo.id}/cert`,
      {},
      false
    );
    if (!data) {
      const {
        data: { status },
      } = await post(
        `/shop/front/cm/user/apply/${trainClassInfo.id}/cert`,
        {},
        false
      );
      // 证书已通过
      if (status === 1) {
        downLoadDiploma();
      } else {
        message.info(applyCertificateStatusArr[status - 1]);
      }
    } else if (data.status === 1) {
      confirm({
        title: "恭喜您已经通过培训和证书审核，请按以下流程进行证书领取",
        icon: <ExclamationCircleFilled />,
        content: (
          <div>
            <p>1、打开网站: https://www.zyjjgl.org.cn/</p>
            <p>2、在网站下方找到【证书查询及申领】并点击</p>
            <p>3、微信扫码登录</p>
            <p>4、登录后即可查到自己的证书了</p>
          </div>
        ),
        okText: "关闭",
        cancelText: "前往网站",
        onCancel() {
          window.open("https://www.zyjjgl.org.cn/");
        },
      });
    } else if (data.status === 2) {
      Modal.success({
        title: "领取证书",
        content: (
          <div>
            <h6>资格审核中</h6>
            <p>说明：证书将在培训结束后统一进行审核发放，请耐心等待</p>
          </div>
        ),
      });
    } else if (data.status === 3) {
      Modal.success({
        title: "领取证书",
        content: (
          <div>
            <p>您的证书申请已被驳回，有问题请联系中华中医药学会资讯详情。</p>
          </div>
        ),
      });
    }
  };
  // 下载文件
  const download = (url: string, name?: string) => {
    const filename: any = url.split("/").pop();
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute("download", name || filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  /**
   * 下载证书
   */
  const downLoadDiploma = async () => {
    const {
      data: { url },
    } = await await post(
      `/shop/front/cm/user/get/${trainClassInfo.id}/cert`,
      {},
      false
    );
    download(url);
  };
  return (
    <div className="payProjectInfo_box">
      <div className="project_header">
        <img className="header_img" src={project_bg} alt="" />
        <div className="project_title">
          <Breadcrumb
            separator="/"
            style={{ fontSize: "20px", marginBottom: "30px" }}
          >
            <Breadcrumb.Item href="/#/train">首页</Breadcrumb.Item>
            <Breadcrumb.Item>
              <span style={{ color: "#1890ff" }}>项目主页</span>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="project_detail_box">
            <div>
              <h2>{trainClassInfo.name}</h2>
              <p className="introduction">{trainClassInfo.desc}</p>
              {trainClassInfo.buyClassFlag === 0 && (
                <p>
                  <Button
                    danger
                    type="primary"
                    onClick={() => {
                      const { amount } = trainClassInfo;
                      checkUserInfo(amount);
                      // if (amount === 0) {
                      //   joinFreeCourse();
                      // } else {
                      //   checkUserInfo();
                      // }
                    }}
                  >
                    {trainClassInfo.amount === 0 ? "获取" : "购买"}
                  </Button>
                </p>
              )}
              <div className="courseLearn_progress">
                <div className="progress_content">
                  <p>
                    <img src={courseImg} alt="" />
                    <p className="course_type">必修课</p>
                  </p>
                  <div className="progress">
                    <p>需要完成{trainClassInfo.requiredPeriod}学时</p>
                    <p>已完成{trainClassInfo.finishedRequiredPeriod}学时</p>
                  </div>
                </div>
                <div className="progress_content">
                  <p>
                    <img src={refresherImg} alt="" />
                    <p className="course_type">选修课</p>
                  </p>
                  <div className="progress">
                    <p>需要完成{trainClassInfo.notRequiredPeriod}学时</p>
                    <p>已完成{trainClassInfo.finishedNotRequiredPeriod}学时</p>
                  </div>
                  <p className="progress">
                    <Button
                      type="primary"
                      onClick={() => {
                        setShowSelectModal(true);
                      }}
                      disabled={
                        canBuyProjectList.length === 0 ||
                        trainClassInfo.buyClassFlag === 0
                      }
                    >
                      选课
                    </Button>
                  </p>
                </div>
                <div className="progress_content">
                  <p>
                    <img src={scheduleImg} alt="" />
                    <p className="course_type">考核与证书</p>
                  </p>
                  <p className="progress">
                    {trainClassInfo.examType === 1 && (
                      <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                          if (trainClassInfo.userStatus === 2) {
                            history.push(
                              `/exam?partnerClassId=${
                                trainClassInfo.id
                              }&examType=${1}`
                            );
                          } else {
                            message.info("学习尚未完成");
                          }
                        }}
                        disabled={trainClassInfo.userStatus !== 2}
                      >
                        参加考试
                      </Button>
                    )}
                    {trainClassInfo.certType === 1 && (
                      <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                          applyCertificate();
                        }}
                        disabled={
                          !trainClassInfo.userStatus ||
                          trainClassInfo.userStatus < 3
                        }
                      >
                        申请证书
                      </Button>
                    )}
                  </p>
                </div>
              </div>
              {["Vq3QBDNk", "oVDob3BA"].includes(courseId) && (
                <div className="prompt">
                  <p> 学习说明：</p>
                  <p>
                    1、需要达到条件要求的必修/选修课时才可以进行项目考试，项目考试通过后进行证书申请。
                  </p>
                  <p>
                    2、单个课件完成，必修/选修学时不统计，完成整个课程才会获得学时。
                  </p>
                  <p>3、若课程/课件存在考试，考试通过才视为学习完成。</p>
                  <p>
                    4、证书发放将在项目结束后统一进行审核，证书申请后请耐心进行等待。
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="project_list">
            {projectList.map((item) => (
              <div
                className="course_content"
                onClick={async () => {
                  const { code } = await post(
                    "/shop/front/cm/train/user/info/check",
                    {},
                    false
                  );
                  if (code === 2000) {
                    setShowUserConfirmModal(true);
                  } else {
                    if (trainClassInfo.buyClassFlag === 0) {
                      const { amount } = trainClassInfo;
                      checkUserInfo(amount);
                    } else {
                      history.push(
                        `/platfromplay?classId=${trainClassInfo.id}&projectId=${item.id}&examType=${item.examType}`
                      );
                    }
                  }
                }}
              >
                <div style={{position:"relative"}}>
                  <img src={item.cover || defaultProjectCover} alt="" />
                { !item.cover&& <p className={!item.cover?'defaultCover course_title':'course_title'}  >{item.title}</p>}
                  {item.teacher && <p>主讲人：{item.teacher}</p>}
                  {item.employer && <p>单位：{item.employer}</p>}
                </div>
                <p className='course_title' >{item.title}</p>
                <p>
                  <span className="compulsoryCourse">
                    {item.requiredType === 2 ? "选修课" : "必修课"}
                  </span>
                  <span className="hours">{item.period}学时</span>
                </p>
                {/* {trainClassInfo.userStatus === 2 && (
                  <div className="finish_icon">
                    <FinishCourse text="学完" />
                  </div>
                )} */}
                <div
                  className={`learn_status ${
                    item.userStatus !== 2 ? "disabled" : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {examStatusArray[item.userStatus]}
                </div>
                {![0, 9].includes(item.examType) && (
                  <div
                    className={
                      item.userStatus === 2
                        ? "exam_status"
                        : "exam_status exam_disabled"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      if (item.userStatus === 2) {
                        history.push(
                          `/exam?partnerClassId=${courseId}&examType=${2}&projectId=${
                            item.id
                          }`
                        );
                      }
                    }}
                  >
                    {item.userStatus === 3 ? "已通过" : "开始考试"}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <SelectCourse
        showSelectModal={showSelectModal}
        setShowSelectModal={setShowSelectModal}
        canBuyProjectList={canBuyProjectList}
        classId={trainClassInfo.id}
        className={trainClassInfo.name}
      />
      <UserInfoConfirm
        showUserConfirmModal={showUserConfirmModal}
        setShowUserConfirmModal={setShowUserConfirmModal}
      />
      <UserOrderConfirm
        showOrderModal={showOrderModal}
        setShowOrderModal={setShowOrderModal}
        courseInfo={trainClassInfo}
      />
    </div>
  );
}
export default ProjectInfo;
