/*
 * @Author: changcheng
 * @LastEditTime: 2022-07-04 21:38:22
 */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { post } from "../../../../common/fetch";
import { Collapse, Modal, Pagination } from "antd";
interface propsType {
  id: number;
  setProjectCourseInfo: (arg: any) => void;
  courseSource: number;
  cmTrainClassId: string;
}
const { Panel } = Collapse;
const CourseTrainLog = <T extends propsType>(props: T) => {
  const { id, setProjectCourseInfo, courseSource, cmTrainClassId } = props;
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const [studyLogList, setStudyLogList] = useState<any>({
    courseWatchRecordList: []
  });
  const showCourseTrainDetail = (page: number) => {
    const url = courseSource === 1 ? "/shop/front/user/project/courseRecordList" : "/shop/front/user/project/cm/train/courseRecordList"
    let data = {}
    if (courseSource === 1) {
      data = { projectId: id, page: { currentPage: page ? page : currentPage, pageSize: pageSize } }
    } else {
      data = { projectId: id, currentPage: page ? page : currentPage, pageSize: pageSize, trainClassId: cmTrainClassId }
    }
    post(
      url,
      data,
      true
    ).then((res) => {
      const { data } = res;
      setStudyLogList(data);
      setTotal(data.page.totalResultSize)
      setCurrentPage(data.page.currentPage)
    });
  };
  function onChange(page: number) {
    setCurrentPage(page)
    showCourseTrainDetail(page)
  }
  useEffect(() => {
    showCourseTrainDetail(currentPage);
  }, []);
  return (
    <div style={{ background: "#fff", padding: "20px" }}>
      <h5>培训详情<span style={{ float: "right", cursor: "pointer", color: "#2a82e4" }} onClick={() => {
        setProjectCourseInfo({
          type: "course",
          projectId: 0,
          show: false,
        });
      }}>返回</span></h5>
      <Collapse defaultActiveKey={["0"]}>
        <Panel header={studyLogList.projectName} key="0">
          <div >
            {courseSource === 1 && studyLogList.courseWatchRecordList.map((it: any, idx: number) => (
              <p key={idx} style={{ marginTop: "10px", display: "flex", justifyContent: "space-around" }}>
                <span style={{ flex: "2", overflow: "hidden", textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: "0 10px" }}>课程名称：{it.courseName}</span>
                <span style={{ flex: "1" }}>课程时长：{it.courseDurationText}</span>
                <span style={{ flex: "1" }}>学习进度：{it.courseLearnProgressText}</span>
              </p>
            ))}
            {courseSource === 2 && studyLogList.courseWatchRecordList.map((it: any, idx: number) => (
              <p key={idx} style={{ marginTop: "10px", display: "flex", justifyContent: "space-around" }}>
                <span style={{ flex: "2", overflow: "hidden", textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: "0 10px" }}>课程名称：{it.title}</span>
                <span style={{ flex: "1" }}>课程时长：{it.durationText}</span>
                <span style={{ flex: "1" }}>学习进度：{it.studyFinishRate}</span>
              </p>
            ))}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Pagination total={total} current={currentPage} pageSize={pageSize} onChange={(page) => onChange(page)} />
            </div>
          </div>
        </Panel>

      </Collapse>
    </div>
  );
};
export { CourseTrainLog };
