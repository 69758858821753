import logo from "../img/logo_header.png";
const InitProjectType = {
    showLogoFlag:1,
    showIndexUrlFlag:1,
    showPersonCenterFlag:1,
    projectAllPathLogo:logo,
  };
  export const projectInfo = (
    state = InitProjectType,
    action: {
      type: string;
      payLoad: {
        showLogoFlag?: number;
        showIndexUrlFlag?: number;
        showPersonCenterFlag?:number;
        projectAllPathLogo:any
      };
    }
  ) => {
    switch (action.type) {
      case "CHANGE_PROJECTINFO":
        return {
          ...action.payLoad,
        };
      default:
        return state;
    }
  };
  