/*
 * @Author: cc
 * @LastEditTime: 2021-04-25 17:07:15
 */
/*
 * @Author: cc
 * @LastEditTime: 2021-04-14 17:24:01
 */
const ininNavStatus = {
  show: false,
};
export const navOptions = (
  state = ininNavStatus,
  action: { show: boolean; type: string }
) => {
  switch (action.type) {
    case "CHANGE_NAVSTATUS":
      return {
        show: action.show,
      };
    default:
      return state;
  }
};
