import { Button, Input, InputNumber } from "antd";

export interface plusScoreItem {
  content: string;
  createTime: string;
  formId: number;
  fullContent: string;
  id: number;
  itemId: number;
  scoreValue: number;
  scoreValueText: string;
  showOrder: number;
  updateTime: string;
  value?: number;
}

export interface minusScoreItem {
  content: string;
  createTime: string;
  formId: number;
  fullContent: string;
  id: number;
  itemId: number;
  scoreValue: number;
  scoreValueText: string;
  showOrder: number;
  updateTime: string;
  value?: number;
}

type plusPropsType = {
  baseInfo: plusScoreItem;
  listIndex: number;
  itemIndex: number;
  onChangeValue(list: number, item: number, type: number, value?: number): void;
};

type minusPropsType = {
  baseInfo: minusScoreItem;
  listIndex: number;
  itemIndex: number;
  onChangeValue(list: number, item: number, type: number, value?: number): void;
};

export const PlusEvaluateItem: React.FC<plusPropsType> = (props) => {
  const { baseInfo, listIndex, itemIndex, onChangeValue } = props;
  return (
    <li>
      <p className="comment_rateview_i_subtitle">{baseInfo.fullContent}</p>
      <div className="comment_rateview_i_text">
        <Input.Group>
          <Button
            onClick={() => {
              onChangeValue(listIndex, itemIndex, 0);
            }}
          >
            满分
          </Button>
          <Button
            onClick={() => {
              onChangeValue(listIndex, itemIndex, 1);
            }}
          >
            +
          </Button>
          <InputNumber
            style={{ width: 200 }}
            max={baseInfo.scoreValue}
            value={baseInfo.value}
            onChange={(value) => {
              onChangeValue(listIndex, itemIndex, 4, value as any);
            }}
          />
          <Button
            onClick={() => {
              onChangeValue(listIndex, itemIndex, 2);
            }}
          >
            -
          </Button>
          <Button
            onClick={() => {
              onChangeValue(listIndex, itemIndex, 3);
            }}
          >
            零分
          </Button>
        </Input.Group>
      </div>
    </li>
  );
};

export const MinusEvaluateItem: React.FC<minusPropsType> = (props) => {
  const { baseInfo, listIndex, itemIndex, onChangeValue } = props;
  return (
    <li>
      <p className="comment_rateview_i_subtitle">{baseInfo.fullContent}</p>
      <div className="comment_rateview_i_text">
        <Input.Group>
          <Button
            onClick={() => {
              onChangeValue(listIndex, itemIndex, 0);
            }}
          >
            全部扣除
          </Button>
          <Button
            onClick={() => {
              onChangeValue(listIndex, itemIndex, 1);
            }}
          >
            +
          </Button>
          <InputNumber
            style={{ width: 200 }}
            max={baseInfo.scoreValue}
            value={baseInfo.value}
            onChange={(value) => {
              onChangeValue(listIndex, itemIndex, 4, value as any);
            }}
          />
          <Button
            onClick={() => {
              onChangeValue(listIndex, itemIndex, 2);
            }}
          >
            -
          </Button>
        </Input.Group>
      </div>
    </li>
  );
};
