/*
 * @Author: changcheng
 * @LastEditTime: 2023-11-20 09:37:08
 */
import { useState, useEffect, createRef } from "react";
import "./_index.scss";
import { post } from "../../common/fetch";
import { useQuery } from "../../common/utils";
import { Form, Radio, Button, Modal, Checkbox, message } from "antd";
import classNames from "classnames";
import { HistoryPush } from "../../common/utils";
interface OptionsType {
  id: string;
  title: string;
}
interface QuestionListProps {
  // 试卷id
  id: string;
  // 考试标题
  title: string;
  // 试题选项
  options: OptionsType[];
  // 试题类型
  type: number;
}
interface examInfoProps {
  // 考试ID
  id: string;
  // 通过分数
  passScore: number;
  // 时间
  time: number;
  // 标题
  title: string;
  // 总分数
  totalScore: number;
}
interface answerListProps {
  // 试题ID
  questionId: string;
  // 答案
  valueList: string[];
  // 考试状态
  userExamStatus?: number;
}
// 考试倒计时计时器
let interval: any = null;
// 倒计时
let totalSeconds = 0;
// 初始化倒计时
let initTotalSeconds = 0;
function Exam() {
  let query = useQuery();
  const answer_sheet = createRef<any>();
  let history = HistoryPush();
  const partnerClassId = query.get("partnerClassId");
  const projectId = query.get("projectId");
  const courseId = query.get("courseId");
  const examType = query.get("examType");
  const [questionList, setQuestionList] = useState<QuestionListProps[]>([]);
  const [examInfo, setExamInfo] = useState({} as examInfoProps);
  const [answerList, setAnswerList] = useState([] as answerListProps[]);
  const [countDown, setCountDown] = useState("");
  /**
   * 获取试卷列表
   */
  const getExamList = async () => {
    const {
      data: { questionList, id, passScore, time, title, totalScore },
    } = await post(
      `/shop/front/cm/user/get/exam`,
      {
        examType,
        partnerClassId,
        projectId,
        courseId,
      },
      false
    );
    totalSeconds = time * 60;
    initTotalSeconds = time * 60;
    setQuestionList(questionList);
    setExamInfo({
      id,
      passScore,
      time,
      title,
      totalScore,
    });
  };
  /**
   * 提交试题
   */
  const submmitExam = async () => {
    if (!answerList.length) {
      message.info("答案不能为空");
      return;
    }
    const {
      data: { userExamScore, userExamStatus, questionList },
    } = await post(
      `/shop/front/cm/user/post/exam`,
      {
        examId: examInfo.id,
        examType,
        partnerClassId,
        answerList,
        projectId,
        courseId,
      },
      false
    );
    // 多少道错题
    const errorQuestion = questionList.filter(
      (item: any) => item.userExamStatus === 0
    );
    // 标记错题
    questionList.forEach((item: any, index: number) => {
      answerList[index].userExamStatus = item.userExamStatus;
    });
    setAnswerList([...answerList]);
    clearInterval(interval);
    if (!userExamStatus) {
      Modal.confirm({
        title: "考试未通过",
        content: `分数为${userExamScore},错题为${errorQuestion.length}道`,
        okText: "继续考试",
        cancelText: "去学习",
        onOk() {
          console.log("OK");
          // 初始化倒计时
          totalSeconds = initTotalSeconds;
          interval = setInterval(updateTimer, 1000);
        },
        onCancel() {
          history.go(-1);
        },
      });
    } else {
      Modal.confirm({
        title: "考试通过",
        content: `分数为${userExamScore}`,
        onOk() {
          history.go(-1);
        },
        onCancel() {
          history.go(-1);
        },
      });
    }
  };
  /**
   * 倒计时功能
   */
  const updateTimer = () => {
    // 计算时、分、秒
    var hours = Math.floor(totalSeconds / 3600);
    var minutes = Math.floor((totalSeconds % 3600) / 60);
    var seconds = totalSeconds % 60;
    // 倒计时结束
    if (totalSeconds <= 0) {
      clearInterval(interval);
      message.info("时间已到，自动交卷");
      submmitExam();
    } else {
      totalSeconds--;
    }
    setCountDown(hours + " 时 " + minutes + " 分 " + seconds + " 秒 ");
  };
  useEffect(() => {
    getExamList();
  }, []);
  useEffect(() => {
    interval = setInterval(updateTimer, 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionList.length]);
  return (
    <div
      className="exam_container"
      onScroll={(e: any) => {
        console.log(e.target.scrollTop);
        if (e.target.scrollTop > 80) {
          answer_sheet.current.style.marginTop = `${
            e.target.scrollTop - 100
          }px`;
        } else {
          answer_sheet.current.style.marginTop = `0px`;
        }
      }}
    >
      <div className="exam_title">
        <h3>{examInfo.title}</h3>
        <p className="exam_desc">
          <span>总分：{examInfo.totalScore}分</span>
          <span>及格分：{examInfo.passScore}分</span>
          <span>考试时长：{examInfo.time}分</span>
        </p>
      </div>
      <div className="container">
        <div className="left">
          <Form
            onFinish={() => {
              if (answerList.length !== questionList.length) {
                message.info("有试题未选择");
                return;
              }
              submmitExam();
            }}
          >
            {questionList.map((item, index) => {
              return (
                <div className="exam_item">
                  <p className="description">
                    <span>
                      {index + 1}. {item.title}
                    </span>
                    <span className="type">
                      {item.type === 1 ? "单选" : "多选"}
                    </span>
                  </p>
                  {item.type === 1 && (
                    <Form.Item>
                      <Radio.Group
                        onChange={(e) => {
                          answerList[index] = {
                            valueList: [],
                            questionId: "",
                          };
                          answerList[index].valueList = [e.target.value];
                          answerList[index].questionId = item.id;
                          setAnswerList([...answerList]);
                        }}
                      >
                        {item.options.map((it) => {
                          return <Radio value={it.id}>{it.title}</Radio>;
                        })}
                      </Radio.Group>
                    </Form.Item>
                  )}
                  {item.type === 2 && (
                    <Form.Item>
                      <Checkbox.Group
                        onChange={(checkedValues) => {
                          answerList[index] = {
                            valueList: [],
                            questionId: "",
                          };
                          answerList[index].valueList = checkedValues.map(
                            (item) => String(item)
                          );
                          answerList[index].questionId = item.id;
                          setAnswerList([...answerList]);
                        }}
                      >
                        {item.options.map((it) => {
                          return <Checkbox value={it.id}>{it.title}</Checkbox>;
                        })}
                      </Checkbox.Group>
                    </Form.Item>
                  )}
                </div>
              );
            })}
            <Form.Item
              style={{
                textAlign: "center",
              }}
            >
              <Button
                onClick={() => {
                  history.go(-1);
                }}
              >
                放弃考试
              </Button>
              &emsp;
              <Button type="primary" htmlType="submit">
                提交试卷
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="answer_sheet" ref={answer_sheet}>
          <h6>答题卡</h6>
          <div className="answer">
            {questionList.map((item, index) => {
              const classes = classNames({
                noSelect:
                  answerList[index] && !answerList[index].valueList.length,
                select: answerList[index] && answerList[index].valueList.length,
                item: true,
                error:
                  answerList[index] && answerList[index].userExamStatus === 0,
              });
              return <div className={classes}>{index + 1}</div>;
            })}
          </div>
          <div className="answer_status">
            <p>未做</p>
            <p>已做</p>
            <p>错误</p>
          </div>
          {countDown && (
            <div className="remaining_time">剩余时间：{countDown}</div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Exam;
