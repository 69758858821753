/*
 * @Author: cc
 * @LastEditTime: 2023-11-15 17:21:19
 */
import { useEffect, useState } from "react";
import { Table, Space, Pagination, Button } from "antd";
import { post } from "../../../common/fetch";
import { connect } from "react-redux";
import { InvoinceModal } from "./invoiceModal";
interface NoteListProps {
  id: number;
  userId: number;
  instituteId: string;
  type: number;
  projectId: number;
  courseId: number;
  sectionId: number;
  content: string;
  sourceSystem: number;
  projectName: string;
  courseName: string;
  sectionName: string;
  updateTime: string;
  orderDetailInfo: string;
  receiptApplyStatus: number;
}
function MyOrder(props: any) {
  const {
    projectType: { type },
  } = props;
  const [totalSize, setTotalSize] = useState(1);
  const [page, setPage] = useState(1);
  const [orderInfo, setOrderInfo] = useState({});
  const [showInvoinceModal, setShowInvoinceModal] = useState(false);
  const columns = [
    {
      title: "订单记录",
      dataIndex: "tradeNo",
      key: "tradeNo",
    },
    {
      title: "物品名称",
      key: "orderDetailInfo",
      render: (text: string, record: NoteListProps) => (
        <Space size="middle">
          <p
            dangerouslySetInnerHTML={{
              __html: record?.orderDetailInfo,
            }}
          ></p>
        </Space>
      ),
    },
    {
      title: "价格",
      dataIndex: "orderAmount",
      key: "orderAmount",
    },
    {
      title: "下单时间",
      dataIndex: "orderCreateTimeText",
      key: "orderCreateTimeText",
    },
    {
      title: "订单状态",
      dataIndex: "tradeStatusDesc",
      key: "tradeStatusDesc",
    },
    {
      title: "操作",
      key: "action",
      render: (text: string, record: NoteListProps) => (
        <Space size="middle">
          <Button
            onClick={() => {
              setOrderInfo(record);
              setShowInvoinceModal(true);
            }}
            type="link"
            disabled={record.receiptApplyStatus === 1}
          >
            申请发票
          </Button>
        </Space>
      ),
    },
  ];
  const [trainList, setTrainList] = useState<NoteListProps[]>([]);
  const getCourseOrder = (page: number) => {
    post(
      `/shop/front/cm/user/order/list`,
      {
        pageNum: page,
        pageSize: 6,
      },
      true
    ).then((res) => {
      const { itemOrderList, page } = res.data;
      const { totalResultSize } = page;
      const list = itemOrderList.map((item: NoteListProps, index: number) => {
        return {
          key: (page.currentPage - 1) * page.pageSize + (index + 1),
          ...item,
        };
      });
      setTrainList(list);
      setTotalSize(totalResultSize);
    });
  };
  useEffect(() => {
    getCourseOrder(1);
  }, []);
  return (
    <div>
      <Table
        columns={columns}
        dataSource={trainList}
        pagination={false}
      ></Table>
      <div style={{ textAlign: "right", padding: "20px 0" }}>
        <Pagination
          total={totalSize}
          current={page}
          hideOnSinglePage={true}
          defaultPageSize={6}
          showSizeChanger={false}
          onChange={(page) => {
            getCourseOrder(page);
            setPage(page);
          }}
        />
      </div>
      <InvoinceModal
        showInvoinceModal={showInvoinceModal}
        setShowInvoinceModal={setShowInvoinceModal}
        orderInfo={orderInfo}
        getList={getCourseOrder}
      />
    </div>
  );
}
const getProjectType = (state: {
  projectType: { type: number; activityId?: string };
}) => {
  return {
    projectType: state.projectType,
  };
};
export default connect(getProjectType)(MyOrder);
