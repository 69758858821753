/*
 * @Author: changcheng
 * @LastEditTime: 2022-03-29 10:21:21
 */
const InitActivityInfo: Array<any> = [];
export const activityInfo = (
  state = InitActivityInfo,
  action: {
    type: string;
    payLoad: Array<any>;
  }
) => {
  switch (action.type) {
    case "CHANGE_ACTIVITY_INFO":
      return action.payLoad;
    default:
      return state;
  }
};
