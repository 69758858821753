/*
 * @Author: changcheng
 * @LastEditTime: 2022-06-15 11:20:04
 */
export interface VideoProps {
  type: string;
  vid: string;
  siteid: string;
  progressbar_enable: number;
  videoWatchAt: number;
  projectId: number;
  courseId: string;
  videoUniqueCode:string;
  sectionId: string;
  videoType: string;
  preventOnHookFlag: number;
  randomPopUpNum: number;
  onHookTipInfo: string;
  preventConcurrentPlayFlag: number;
  topicId: string;
  upDateVideoListFn: () => void;
}
const InitvideoStatus = {
  // 视频id
  vid: "",
  // ccUserId
  siteid: "",
  // 是否允许拖拽
  progressbar_enable: 1,
  // 观看位置
  videoWatchAt: 0,
  courseId: "",
  sectionId: "",
  forward:0, //左右方向键控制快进退步长
  videoType: "",
  videoUniqueCode:new Date().getTime()+'',
  preventOnHookFlag: 0,
  randomPopUpNum: 0,
  projectId: 0,
  onHookTipInfo: "辛苦，请继续观看！",
  preventConcurrentPlayFlag: 0,
  upDateVideoListFn: () => {},
  topicId: "",
};
export const videoStatus = (state = InitvideoStatus, action: VideoProps) => {
  switch (action.type) {
    case "SET_VIDEO_STATUS":
      return {
        ...action,
      };
    default:
      return state;
  }
};
const InitUserInfo = {
  name: "",
  avatar: "",
  mobile:"",
};
export interface UserProps {
  type?: string;
  name?: string;
  mobile?:string;
  avatar?: string;
}
export const userInfo = (state = InitUserInfo, action: UserProps) => {
  switch (action.type) {
    case "SET_USER_INFO":
      return {
        ...action,
      };
    default:
      return state;
  }
};
