/*
 * @Author: cc
 * @LastEditTime: 2024-04-18 15:19:10
 */
import { useState, useEffect } from "react";
import {
  Pagination,
  Button,
  message,
  Breadcrumb,
  Modal,
  Space,
  Upload,
  Progress,
} from "antd";
import axios from "axios";
import project_bg from "../../img/project_bg.png";
import no_class from "../../img/no_class.png";
import "./project-info.scss";
import { CountDown, Menu, Icon } from "../../components/index";
import { IconName } from "@fortawesome/fontawesome-common-types";
import { post } from "../../common/fetch";
import { useQuery } from "../../common/utils";
import { useHistory } from "react-router-dom";
import liveIcon from "../../img/live-icon.png";
import { TopicCourseSingle } from "./component/topicExamSingle/topicSingleCouse";
import { TopicCourseDouble } from "./component/topicExamDouble/topicDoubleCourse";
import {
  VideoCameraTwoTone,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import store from "../../reducers";
export type IconType = {
  iconStr: IconName;
};
interface navListProps {
  title: string;
  sub: string;
  icon: any;
  disabled: boolean;
}
interface ListProps {
  showName?: string;
  id: number;
  name?: string;
  videoWatchProgress?: number;
  videoWatchTimeText?: string;
  ccUserId?: string;
  courseId?: string;
  roomId?: string;
}
interface CalssInfoProps {
  // 课程名称
  projectName: string;
  // 学习时间范围
  projectTimeRange: string;
  // 课程总时长
  projectDurationText: string;
  // 学习进度
  projectProgress: string;
  // 培训须知
  projectNotice: string;
  // 是否要必须阅读
  mustReadNoticeFlag: number;
  // 是否已经阅读
  hasReadNoticeFlag: number;
  // 考试类型表示
  examSystemType: number;
  // 项目已经观看总时长
  projectWatchTimeText?: string;
  // 项目已经观看进度
  projectWatchTimeProgress?: string;
}
interface ExamProps {
  projectName: string;
  repeatExamFlag: number;
  projectPicture: string;
  progress: string;
  readNoticeTime: number;
  courseForm: number;
  topicShowForm: number;
  projectPageSetInfo?: {
    showLogoFlag?: number;
    showIndexUrlFlag?: number;
    showPersonCenterFlag?: number;
    projectAllPathLogo: any;
  };
  preClassExamInfo?: {
    questionnaireId: string;
    questionnaireStatus: number;
  };
  projectQuestionnaireInfo?: {
    questionnaireId: string;
    questionnaireStatus: number;
  };
  projectExamInfoList?: ExamInfoList[];
}
interface SectionListProps {
  courseName: string;
  courseId: string;
  questionnaireStatus: number;
  examStatus: number;
  repeatExamFlag: number;
  progress: string;
  courseDurationText: string;
  hasQuestionnaireFlag: number;
  hasExamFlag: number;
  activityId: string;
  courseType: string;
  examSystemType: number;
  examManagerId: string;
  sectionList: ListProps[];
  courseExamShowFlag: number;
}
interface ExamInfoList {
  name: string;
  examPaperName: string;
  examPaperId: string;
  examStatus: number;
  examTimeRange: string;
  examStatusText: string;
  examManagerId?: string;
  examSystemType: number;
  activityId?: string;
}
// const CnExamStatus = {
//   0: "未达标",
//   7: "问卷未完成",
// };
interface projectInfo {
  projectStatus: number;
  showCourseDurationFlag: number;
  projectDuration: string;
  projectDurationText: string;
  showWatchTimeFlag: number;
  projectWatchTime: string;
  projectWatchTimeText: string;
  showCourseCountFlag: number;
  showProjectTimeRangeFlag: number;
  projectCourseCount: number;
  showLearnedCourseCountFlag: number;
  projectLearnedCourseCount: number;
  showCourseExamCountFlag: number;
  projectCourseExamCount: number;
  showFinishCourseExamCountFlag: number;
  projectFinishCourseExamCount: number;
  showLearnProgressFlag: number;
  projectWatchTimeProgress: string;
  projectProgress: string;
  projectName: string;
  projectTimeRange: string;
  fileUploadFlag: number;
  fileUploadSize: number;
  fileUploadType: string;
  isUploadFile: boolean;
  // 控制答题记录显示
  courseAnswerRecordShowFlag: number;
  courseStudyBeginTimeText: string;
  courseStudyEndTimeText: string;
  // 显示课程练习状态标识(0:关闭; 1:开启)
  showCourseExamStatusFlag: number;
}
interface ProjectPageSetInfo {
  showLogoFlag?: number;
  showIndexUrlFlag?: number;
  showPersonCenterFlag?: number;
  projectAllPathLogo: any;
}
const QuestionStatus = ["不可以调查问卷", "可以调查问卷", "完成调查问卷"];
function HeaderIcon(props: IconType) {
  const { iconStr } = props;
  return <Icon icon={iconStr} size="1x" theme="gray" />;
}

let projectId: string;
let manageId: string = "";
let fromUrl: string = "";
function ProjectInfo(props: any) {
  const query = useQuery();
  const history = useHistory();
  projectId = query.get("projectId") as string;
  fromUrl = query.get("fromUrl") as string;
  const trainStatus = new Map([
    [0, "未通过"],
    [1, "未通过"],
    [2, "已通过"],
    [4, "未通过"],
    [7, "未通过"],
  ]);
  let navList =
    projectId === "1214"
      ? [
          { title: "培训须知", sub: "1", icon: "bullhorn", disabled: false },
          { title: "课程列表", sub: "2", icon: "list-ul", disabled: true },
          { title: "问卷考试", sub: "3", icon: "landmark", disabled: true },
          { title: "数据查询", sub: "4", icon: "book-open", disabled: true },
          { title: "证书记录", sub: "5", icon: "procedures", disabled: true },
        ]
      : [
          { title: "培训须知", sub: "1", icon: "bullhorn", disabled: false },
          { title: "课程列表", sub: "2", icon: "list-ul", disabled: true },
          { title: "问卷考试", sub: "3", icon: "landmark", disabled: true },
        ];
  const projectType = query.get("projectType");
  // 根据河北项目进行区分字段
  // const hebeiList = ["867", "868", "869", "873"];
  const [navIndex, setNavIndex] = useState("0");
  const [navlist, setNavList] = useState<navListProps[]>(navList);
  const [classInfo, setClassInfo] = useState<CalssInfoProps>();
  const [hasReadFlag, setHasReadFlag] = useState(false);
  const [totalPageSize, setTotalPageSize] = useState(1);
  const [courseList, setCourseList] = useState<SectionListProps[]>([]);
  const [projectInfo, setProjectInfo] = useState<projectInfo>(
    {} as projectInfo
  );
  const [questionExamInfo, setQuestionExamInfo] = useState<ExamProps>(
    {} as ExamProps
  );
  const [courseTypeInfo, setCourseTypeInfo] = useState<{
    courseForm: 1 | 2;
    topicShowForm: number;
  }>({
    courseForm: 1,
    topicShowForm: 1,
  });
  const [page, setPage] = useState(1);
  const projectPageSetInfo: ProjectPageSetInfo = store.getState().projectInfo;
  const [percent, setPercent] = useState(0);
  const [progressStatus, setProgressStatus] = useState("active");
  const [courseStudyTimeFlag, setCourseStudyTimeFlag] = useState(1);
  const uploadProps = {
    name: "file",
    onChange(info: any) {
      // console.log(info.file.percent)
      // if (info.file.status === 'uploading') {
      //   const uploadedPercent = (info.file.percent || 0); // 增加进度
      //   setPercent(uploadedPercent);
      // } else if (info.file.status === 'done') {
      //   setPercent(100); // 文件上传完成后，重置进度
      // } else if (info.file.status === 'error') {
      //   setProgressStatus('exception')
      // }
    },
  };
  const customRequest = async (data: any) => {
    const { file } = data;

    // // 检查文件类型
    // const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    // if (!allowedTypes.includes(file.type)) {
    //   message.error('只允许上传 PDF、DOC 或 DOCX 格式的文件');
    //   onError('只允许上传 PDF、DOC 或 DOCX 格式的文件');
    //   return;
    // }
    // 使用 FormData 构建请求
    const formData = new FormData();
    formData.append("file", file);
    formData.append("projectId", projectId);
    const response: any = await axios.post(
      "/shop/front/uploadProjectUserFile",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setPercent(percentCompleted);
        },
      }
    );
    if (response.data.code === 0) {
      message.success("文件上传成功");
    } else {
      message.error(response.data.msg);
    }
  };
  //图片上传前置事件
  function beforeUpload(file: any) {
    return new Promise((resovle, reject) => {
      if (file.size / 1024 / 1024 > projectInfo.fileUploadSize) {
        message.error("请选择正确的文件");
        reject();
      }
      resovle(file);
    });
  }
  // const studySteps: any = hebeiList.includes(projectId)
  //   ? classInfo?.projectWatchTimeProgress
  //   : classInfo?.projectProgress
  //     ? Number(classInfo?.projectProgress)
  //     : 0

  const getProjectInfo = () => {
    const url = `/shop/front/project/${projectId}/info`;
    post(url, {}, true).then((res) => {
      const {
        projectName,
        repeatExamFlag,
        projectPicture,
        progress,
        readNoticeTime,
        mustReadNoticeFlag,
        hasReadNoticeFlag,
        preClassExamInfo,
        projectExamInfoList,
        projectQuestionnaireInfo,
        projectPageSetInfo,
        courseForm,
        topicShowForm,
        courseStudyTimeFlag,
      } = res.data;
      setProjectInfo(res.data);
      console.log(res.data.fileUploadFlag);
      setCourseTypeInfo({ courseForm, topicShowForm });
      setCourseStudyTimeFlag(courseStudyTimeFlag);
      store.dispatch({
        type: "CHANGE_PROJECTINFO",
        payLoad: {
          ...projectPageSetInfo,
        },
      });
      sessionStorage.setItem(
        "projectPageSetInfo",
        JSON.stringify(projectPageSetInfo)
      );
      setQuestionExamInfo({
        projectPicture,
        projectPageSetInfo,
        readNoticeTime,
        progress,
        repeatExamFlag,
        projectName,
        preClassExamInfo,
        projectQuestionnaireInfo,
        courseForm,
        topicShowForm,
        projectExamInfoList: projectExamInfoList ? projectExamInfoList : [],
      });
      if (courseForm === 1) {
        getCourseList(1);
      }
      // mustReadNoticeFlag 1:必须读 0:不需要读
      // hasReadNoticeFlag 1:已经读 0:未读
      let hasReadFlag = mustReadNoticeFlag === 1 && hasReadNoticeFlag === 0;
      setHasReadFlag(hasReadFlag);
      if (!hasReadFlag) {
        setNavList(
          navlist.map((item, index) =>
            index > 0
              ? {
                  title: item.title,
                  sub: item.sub,
                  icon: item.icon,
                  disabled: false,
                }
              : item
          )
        );
        setNavIndex("1");
      }
      setClassInfo(res.data);
    });
  };
  const getCourseList = (page: number) => {
    const url = `/shop/front/project/course/list`;
    post(
      url,
      {
        projectId,
        page: {
          currentPage: page,
          pageSize: 15,
        },
      },
      true
    ).then((res) => {
      const { courseList, page } = res.data;
      const { totalResultSize } = page;
      const list = courseList.map((item: SectionListProps) => {
        return {
          ...item,
          progress: Number(item.progress),
        };
      });
      console.log("list", list);
      setTotalPageSize(totalResultSize);
      setCourseList(list);
    });
  };
  // 问卷
  const getQuestioUrl = (item: SectionListProps) => {
    let CnQuestionStatus = {
      0: "不可以问卷",
      2: "已完成",
    };
    const { questionnaireStatus } = item;
    if (CnQuestionStatus[questionnaireStatus]) {
      message.info(CnQuestionStatus[questionnaireStatus]);
      return;
    }
    const { courseId } = item;
    const url = `/shop/questionnaire/getProjectCourseStarQuestionnaireUrl`;
    post(
      url,
      {
        projectId,
        courseId,
      },
      true
    ).then((res) => {
      const { questionnaireUrl } = res.data;
      window.location.href = questionnaireUrl;
    });
  };
  const saveReadNotic = async () => {
    const url = `/shop/front/project/${projectId}/saveReadNotice`;
    post(url, {}, false).then((res) => {
      setNavList(
        navlist.map((item, index) =>
          index > 0
            ? {
                title: item.title,
                sub: item.sub,
                icon: item.icon,
                disabled: false,
              }
            : item
        )
      );
      setHasReadFlag(false);
    });
  };
  const getProjectPreCourseExamUrl = () => {
    post(
      `/shop/exam/getProjectPreCourseExamUrl`,
      {
        projectId,
      },
      true
    ).then((res) => {
      const { preCourseExamUrl } = res.data;
      window.location.href = preCourseExamUrl;
    });
  };
  const getProjectStarQuestionnaireUrl = () => {
    const url = `/shop/questionnaire/getProjectStarQuestionnaireUrl`;
    post(
      url,
      {
        projectId,
      },
      true
    ).then((res) => {
      const { questionnaireUrl } = res.data;
      window.location.href = questionnaireUrl;
    });
  };
  // 课程考试
  const checkCourseExamFlag = async (it: SectionListProps) => {
    const { courseId, examSystemType } = it;
    // 获取考试剩余次数
    const { courseCanExamNum, courseExamLimitFlag, courseExamLimitNum } =
      await checkCourseExam(courseId);
    if (courseExamLimitFlag === 1 && examSystemType === 2) {
      Modal.confirm({
        title: "提示",
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            <p>本场共{courseExamLimitNum}次机会</p>
            <p>当前剩余{courseCanExamNum}次</p>
          </div>
        ),
        okText: "确认",
        cancelText: "取消",
        onOk() {
          getCourseExamUrl(
            it,
            courseCanExamNum,
            courseExamLimitFlag,
            courseId,
            examSystemType
          );
        },
      });
    } else {
      getCourseExamUrl(
        it,
        courseCanExamNum,
        courseExamLimitFlag,
        courseId,
        examSystemType
      );
    }
  };
  const checkCourseExam = (
    courseId: string
  ): Promise<{
    courseCanExamNum: number;
    courseExamLimitFlag: number;
    courseExamLimitNum: number;
  }> => {
    return new Promise((resolve, reject) => {
      const url = `/shop/exam/checkProjectCourseExam`;
      post(
        url,
        {
          projectId,
          courseId,
        },
        true
      ).then((res) => {
        const { courseCanExamNum, courseExamLimitFlag, courseExamLimitNum } =
          res.data;
        resolve({
          courseCanExamNum,
          courseExamLimitFlag,
          courseExamLimitNum,
        });
      });
    });
  };
  //获取考试链接
  const getCourseExamUrl = (
    it: SectionListProps,
    courseCanExamNum: number,
    courseExamLimitFlag: number,
    courseId: string,
    examSystemType: number
  ) => {
    const { REACT_APP_NODE_ENV } = process.env;
    // 如果剩余次数是0次，则不跳转，并且不是无限次
    if (courseCanExamNum === 0 && courseExamLimitFlag === 1) {
      return;
    }
    if (examSystemType === 2) {
      const { examManagerId, courseId, activityId } = it;
      window.location.href = `${window.origin}/${
        REACT_APP_NODE_ENV === "development" || REACT_APP_NODE_ENV === "test"
          ? "testing-mvw-exam-mobile"
          : "studentmobile"
      }/exam-list/examList?fromType=${
        projectType === "2" ? "activityCourseExam" : "courseExam"
      }&projectId=${projectId}&examManagerId=${examManagerId}&courseId=${courseId}&activityId=${activityId}`;
    } else {
      const url = `/shop/exam/getProjectCourseExamUrl`;
      post(
        url,
        {
          projectId,
          courseId,
        },
        true
      ).then((res) => {
        const { courseExamUrl } = res.data;
        window.location.href = courseExamUrl;
      });
    }
  };
  // 项目考试
  const checkExamFlag = async (it: ExamInfoList) => {
    const { examManagerId = "" } = it;
    manageId = examManagerId;
    // 获取考试剩余次数
    const { projectCanExamNum, projectExamLimitFlag, projectExamLimitNum } =
      await checkProjectExam(manageId);
    if (projectExamLimitFlag === 1) {
      Modal.confirm({
        title: "提示",
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            <p>本场共{projectExamLimitNum}次机会</p>
            <p>当前剩余{projectCanExamNum}次</p>
          </div>
        ),
        okText: "确认",
        cancelText: "取消",
        onOk() {
          getProjectExamUrl(it, projectCanExamNum, projectExamLimitFlag);
        },
      });
    } else {
      getProjectExamUrl(it, projectCanExamNum, projectExamLimitFlag);
    }
  };
  const getProjectExamUrl = (
    it: ExamInfoList,
    projectCanExamNum: number,
    projectExamLimitFlag: number
  ) => {
    // 如果剩余次数是0次，则不跳转，并且不是无限次
    if (projectCanExamNum === 0 && projectExamLimitFlag === 1) {
      return;
    }
    const { REACT_APP_NODE_ENV } = process.env;
    if (classInfo?.examSystemType === 2) {
      const { activityId } = it;
      window.location.href = `${window.origin}/${
        REACT_APP_NODE_ENV === "development" || REACT_APP_NODE_ENV === "test"
          ? "testing-mvw-exam-mobile"
          : "studentmobile"
      }/exam-list/examList?fromType=projectExam&projectId=${projectId}&examManagerId=${manageId}&activityId=${activityId}`;
    } else {
      const url = `/shop/exam/getProjectExamUrl`;
      post(
        url,
        {
          projectId,
        },
        true
      ).then((res) => {
        const { projectExamUrl } = res.data;
        window.location.href = projectExamUrl;
      });
    }
  };
  /**
   *
   * @param item 项目考试记录
   */
  const examPaperRecord = (item: any) => {
    const { userPaperScoreId } = item;
    const { REACT_APP_BASE_URL, REACT_APP_EXAM_URL } = process.env;
    const examResultUrl = `${REACT_APP_BASE_URL}${REACT_APP_EXAM_URL}/record-list?userPaperScoreId=${userPaperScoreId}&type=projectExam`;
    window.open(examResultUrl);
  };
  const checkProjectExam = (
    examManagerId: string
  ): Promise<{
    projectCanExamNum: number;
    projectExamLimitFlag: number;
    projectExamLimitNum: number;
  }> => {
    return new Promise((resolve, reject) => {
      const url = `/shop/exam/checkProjectExam`;
      post(
        url,
        {
          projectId,
          examManagerId,
        },
        true
      ).then((res) => {
        const { projectCanExamNum, projectExamLimitFlag, projectExamLimitNum } =
          res.data;
        resolve({
          projectCanExamNum,
          projectExamLimitFlag,
          projectExamLimitNum,
        });
      });
    });
  };
  useEffect(() => {
    fromUrl && sessionStorage.setItem("fromUrl", fromUrl);
    getProjectInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // 根据项目区分隐藏项目时长和项目总时长
  // const xjProjectFlag = useMemo(() => {
  //   const projectArr = ["487", "488", "489"];
  //   return projectArr.includes(projectId);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [projectId]);
  const {
    preClassExamInfo,
    projectQuestionnaireInfo,
    projectPicture,
    projectExamInfoList,
    readNoticeTime,
  } = questionExamInfo;
  // 跳转播放页面
  const jumpLiveRoom = (item: SectionListProps) => {
    const { courseType, courseId } = item;
    if (item.sectionList.length === 0) return;
    const { id, roomId, ccUserId } = item.sectionList[0];
    if (courseType === "course") {
      history.push(
        `/play?projectId=${projectId}&courseId=${courseId}&sectionId=${id}&type=project`
      );
    } else {
      const url = `/shop/ccLiveFunction/getUserProjectLiveUrl?roomId=${roomId}&ccUserId=${ccUserId}`;
      window.open(url);
    }
  };
  return (
    <div className="projectInfo_box">
      <div className="project_header">
        <img className="header_img" src={project_bg} alt="" />
        <div className="project_title">
          <Breadcrumb
            separator="/"
            style={{ fontSize: "20px", marginBottom: "30px" }}
          >
            {projectPageSetInfo.showIndexUrlFlag === 1 && (
              <Breadcrumb.Item href="/#/train">首页</Breadcrumb.Item>
            )}
            <Breadcrumb.Item>
              <span style={{ color: "#1890ff" }}>项目主页</span>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="project_detail_box">
            <img
              src={projectPicture}
              alt=""
              style={{ width: "469px", height: "300px" }}
            />
            <div style={{ paddingLeft: "20px" }}>
              <h2>{projectInfo?.projectName}</h2>
              <div className="time">
                {projectInfo.showProjectTimeRangeFlag === 1 && (
                  <p>项目时间：{projectInfo?.projectTimeRange}</p>
                )}
                <div style={{ display: "flex" }}>
                  {projectInfo.showCourseDurationFlag === 1 && (
                    <p style={{ width: "45%" }}>
                      课程时长：{projectInfo?.projectDurationText}
                    </p>
                  )}
                  {projectInfo.showWatchTimeFlag === 1 && (
                    <p>已学时长：{projectInfo?.projectWatchTimeText}</p>
                  )}
                  {/* 课程项目总时长 */}
                </div>
                <div>
                  {projectInfo.showCourseCountFlag === 1 && (
                    <p style={{ width: "45%" }}>
                      课程数量：
                      {projectInfo.projectCourseCount
                        ? projectInfo.projectCourseCount
                        : 0}
                    </p>
                  )}
                  {projectInfo.showLearnedCourseCountFlag === 1 && (
                    <p>
                      课程学完数量：{projectInfo?.projectLearnedCourseCount}
                    </p>
                  )}
                </div>
                <div>
                  {projectInfo.showCourseExamCountFlag === 1 && (
                    <p style={{ width: "45%" }}>
                      练习数量：
                      {projectInfo.projectCourseExamCount
                        ? projectInfo.projectCourseExamCount
                        : 0}
                    </p>
                  )}
                  {projectInfo.showFinishCourseExamCountFlag === 1 && (
                    <p>
                      练习通过数量：
                      {projectInfo.projectFinishCourseExamCount
                        ? projectInfo.projectFinishCourseExamCount
                        : 0}
                    </p>
                  )}
                </div>
                <div>
                  {projectInfo.showLearnProgressFlag === 1 && (
                    <p style={{ width: "45%" }}>
                      学习进度：
                      {projectInfo.projectProgress}%
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="project_list">
            <div>
              <Menu
                index={navIndex}
                defaultOpenSubMenus={[]}
                mode="horizontal"
                onSelect={(index) => {
                  if (index === "3") {
                    history.push(`./dataQuery?projectId=${projectId}`);
                  } else if (index === "4") {
                    history.push(`./certificateRecords?projectId=${projectId}`);
                  }
                  setNavIndex(index);
                }}
              >
                {navlist.map((it: navListProps, index: number) => (
                  <Menu.Item key={index} disabled={it.disabled}>
                    <Icon
                      icon={it.icon}
                      size="1x"
                      theme="dark"
                      style={{ marginRight: "8px" }}
                    />
                    <span>{it.title}</span>
                  </Menu.Item>
                ))}
              </Menu>
              <div className="video_box">
                {navIndex === "0" && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: classInfo?.projectNotice as string,
                    }}
                    style={{
                      padding: "6px 12px",
                      width: "100%",
                      overflowWrap: "anywhere",
                    }}
                  ></div>
                )}
                {/* 课程 */}
                {navIndex === "1" &&
                  courseStudyTimeFlag === 1 &&
                  projectInfo.projectStatus === 1 && (
                    <>
                      {/* 课程 */}
                      {questionExamInfo.courseForm === 1 && (
                        <p>
                          {courseList.map((item: any, idx: number) => {
                            return (
                              <div
                                className={
                                  item.courseLock
                                    ? "video_item_box_mask video_item_box"
                                    : "video_item_box"
                                }
                                key={idx}
                              >
                                <p
                                  onClick={() => {
                                    jumpLiveRoom(item);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <h5
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <VideoCameraTwoTone
                                      twoToneColor={
                                        item.courseType === "course"
                                          ? ""
                                          : "red"
                                      }
                                    />
                                    &emsp;
                                    <span style={{ color: "#1890ff" }}>
                                      {item.courseOrder}
                                    </span>
                                    &emsp;
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: item.courseName,
                                      }}
                                    ></span>
                                    {item.courseType === "live" && (
                                      <img
                                        src={liveIcon}
                                        alt=""
                                        style={{
                                          width: "46px",
                                          marginLeft: "4px",
                                        }}
                                      />
                                    )}
                                  </h5>
                                </p>
                                <div className="studyProgress_box">
                                  <p
                                    style={{
                                      opacity:
                                        item.courseType === "course" ? 100 : 0,
                                    }}
                                  >
                                    {!item.onlyPdfFlag && (
                                      <>
                                        <span>
                                          课程时长：{item.courseDurationText}
                                        </span>
                                        <span>
                                          &emsp;学习进度:{item.progress}%
                                        </span>
                                      </>
                                    )}
                                    {projectInfo.showCourseExamStatusFlag ===
                                      1 &&
                                      item.courseExamShowFlag === 1 &&
                                      item.hasExamFlag === 1 && (
                                        <span>
                                          &emsp;练习状态:
                                          {trainStatus.get(item.examStatus)}
                                        </span>
                                      )}
                                  </p>
                                  <Space>
                                    <span>
                                      {item.hasQuestionnaireFlag === 1 && (
                                        <Button
                                          type="primary"
                                          size="small"
                                          onClick={() => {
                                            getQuestioUrl(item);
                                          }}
                                          style={{
                                            marginLeft: "6px",
                                          }}
                                        >
                                          评价
                                        </Button>
                                      )}
                                    </span>
                                    {item.courseExamShowFlag === 1 &&
                                      item.hasExamFlag === 1 &&
                                      item.userPaperScoreId &&
                                      projectInfo.courseAnswerRecordShowFlag ===
                                        1 && (
                                        <>
                                          <Button
                                            style={{ color: "white" }}
                                            type="primary"
                                            size="small"
                                            onClick={() => {
                                              examPaperRecord(item);
                                            }}
                                          >
                                            最新答题记录
                                          </Button>
                                        </>
                                      )}
                                    <span>
                                      {item.courseExamShowFlag === 1 &&
                                        item.hasExamFlag === 1 && (
                                          <Button
                                            type="primary"
                                            size="small"
                                            disabled={item.examStatus === 0}
                                            onClick={() => {
                                              checkCourseExamFlag(item);
                                            }}
                                            style={{
                                              marginLeft: "6px",
                                            }}
                                          >
                                            练习
                                          </Button>
                                        )}
                                    </span>
                                    {item.sectionList.length > 0 && (
                                      <>
                                        <Button
                                          style={{ color: "white" }}
                                          type="primary"
                                          size="small"
                                          onClick={() => {
                                            jumpLiveRoom(item);
                                          }}
                                        >
                                          播放
                                        </Button>
                                      </>
                                    )}
                                  </Space>
                                </div>
                              </div>
                            );
                          })}
                          <div className="pagination-box">
                            <h6>共{totalPageSize}门课程</h6>
                            <Pagination
                              total={totalPageSize}
                              current={page}
                              defaultPageSize={15}
                              showSizeChanger={false}
                              onChange={(page) => {
                                getCourseList(page);
                                setPage(page);
                              }}
                            ></Pagination>
                          </div>
                        </p>
                      )}
                      {/* 项目专题单层 */}
                      {questionExamInfo.courseForm === 2 &&
                        questionExamInfo.topicShowForm === 1 && (
                          <TopicCourseSingle
                            projectId={projectId}
                            projectInfo={projectInfo}
                          />
                        )}
                      {/* 项目专题多层第一层 */}
                      {courseTypeInfo.courseForm === 2 &&
                        courseTypeInfo.topicShowForm === 2 && (
                          <TopicCourseDouble
                            projectId={projectId}
                            projectInfo={projectInfo}
                          />
                        )}
                    </>
                  )}
                {navIndex === "1" &&
                  courseStudyTimeFlag === 0 &&
                  projectInfo.projectStatus === 1 && (
                    <div style={{ textAlign: "center" }}>
                      <h3>不在学习时间范围内</h3>
                      <h4>
                        学习时间：{projectInfo.courseStudyBeginTimeText} -{" "}
                        {projectInfo.courseStudyEndTimeText}
                      </h4>
                    </div>
                  )}
                {navIndex === "1" && projectInfo.projectStatus !== 1 && (
                  <div className="no_class">
                    <img src={no_class} alt="未在项目开放周期内" />
                    <p>未在项目开放周期内</p>
                  </div>
                )}
                {navIndex === "0" && hasReadFlag && (
                  <CountDown time={readNoticeTime} sureClick={saveReadNotic} />
                )}
                {navIndex === "2" && (
                  <div className="examInfo_box">
                    {preClassExamInfo && (
                      <div>
                        <p>项目摸底测试</p>
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            状态：
                            {preClassExamInfo.questionnaireStatus === 0
                              ? "未考试"
                              : "已考试"}
                          </span>
                          <Button
                            type="primary"
                            disabled={
                              preClassExamInfo.questionnaireStatus === 1
                            }
                            onClick={getProjectPreCourseExamUrl}
                          >
                            进入测试
                          </Button>
                        </p>
                      </div>
                    )}
                    {projectQuestionnaireInfo && (
                      <div>
                        <p>项目问卷</p>
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            状态：
                            {
                              QuestionStatus[
                                projectQuestionnaireInfo.questionnaireStatus
                              ]
                            }
                          </span>
                          <Button
                            type="primary"
                            disabled={
                              projectQuestionnaireInfo.questionnaireStatus ===
                                0 ||
                              projectQuestionnaireInfo.questionnaireStatus === 2
                            }
                            onClick={getProjectStarQuestionnaireUrl}
                          >
                            进入问卷
                          </Button>
                        </p>
                      </div>
                    )}
                    {projectExamInfoList &&
                      projectExamInfoList.map((item, index) => (
                        <div key={index}>
                          <p>{item.examPaperName}</p>
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>状态：{item.examStatusText}</span>
                            <Button
                              type="primary"
                              onClick={() => {
                                checkExamFlag(item);
                              }}
                              disabled={
                                item.examStatus === 0 ||
                                item.examStatus === 10 ||
                                item.examStatus === 6 ||
                                item.examStatus === 7
                              }
                            >
                              进入考试
                            </Button>
                          </p>
                        </div>
                      ))}
                    {projectInfo.fileUploadFlag === 1 && (
                      <div>
                        <p>上传文件</p>
                        <p>格式：{projectInfo.fileUploadType}等格式</p>
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            position: "relative",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            状态：
                            {projectInfo.isUploadFile ? "已上传" : "未上传"}
                          </span>
                          {percent > 0 && (
                            <Progress
                              className="myProgress"
                              percent={percent}
                              showInfo
                              status={
                                progressStatus === "exception"
                                  ? "exception"
                                  : "active"
                              }
                              format={() => `${percent.toFixed(2)}%`}
                            />
                          )}
                          <Upload
                            // className="myUpload"
                            maxCount={1}
                            beforeUpload={beforeUpload as any}
                            showUploadList={false}
                            customRequest={customRequest}
                            {...uploadProps}
                          >
                            <Button type="primary" onClick={() => {}}>
                              上传文件
                            </Button>
                          </Upload>
                        </p>
                      </div>
                    )}
                    {!preClassExamInfo &&
                      !projectQuestionnaireInfo &&
                      !projectExamInfoList!.length && (
                        <div className="no_class">
                          <img src={no_class} alt="暂无课程" />
                          <p>暂无相关信息</p>
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProjectInfo;
