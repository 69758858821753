/*
 * @Author: cc
 * @LastEditTime: 2021-03-16 17:39:01
 */
import "./_style.scss";
const Footer: React.FC = () => {
  return (
    <div className="mvw-footer">
      <div>
        <span>官方网站：www.mvwchina.com</span>
        <span>客服电话：400-001-8080</span>
        <span> 010-84280076</span>
        <span>办公地址：北京市朝阳区芍药居38号楼3层</span>
      </div>
      <div>
        <span>医视界版权所有</span>
        <span>京ICP备13033683</span>
        <span>京公网安备</span>
        <span>11010502041560</span>
      </div>
    </div>
  );
};
export default Footer;
