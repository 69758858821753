/*
 * @Author: cc
 * @LastEditTime: 2023-09-25 10:51:44
 */
import "./styles/index.scss";
import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./routes/index";
import "antd/dist/antd.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Provider } from "react-redux";
import store from "./reducers/index";
import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import { PersistGate } from "redux-persist/lib/integration/react";
import { CCvideoControlInstance } from "./common/video";
import { persistor } from "./reducers/index";
CCvideoControlInstance.initVideo();
library.add(fas);
ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </ConfigProvider>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
