/*
 * @Author: cc
 * @LastEditTime: 2023-12-26 11:19:15
 */
import { useEffect, useState } from "react";
import { Table, Space, Pagination } from "antd";
import { post } from "../../../common/fetch";
import { connect } from "react-redux";
import { HistoryPush } from "../../../common/utils";
interface NoteListProps {
  id: number;
  userId: number;
  instituteId: string;
  type: number;
  projectId: number;
  courseId: number;
  sectionId: number;
  content: string;
  sourceSystem: number;
  projectName: string;
  courseName: string;
  sectionName: string;
  updateTime: string;
}
function PurchasedOrder(props: any) {
  const {
    projectType: { type },
  } = props;
  const [totalSize, setTotalSize] = useState(1);
  const [page, setPage] = useState(1);
  let history = HistoryPush();
  const columns = [
    {
      title: "培训名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "必修达标学时",
      dataIndex: "requiredPeriod",
      key: "requiredPeriod",
      render: (text: string) => {
        return <span>{text}学时</span>;
      },
    },
    {
      title: "选修达标学时",
      dataIndex: "notRequiredPeriod",
      key: "notRequiredPeriod",
      render: (text: string) => {
        return <span>{text}学时</span>;
      },
    },
    {
      title: "操作",
      key: "action",
      render: (text: string, record: NoteListProps) => (
        <Space size="middle">
          <a
            style={{ color: "#1890ff" }}
            onClick={() => {
              history.push(`/payProjectInfo?courseId=${record.id}`);
            }}
          >
            继续学习
          </a>
        </Space>
      ),
    },
  ];
  const [trainList, setTrainList] = useState<NoteListProps[]>([]);
  const getCourseOrder = (page: number) => {
    post(
      `/shop/front/cm/user/class/list`,
      {
        pageNum: page,
        pageSize: 6,
      },
      true
    ).then((res) => {
      const { trainClassInfoList, page } = res.data;
      const { totalResultSize } = page;
      const list = trainClassInfoList.map(
        (item: NoteListProps, index: number) => {
          return {
            key: (page.currentPage - 1) * page.pageSize + (index + 1),
            ...item,
          };
        }
      );
      setTrainList(list);
      setTotalSize(totalResultSize);
    });
  };
  useEffect(() => {
    getCourseOrder(1);
  }, []);
  return (
    <div>
      <Table
        columns={columns}
        dataSource={trainList}
        pagination={false}
      ></Table>
      <div style={{ textAlign: "right", padding: "20px 0" }}>
        <Pagination
          total={totalSize}
          current={page}
          hideOnSinglePage={true}
          defaultPageSize={6}
          showSizeChanger={false}
          onChange={(page) => {
            getCourseOrder(page);
            setPage(page);
          }}
        />
      </div>
    </div>
  );
}
const getProjectType = (state: {
  projectType: { type: number; activityId?: string };
}) => {
  return {
    projectType: state.projectType,
  };
};
export default connect(getProjectType)(PurchasedOrder);
