/*
 * @Author: changcheng
 * @LastEditTime: 2024-04-18 15:19:42
 */
import React, { useEffect, FC, useState } from "react";
import { Collapse } from "antd";
import { ExamGroup } from "./topicExamSingleButton";
import { SectionListProps } from "../../../../actions/index";
import { post } from "../../../../common/fetch";
import { VideoCameraTwoTone } from "@ant-design/icons";
const { Panel } = Collapse;
type propsType = {
  projectId: string;
  projectInfo: any;
};
type TopicType = {
  courseWatchTimeText: string;
  courseName: string;
  courseOrder: number;
  progress: string;
};
type topicCourseType = {
  topicName: string;
  topicId: number;
  hasLearnAuthorityFlag: number;
  courseInfoList: Array<SectionListProps & TopicType>;
};
const trainStatus = new Map([
  [0, "未通过"],
  [1, "未通过"],
  [2, "已通过"],
  [4, "未通过"],
  [7, "未通过"],
]);
export const TopicCourseSingle: FC<propsType> = (props) => {
  const [topicDetailList, setTopicDetailList] = useState<topicCourseType[]>([]);
  const { projectId, projectInfo } = props;
  // 获取单层专题列表数据
  const getSingleTopicList = async () => {
    post(`/shop/front/project/${projectId}/topic/list`, {}, true).then(
      (res: any) => {
        const { topicDetailList } = res.data;
        setTopicDetailList(
          topicDetailList.filter(
            (item: topicCourseType) => item.hasLearnAuthorityFlag === 1 // 过滤没有权限的数据
          )
        );
      }
    );
  };
  useEffect(() => {
    getSingleTopicList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Collapse accordion className="my-accordion" defaultActiveKey={["0"]}>
        {topicDetailList.map((item, key) => (
          <Panel header={item.topicName} className="pad" key={key}>
            {item.courseInfoList.map((it, idx) => (
              <div
                key={idx}
                className={
                  it.courseLock
                    ? "video_item_box_mask video_item_box border_sty"
                    : "video_item_box border_sty"
                }
              >
                <h5 style={{ display: "flex", alignItems: "center" }}>
                  <VideoCameraTwoTone
                    twoToneColor={it.courseType === "course" ? "" : "red"}
                  />
                  &emsp;{" "}
                  <span style={{ color: "#1890ff" }}>{it.courseOrder}</span>
                  &emsp;{it.courseName}
                </h5>
                <p style={{ fontSize: "16px" }}>
                  {it.courseDurationText !== "00:00:00"
                    ? `课程时长:${it.courseDurationText}`
                    : ""}
                  {Number(it.progress) > 0 ? `·已学习${it.progress}%` : ""}
                  &emsp;
                  {projectInfo.showCourseExamStatusFlag === 1 &&
                    it.courseExamShowFlag === 1 &&
                    it.hasExamFlag === 1 &&
                    it.courseType === "course" && (
                      <span>
                        &emsp;练习状态:
                        {trainStatus.get(it.examStatus)}
                      </span>
                    )}
                </p>
                <div className="btn_group_container">
                  <ExamGroup
                    Id={projectId}
                    courseExamInfo={it}
                    topicId={it.topicId as string}
                    projectInfo={projectInfo}
                  />
                </div>
              </div>
            ))}
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};
