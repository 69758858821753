/*
 * @Author: changcheng
 * @LastEditTime: 2023-10-27 10:11:40
 */
import { Modal, Form, Input, Button, message } from "antd";
import { post } from "../../common/fetch";

const UserInfoConfirm = (props: {
  showUserConfirmModal: boolean;
  setShowUserConfirmModal: (show: boolean) => void;
}) => {
  const [form] = Form.useForm();
  const { showUserConfirmModal, setShowUserConfirmModal } = props;
  /**
   * 完善信息
   * @param values 表单字段
   */
  const confirmUserInfo = async (values: any) => {
    const { msg } = await post(
      "/shop/front/cm/train/user/info/add",
      values,
      true
    );
    message.success(msg);
    setShowUserConfirmModal(false);
  };
  const checkConfirm = (rule: any, value: any, callback: any) => {
    const idCard = form.getFieldValue("idCard");
    /**校验18位身份证号 */
    let _IDRe18 =
      /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    /**校验15位身份证号 */
    let _IDre15 =
      /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/;
    if (!_IDre15.test(idCard) && !_IDRe18.test(idCard)) {
      callback("身份证格式不正确!");
    } else {
      callback();
    }
  };
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };
  return (
    <Modal
      title="信息确认"
      visible={showUserConfirmModal}
      footer={null}
      onCancel={() => {
        setShowUserConfirmModal(false);
      }}
    >
      <p
        style={{
          textAlign: "center",
        }}
      >
        因为涉及到培训证书发放，需要完善您的信息。
      </p>
      <Form onFinish={confirmUserInfo} form={form} {...layout}>
        <Form.Item
          label="姓名"
          name="name"
          rules={[{ required: true, message: "请输入姓名" }]}
        >
          <Input placeholder="请输入姓名" />
        </Form.Item>
        <Form.Item
          label="身份证"
          name="idCard"
          rules={[
            {
              required: true,
              message: "身份证号码格式不正确，X为大写",
              validator: checkConfirm,
            },
          ]}
        >
          <Input placeholder="请输入身份证号" />
        </Form.Item>
        <Form.Item
          style={{
            paddingLeft: "230px",
          }}
        >
          <Button type="primary" htmlType="submit">
            确认
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default UserInfoConfirm;
