/*
 * @Author: cc
 * @LastEditTime: 2024-02-05 14:32:24
 */

import { LeftOutlined } from "@ant-design/icons";
import "./play.scss";
import { VideoProps } from "../../reducers/video";
import { connect } from "react-redux";
import { useQuery, HistoryPush } from "../../common/utils";
import { Modal, Input, Button, message, Space, Tooltip, } from "antd";
import { Icon } from "../../components/index";
import { post } from "../../common/fetch";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FinishCourse } from "../../components/index";
import defaultDoctor from "../../img/default_teacher.jpg";
const { TextArea } = Input
interface teacherProps {
  // 讲师姓名
  name: string;
  // 讲师头像
  avatar: string;
  // 工作单位
  employer: string;
  // 讲师简介
  desc: string;
}
interface courseListProps {
  // 课程名称
  title: string;
  // 播放链接
  url: string;
  // 视频时长
  durationText: string;
  // 讲师信息
  teacher: teacherProps;
  // 观看状态
  userStatus: number;
  // 0:需要考试 1:先不要
  ignoreExam: number;
  // id
  id: string;
  // 考试类型
  examType: number;
}
export default function Play(props: any) {
  let query = useQuery();
  const classId = query.get("classId");
  const projectId = query.get("projectId");
  const examType = Number(query.get("examType"));
  const [courseList, setCourseList] = useState<courseListProps[]>([]);
  const [refToken, setRefToken] = useState("");
  const [courseListIndex, setCourseListIndex] = useState(0);
  const [teacher, setTeacher] = useState({} as teacherProps);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [content, setContent] = useState("");
  const history = useHistory();
  /**
   * 获取课程详情
   */
  const getSectionInfo = async () => {
    (document.getElementById("aliLivePlayer") as HTMLElement).innerHTML = "";
    const {
      data: { courseList, refToken },
    } = await post(
      `/shop/front/cm/train/class/${classId}/project/${projectId}/info`,
      {},
      false
    );
    setCourseList(courseList);
    setTeacher(courseList[courseListIndex].teacher || {});
    setRefToken(refToken);
    var iframe = document.createElement("iframe");
    iframe.src = `${courseList[courseListIndex].url}&refToken=${refToken}`;
    iframe.style.height = "100%";
    document.getElementById("aliLivePlayer")?.appendChild(iframe);
  };
  useEffect(() => {
    getSectionInfo();
  }, []);
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const addNotes = () => {
    let param = {
      classId,
      projectId,
      courseId: courseList[courseListIndex].id,
      content,
    };
    if (content) {
      if (content.length > 1000) {
        message.info("笔记最多为1000字");
        return;
      }
      const url = `/shop/front/cm/train/user/learn/note/add`;
      post(url, param, false).then((res) => {
        message.info("提交成功");
        handleCancel();
      });
    } else {
      message.info("笔记不能为空");
    }
  }
  const onChange = ({ target: { value } }: any) => {
    setContent(value);
  };
  return (
    <>
      <div className="video_title">
        <p
          className="left"
          onClick={() => {
            history.go(-1);
          }}
        >
          <LeftOutlined />
        </p>
        <p
          style={{ fontSize: "20px" }}
          onClick={() => {
            history.push("/person");
          }}
        >
          个人中心
        </p>
      </div>
      <div className="playInfo_box">
        <div className="video_info">
          <div style={{ paddingBottom: "60px", overflowY: "scroll" }}>
            <div
              className="project_name_box"
              style={{
                background: "#999999",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                fontSize: "16px",
                color: "#fff",
                height: "42px",
                lineHeight: "42px",
              }}
            >
              课件列表
            </div>
            {courseList.map((item, index) => {
              return (
                <p
                  className="show-info"
                  onClick={() => {
                    (
                      document.getElementById("aliLivePlayer") as HTMLElement
                    ).innerHTML = "";
                    var iframe = document.createElement("iframe");
                    iframe.src = `${courseList[index].url}&refToken=${refToken}`;
                    iframe.style.height = "100%";
                    document
                      .getElementById("aliLivePlayer")
                      ?.appendChild(iframe);
                    setCourseListIndex(index);
                    setTeacher(item.teacher || {});
                  }}
                >
                  <div className="course_item">
                    <p
                      className={
                        index === courseListIndex
                          ? "check course_title"
                          : "course_title"
                      }
                    >
                      {item.title}
                    </p>
                    {item.teacher && <p>主讲老师：{item.teacher.name}</p>}
                    <div className="course_exam_status">
                      <p>时长：{item.durationText}</p>
                      {item.userStatus === 3 && (
                        <div className="finish_icon pass">已通过</div>
                      )}
                    </div>
                  </div>
                </p>
              );
            })}
          </div>
        </div>
        <div id="aliLivePlayer"></div>
        <div className="video_info">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <div
              style={{
                overflowY: "scroll",
              }}
            >
              <div
                style={{
                  background: "#999999",
                  color: "#fff",
                  fontSize: "16px",
                  height: "42px",
                  lineHeight: "42px",
                  padding: "0 10px",
                }}
              >
                讲师介绍
              </div>
              <div className="teacher_info">
                <div>
                  <img
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                    }}
                    src={teacher.avatar || defaultDoctor}
                    alt=""
                  />
                </div>
                <div style={{ marginLeft: "20px", fontSize: "16px" }}>
                  <div>{teacher.name || "暂无介绍"}</div>
                  <div>{teacher.employer || ""}</div>
                </div>
              </div>
              <div style={{ padding: "10px 8px" }}>
                <div>{teacher.desc}</div>
              </div>
            </div>
            {courseList[courseListIndex] &&
              <div style={{ display: "flex" }}>
                {courseList[courseListIndex].ignoreExam === 0 && courseList[courseListIndex].userStatus === 3 && (
                  <Button
                    size="large"
                    block
                    style={{
                      background: "#a6a6a6",
                      color: "#ffff",
                    }}
                  >
                    考试通过
                  </Button>
                )}
                {courseList[courseListIndex].userStatus === 2 && courseList[courseListIndex].ignoreExam === 0 && (
                    <Button
                      block
                      disabled={courseList[courseListIndex].userStatus !== 2}
                      size="large"
                      onClick={() => {
                        history.push(
                          `/exam?partnerClassId=${classId}&examType=${3}&projectId=${projectId}&courseId=${courseList[courseListIndex].id
                          }`
                        );
                      }}
                      style={{
                        background: "#537fd6",
                        color: "#ffff",
                      }}
                    >
                      开始考试
                    </Button>
                  )}
                {courseList[courseListIndex].ignoreExam === 0 && [0, 1].includes(courseList[courseListIndex].userStatus) && (
                  <Button block disabled size="large">
                    开始考试
                  </Button>
                )}
                <Button block type="primary" size="large" onClick={() => {
                  setContent('')
                  setIsModalVisible(true)
                }}>
                  笔记
                 </Button>
              </div>
            }
          </div>
        </div>
      </div>
      <Modal
        title="笔记"
        footer={null}
        closable={true}
        visible={isModalVisible}
        className="notesModal"
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <TextArea
          value={content}
          placeholder=""
          onChange={onChange}
          showCount
          maxLength={1000}
          autoSize={true}
          className="notes_modal"
          style={{ height: 300 }}
        />
        <div style={{ textAlign: "right", padding: "40px 0 20px" }}>
          <Button
            type="primary"
            onClick={() => {
              addNotes();
            }}
            style={{ cursor: "pointer" }}
          >
            提交
          </Button>
        </div>
      </Modal>
    </>
  );
}
