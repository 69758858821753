/*
 * @Author: cc
 * @LastEditTime: 2022-06-15 11:40:56
 */
import { useEffect, useState } from "react";
import {
  Table,
  Space,
  Pagination,
  Modal,
  Input,
  Button,
  message,
  Tooltip,
  Tabs
} from "antd";
import axios from "axios"
import { post, get } from "../../../common/fetch";
import { connect } from "react-redux";
import { DownloadOutlined } from "@ant-design/icons"
const { TextArea } = Input;
const { TabPane } = Tabs
interface NoteListProps {
  id: number;
  userId: number;
  instituteId: string;
  type: number;
  projectId: number;
  courseId: number;
  sectionId: number;
  content: string;
  sourceSystem: number;
  projectName: string;
  courseName: string;
  sectionName: string;
  updateTime: string;
}
function Notes(props: any) {
  const {
    projectType: { type },
  } = props;
  const [totalSize, setTotalSize] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [content, setContent] = useState("");
  const [id, setId] = useState(0);
  const [page, setPage] = useState(1);
  const [tab, setTab] = useState("1")
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onChange = ({ target: { value } }: any) => {
    setContent(value);
  };
  const columns = [
    {
      title: "培训名称",
      dataIndex: "projectName",
      key: "projectName",
    },
    {
      title: "课程名称",
      dataIndex: "courseName",
      key: "courseName",
    },
    {
      title: "课件名称",
      dataIndex: "sectionName",
      key: "sectionName",
    },
    {
      title: "笔记内容",
      dataIndex: "content",
      key: "content",
      width: "300",
      render: (text: string) => (
        // <div>{text.substring(0, 16)}</div>
        <Tooltip overlayClassName="overlay" title={text}>
          {text.substring(0, 16)}
        </Tooltip>
      ),
    },
    {
      title: "笔记时间",
      dataIndex: "updateTime",
      key: "updateTime",
    },
    {
      title: "操作",
      key: "action",
      render: (text: string, record: NoteListProps) => (
        <Space size="middle">
          <a
            style={{ color: "#1890ff" }}
            onClick={() => {
              showModal();
              setContent(record.content);
              setId(record.id);
            }}
          >
            编辑
          </a>
        </Space>
      ),
    },
  ];
  const columns2 = [
    {
      title: "课程名称",
      dataIndex: "courseName",
      key: "courseName",
    },
    {
      title: "课件名称",
      dataIndex: "sectionName",
      key: "sectionName",
    },
    {
      title: "笔记内容",
      dataIndex: "content",
      key: "content",
      width: "300",
      render: (text: string) => (
        // <div>{text.substring(0, 16)}</div>
        <Tooltip overlayClassName="overlay" title={text}>
          {text.substring(0, 16)}
        </Tooltip>
      ),
    },
    {
      title: "笔记时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "操作",
      key: "action",
      render: (text: string, record: NoteListProps) => (
        <Space size="middle">
          <a
            style={{ color: "#1890ff" }}
            onClick={() => {
              showModal();
              setContent(record.content);
              setId(record.id);
            }}
          >
            编辑
          </a>
        </Space>
      ),
    },
  ];
  const columns3 = [
    {
      title: "资源名称",
      dataIndex: "className",
      key: "className",
    },
    {
      title: "课程名称",
      dataIndex: "projectName",
      key: "projectName",
    },
    {
      title: "课件名称",
      dataIndex: "courseName",
      key: "courseName",
    },
    {
      title: "笔记内容",
      dataIndex: "content",
      key: "content",
      width: "300",
      render: (text: string) => (
        // <div>{text.substring(0, 16)}</div>
        <Tooltip overlayClassName="overlay" title={text}>
          {text.substring(0, 16)}
        </Tooltip>
      ),
    },
    {
      title: "笔记时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "操作",
      key: "action",
      render: (text: string, record: NoteListProps) => (
        <Space size="middle">
          <a
            style={{ color: "#1890ff" }}
            onClick={() => {
              showModal();
              setContent(record.content);
              setId(record.id);
            }}
          >
            编辑
          </a>
        </Space>
      ),
    },
  ];
  const [trainList, setTrainList] = useState<NoteListProps[]>([]);
  const getUserCourseWatchRecord = (page: number) => {
    let url = ``
    switch (tab) {
      case "1":
        url = "/shop/front/user/getTrainUserLearnNoteInfo";
        break
      case "2":
        url = "/shop/front/user/getResourceUserLearnNoteInfo"
        break
      case "3":
        url = "/shop/front/cm/train/get/user/learn/note/list"
        break
      default:
        url = "/shop/front/user/getUserLearnNoteInfo"
    }
    post(
      url,
      {
        currentPage: page,
        pageSize: 10,
      },
      true
    ).then((res) => {
      const { list, page } = res.data
      const { totalResultSize } = page;
      const arr = list.map(
        (item: NoteListProps, index: number) => {
          return {
            key: (page.currentPage - 1) * page.pageSize + (index + 1),
            ...item,
          };
        }
      );
      setTrainList(arr);
      setTotalSize(totalResultSize);
    });
  };
  const upDateNotes = () => {
    if (content) {
      if (content.length > 1000) {
        message.info("笔记最多为1000字");
        return;
      }
      let url = ``;
      switch (tab) {
        case "1":
          url = "/shop/front/updateUserLearnNote";
          break
        case "2":
          url = "/shop/front/updateUserLearnNote"
          break
        case "3":
          url = "/shop/front/cm/train/update/user/learn/note"
          break
        default:
          url = "/shop/front/updateUserLearnNote"
      }
      post(
        url,
        {
          id,
          content,
        },
        true
      ).then((res) => {
        message.success("修改成功");
        setIsModalVisible(false);
        getUserCourseWatchRecord(page);
      });
    } else {
      message.info("笔记不能为空");
    }
  };
  const tabChange = (key: any) => {
    setTab(key)
    setPage(1)
  }
  // 流式文件下载
  function downloadFileForExcel(res:any, name:string) {
    let blob1 = new Blob([res.data], {
      type: 'application/json'
    });
    console.log(blob1.text());
    blob1.text().then(function (value) {
      console.log(value);
      var obj = JSON.parse(value);
      console.log(obj.code);
      console.log(obj.msg);
      if (obj.code !== 0) {
        alert(obj.msg);
      }
    }).catch(function (err) {
      let blob = new Blob([res.data], {
        type: 'application/vnd.ms-excel'
      }); // 转化为blob对象
      let filename = name + '.xlsx';

      const blobURL = window.URL.createObjectURL(blob); // 将blob对象转为一个URL
      const tempLink = document.createElement('a'); // 创建一个a标签
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', filename); // 给a标签添加下载属性
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }
      document.body.appendChild(tempLink); // 将a标签添加到body当中
      tempLink.click(); // 启动下载
      document.body.removeChild(tempLink); // 下载完毕删除a标签
      window.URL.revokeObjectURL(blobURL);
    })
  }
  const exportFun = () => {
    let url = ``;
    switch (tab) {
      case "1":
        url = "/shop/front/user/exportTrainUserLearnNoteInfo";
        break
      case "2":
        url = "/shop/front/user/exportResourceUserLearnNoteInfo"
        break
      case "3":
        url = "/shop/front/cm/train/export/user/learn/note/list"
        break
      default:
        url = "/shop/front/user/exportTrainUserLearnNoteInfo"
    }
    axios({
      url: url,
      method: "get",
      responseType: "blob",
    }).then(res => {
      downloadFileForExcel(res, '笔记')
    })
  }
  useEffect(() => {
    getUserCourseWatchRecord(1);
  }, [tab]);
  return (
    <div>
      <div style={{ position: "relative" }}>
        <Tabs defaultActiveKey="1" onChange={tabChange}>
          <TabPane tab="培训笔记" key="1">
            <Table
              columns={columns}
              dataSource={trainList}
              pagination={false}
            ></Table>
            <div style={{ textAlign: "right", padding: "20px 0" }}>
              <Pagination
                total={totalSize}
                current={page}
                hideOnSinglePage={true}
                defaultPageSize={6}
                showSizeChanger={false}
                onChange={(page) => {
                  getUserCourseWatchRecord(page);
                  setPage(page);
                }}
              />
            </div>
          </TabPane>
          <TabPane tab="课程笔记" key="2">
            <Table
              columns={columns2}
              dataSource={trainList}
              pagination={false}
            ></Table>
            <div style={{ textAlign: "right", padding: "20px 0" }}>
              <Pagination
                total={totalSize}
                current={page}
                hideOnSinglePage={true}
                defaultPageSize={6}
                showSizeChanger={false}
                onChange={(page) => {
                  getUserCourseWatchRecord(page);
                  setPage(page);
                }}
              />
            </div>

          </TabPane>
          <TabPane tab="资源笔记" key="3">
            <Table
              columns={columns3}
              dataSource={trainList}
              pagination={false}
            ></Table>
            <div style={{ textAlign: "right", padding: "20px 0" }}>
              <Pagination
                total={totalSize}
                current={page}
                hideOnSinglePage={true}
                defaultPageSize={10}
                showSizeChanger={false}
                onChange={(page) => {
                  getUserCourseWatchRecord(page);
                  setPage(page);
                }}
              />
            </div>
          </TabPane>
        </Tabs>
        <DownloadOutlined style={{ position: "absolute", right: "15px", top: "15px", fontSize: "18px", color: "#2A82E4", fontWeight: "bolder", cursor: "pointer" }} onClick={exportFun} />
      </div>
      <Modal
        title="笔记"
        footer={null}
        closable={true}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <TextArea
          value={content}
          placeholder=""
          onChange={onChange}
          autoSize={true}
          showCount
          maxLength={1000}
          style={{ height: 300 }}
          className="notes_modal"
        />
        <div style={{ textAlign: "right", padding: "40px 0 20px" }}>
          <Button type="primary" onClick={upDateNotes}>
            修改
          </Button>
        </div>
      </Modal>
    </div>
  );
}
const getProjectType = (state: {
  projectType: { type: number; activityId?: string };
}) => {
  return {
    projectType: state.projectType,
  };
};
export default connect(getProjectType)(Notes);
