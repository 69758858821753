/*
 * @Author: cc
 * @LastEditTime: 2023-12-28 10:01:16
 */
import { useEffect, useState } from "react";
import "./train.scss";
import { message, Tabs } from "antd";
import { Icon, ProjectList, PlatformResource } from "../../components/index";
import { post } from "../../common/fetch";
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { HistoryPush } from "../../common/utils";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import { getCookie } from "../../common/utils";
import "swiper/components/navigation/navigation.min.css";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);
export interface ActivityHumanInstituteListProps {
  id: number;
  sourceId: string;
  activityId: string;
  instituteId: string;
  instituteName: string;
}
export interface TrainListProps {
  projectPicture: string;
  projectTimeRange: string;
  courseSource: number;
  projectName: string;
  projectId: number;
  studyStatus: number;
  endTime: string;
  endTimeText: string;
  startTimeText: string;
  studyStatusText: string;
  projectType: number;
  needSelectInstituteFlag?: number;
  activityHumanInstituteList?: ActivityHumanInstituteListProps[];
}
export interface CourseListProps {
  courseDuration: number;
  courseDurationText: string;
  courseId: number;
  courseName: string;
  coursePicture: string;
  coursePrice: string;
  courseVideoNum: number;
  showOrder: number;
  sectionId: number;
  type: string;
  courseStatus: number;
  hasExamRecordFlag: number;
}
export interface StudyActionListProps {
  leaveTimeLast: string;
  leaveTimeLastStr: string;
  liveId: string;
  roomId: string;
  watchTimeSum: number;
  watchTimeSumStr: string;
}
export interface LiveLisrProps {
  courseLogo: string;
  courseName: string;
  liveRoomId: string;
  liveStartTimeStr: string;
  ccUserId: string;
  teacherNames: string;
}
export interface PublicListProps {
  courseDuration: number;
  courseDurationText: string;
  courseId: number;
  courseName: string;
  coursePicture: string;
  coursePrice: string;
  courseVideoNum: number;
  showOrder: number;
  sectionId: number;
  type: string;
  courseStatus: number;
  hasExamRecordFlag: number;
}
export interface newCouseListProps {
  courseAllPathPicture: string;
  courseDuration: number;
  courseDurationText: string;
  courseId: number;
  courseName: string;
  coursePicture: string;
  courseVideoNum: number;
  showOrder: number;
}
export interface ActivityInfoProps {
  activityId: string;
}
interface BannerProps {
  id: string;
  // 指定类型 1.不指定 2.显示指定 3.点击指定
  applyType: "1" | "2" | "3";
  // banner地址
  allPathLink: string;
  // 是否在时间范围
  timeRangeFlag: string;
  // 是否有权限
  hasAuthorized?: string;
  // 登录状态
  loginStatus?: string;
  moreUrl: string;
}
export interface platFormProps {
  // 课程名称
  name: string;
  // 选修时长
  notRequiredPeriod: number;
  // 必修时长
  requiredPeriod: number;
  // 价格
  salePriceText: string;
  // 是否已购买
  buyClassFlag: number;
  // id
  id: string;
  // 价格
  amount: number;
  // 证书状态
  certType: number;
}
const MAXLENGTH = 8;
let trainListLength = 1;
let myCourseListLength = 1;
let publicCourseListLength = 1;
let myLiveListLength = 1;
let platFormListLength = 1;
let playBackListLength = 1;
export default function Train() {
  const history = HistoryPush();
  const [trainList, setTrainList] = useState<TrainListProps[]>([]);
  const [myCourseList, setMyCourseList] = useState<CourseListProps[]>([]);
  const [myLiveList, setMyLiveList] = useState<LiveLisrProps[]>([]);
  const [platFormList, setPlatFromList] = useState<platFormProps[]>([]);
  const [publicCourseList, setPublicCourseList] = useState<PublicListProps[]>(
    []
  );
  const [bannerInfo, setBannerInfo] = useState<BannerProps[]>([]);
  const [playBackList, setPlatBackList] = useState<LiveLisrProps[]>([]);
  // 推荐课程
  const getMyProjectList = () => {
    post(`/shop/front/getMyProjectList`, {}, true).then((res) => {
      const { data } = res;
      const projectIdArr = [484, 485, 486, 419, 420];
      let handleArr = data.filter(
        (item: any) => !projectIdArr.includes(item.projectId)
      );
      trainListLength = handleArr.length;
      setTrainList(handleArr.splice(0, MAXLENGTH));
    });
  };
  /**
   * 获取平台资源列表
   */
  const getPlatFromList = async () => {
    const {
      data: { list, page },
    } = await post(
      `/shop/front/cm/train/class/list`,
      {
        pageNum: 1,
        pageSize: 100,
      },
      true
    );
    setPlatFromList(list);
    platFormListLength = page;
  };
  // 点播课程
  const getMyCourseList = () => {
    post(
      `/shop/front/getMyCourseList`,
      {
        currentPage: 1,
        pageSize: MAXLENGTH,
      },
      true
    ).then((res) => {
      if (res.data) {
        const { data } = res;
        const { totalResultSize } = data.page;
        myCourseListLength = totalResultSize;
        const { myCourseList } = data;
        setMyCourseList(myCourseList);
      }
    });
  };
  // 直播课程
  const getMyLiveist = () => {
    post(
      `/shop/front/getPcLiveCourseList`,
      {
        currentPage: 1,
        pageSize: MAXLENGTH,
      },
      true
    ).then((res) => {
      if (res.data) {
        const { data } = res;
        const { totalResultSize } = data.page;
        myLiveListLength = totalResultSize;
        const { list } = data;
        if (list) {
          setMyLiveList(list);
        }
      }
    });
  };
  // 公益课程
  const getPublicCourseList = () => {
    post(
      `/shop/front/getPublicCourseList`,
      {
        currentPage: 1,
        pageSize: MAXLENGTH,
      },
      true
    ).then((res) => {
      const { data } = res;
      const { totalResultSize } = data.page;
      publicCourseListLength = totalResultSize;
      const { publicCourseList } = data;
      setPublicCourseList(publicCourseList);
    });
  };
  // 获取banner
  const getBannerList = () => {
    post(`/shop/front/indexBannerList`, {}, true).then((res) => {
      const { data } = res;
      setBannerInfo(
        data.map((item: BannerProps) => {
          const {
            hasAuthorized,
            loginStatus,
            timeRangeFlag,
            allPathLink,
            moreUrl,
          } = item;
          return {
            hasAuthorized,
            loginStatus,
            timeRangeFlag,
            allPathLink,
            moreUrl,
          };
        })
      );
    });
  };
  // 轮播图跳转逻辑
  const jumpHref = (item: BannerProps) => {
    // 点击指定
    if (item.applyType === "3") {
      // 不在时间范围
      if (item.loginStatus === "0") {
        history.push("/login");
      } else {
        // 有权限
        if (item.hasAuthorized === "1") {
          //不在时间范围
          if (item.timeRangeFlag === "0") {
            message.info("不在时间范围");
          } else {
            window.location.href = item.moreUrl;
          }
        } else {
          message.info("您没有观看权限");
        }
      }
    } else {
      // 不在开放时间
      if (item.timeRangeFlag === "0") {
        message.info("不在开放时间内");
      } else {
        window.location.href = item.moreUrl;
      }
    }
  };
  const getPlayBackLive = () => {
    post(
      `/shop/front/getPcLiveCoursePlayBackList`,
      {
        currentPage: 1,
        pageSize: MAXLENGTH,
      },
      true
    ).then((res) => {
      if (res.data) {
        const { data } = res;
        const { totalResultSize } = data.page;
        playBackListLength = totalResultSize;
        const { list } = data;
        setPlatBackList(list);
      }
    });
  };
  useEffect(() => {
    let sid = getCookie("X-MVW-userID");
    if (sid) {
      getMyProjectList();
      getMyCourseList();
      getPublicCourseList();
      getMyLiveist();
      getPlayBackLive();
    }
    getPlatFromList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    getBannerList();
  }, []);
  return (
    <>
      <div className="tarin_box">
        {/* <img src={wuyi_workers_day} alt="" style={{width:"100%",cursor:"pointer"}} onClick={()=>{
          window.location.href = "http://qt.mvwchina.com/vj/tUzMD5y.aspx"
        }}/> */}
        {bannerInfo && (
          <Swiper
            navigation={true}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            style={{ margin: "20px 0 60px 0" }}
            initialSlide={1}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
          >
            {bannerInfo.map((item, index) => (
              <SwiperSlide
                key={index}
                onClick={(e: any) => {
                  e.stopPropagation();
                  jumpHref(item);
                }}
              >
                <img
                  src={item.allPathLink}
                  alt="banner"
                  style={{
                    width: "100%",
                    height: "300px",
                    borderRadius: "12px",
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {(trainListLength || myLiveListLength  ||  myCourseListLength  )  && (
          <div>
            <div>
              <div className="more_class">
                <div className="my_train">
                  {/* <Icon icon="graduation-cap" size="2x" theme="dark" /> */}
                  <h3>机 构 资 源</h3>
                </div>
                <Tabs
                  defaultActiveKey="1"
                  centered
                  style={{marginTop:"20px"}}
                >
                  <Tabs.TabPane tab="机构培训" key="1">
                    <p style={{textAlign:"right",display:"block"}}>
                      {trainListLength > MAXLENGTH && (
                        <Link
                          to={`/classList?type=train`}
                          style={{ color: "#777777" }}
                        >
                          查看更多&gt;&gt;
                        </Link>
                      )}
                    </p>
                    {<ProjectList classList={trainList} />}
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="直播课程" key="2">
                    <p style={{textAlign:"right",display:"block"}}>
                      {(myLiveListLength > MAXLENGTH ||
                        (myLiveListLength === 0 && playBackListLength > MAXLENGTH) ||
                        (myLiveListLength > 0 &&
                          myLiveListLength < 4 &&
                          playBackListLength > 0)) && (
                          <Link
                            to={`/classList?type=live`}
                            style={{ color: "#777777" }}
                          >
                            查看更多&gt;&gt;
                          </Link>
                        )}
                    </p>
                    {myLiveListLength > 0 ? (
                      <ProjectList classList={myLiveList} type={"live"} />
                    ) : (
                      <ProjectList classList={playBackList} type={"live"} />
                    )}
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="点播课程" key="3">
                    <p style={{textAlign:"right",display:"block"}}>
                      {myCourseListLength > MAXLENGTH && (
                        <Link
                          to={`/classList?type=ourHospital`}
                          style={{ color: "#777777" }}
                        >
                          查看更多&gt;&gt;
                        </Link>
                      )}
                    </p>
                    <ProjectList classList={myCourseList} type={"ourHospital"} />
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        )}
        {platFormList.length > 0 && (
          <div>
            <div className="more_class">
              <div className="my_train">
                {/* <Icon icon="book" size="2x" theme="dark" /> */}
                <h3>平 台 资 源</h3>
                {platFormListLength > MAXLENGTH && (
                  <Link
                    to={`/platformList?type=platform`}
                    style={{ color: "#777777"}}
                  >
                    查看更多&gt;&gt;
                  </Link>
                )}
              </div>
            </div>
            <PlatformResource
              classList={platFormList}
              getPlatFromList={getPlatFromList}
            />
          </div>
        )}
      </div>
    </>
  );
}
