/*
 * @Author: changcheng
 * @LastEditTime: 2024-03-27 19:48:17
 */
import React, { useEffect, FC, useState } from "react";
import { post } from "../../../../common/fetch";
import { useHistory } from "react-router-dom";
import { message } from "antd";
type propsType = {
  projectId: string;
  projectInfo: any;
};
type topicCourseType = {
  topicImage: string;
  topicTimeRange: string;
  hasLearnAuthorityFlag: number;
  topicId: string;
  topicName: string;
  studyStatus: number;
  topicWatchTimeText: string;
  topicProgress: string;
  topicDurationText: string;
};
const topicProjectStatus = new Map([
  ["0", "未开始"],
  ["1", "进行中"],
  ["2", "已结束"],
]);
export const TopicCourseDouble: FC<propsType> = (props) => {
  const history = useHistory();
  const [topicInfoList, setTopicInfoList] = useState<topicCourseType[]>([]);
  const { projectId } = props;
  // 获取单层专题列表数据
  const getSingleTopicList = async () => {
    post(`/shop/front/project/${projectId}/topic/list`, {}, true).then(
      (res: any) => {
        const { topicInfoList } = res.data;
        setTopicInfoList(
          topicInfoList.filter(
            (item: topicCourseType) => item.hasLearnAuthorityFlag === 1
          )
        );
      }
    );
  };
  const checkTopicProject = (item: topicCourseType) => {
    const { topicId, studyStatus } = item;
    if (studyStatus === 1) {
      history.push(`/topicInfo?projectId=${projectId}&topicId=${topicId}`);
    } else {
      message.info(`专题项目${topicProjectStatus.get(studyStatus.toString())}`);
    }
  };
  useEffect(() => {
    getSingleTopicList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="topicInfoContainer">
      {topicInfoList.map((item, key) => (
        <div
          key={key}
          onClick={() => {
            checkTopicProject(item);
          }}
          style={{
            width: "270px",
            margin: "12px",
          }}
        >
          <img src={item.topicImage} alt="" width={"100%"} />
          {item.topicTimeRange && (
            <span className="range">{item.topicTimeRange}</span>
          )}
          <h6>{item.topicName}</h6>
          <p className="topic_progress">
            {item.topicDurationText !== "00:00:00" && (
              <span>专题时长：{item.topicDurationText}</span>
            )}
            {Number(item.topicProgress) > 0 && (
              <span>学习进度：{item.topicProgress}%</span>
            )}
          </p>
        </div>
      ))}
    </div>
  );
};
