/*
 * @Author: cc
 * @LastEditTime: 2023-11-13 10:33:39
 */
import React, { useEffect } from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import { IsPC } from "../common/utils";
import { routeConfig, changeNavStatus } from "../common/utils";
import {message} from "antd"
import { Footer } from "../components/index";
import Header from "../components/Header/Header";
import Login from "../pages/login/login.tsx";
import ProjectInfo from "../pages/projectInfo/projectInfo";
import Personal from "../pages/personal/personal";
import Train from "../pages/tarin/train";
import Play from "../pages/play/play";
import ClassList from "../pages/classList/classList";
import TopicInfo from "../pages/topicInfo/topicInfo";
import DataQuery from "../pages/projectInfo/dataQuery";
import CertificateRecords from "../pages/projectInfo/certificateRecords";
import Comment from "../pages/comment/comment";
import Search from "../pages/Search/search";
import CollectionInfo from "../pages/collectionInformation";
import Register from "../pages/register/register.tsx";
import WechatPay from "../pages/wechatPay/index";
import PayProjectInfo from "../pages/payProjectInfo/projectInfo.tsx";
import CePayProjectInfo from "../pages/cePayProjectInfo/projectInfo.tsx";
import PlatformList from "../pages/platformList/index.tsx";
import PassWord from "../pages/Password/index";
import PlatFromPlay from "../pages/platfromPlay/index";
import Error from "../pages/error"
import { CCvideoControlClass, CCvideoControlInstance } from "../common/video";
import Exam from "../pages/exam";
import eventEmitter from '../eventEmitter';
// import { hot } from 'react-hot-loader'
export const routes = [
  {
    path: "/platfromplay",
    component: PlatFromPlay,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/modifyPassWord",
    component: PassWord,
  },
  {
    path: "/collectionInfo",
    component: CollectionInfo,
  },
  {
    path: "/projectInfo",
    component: ProjectInfo,
  },
  {
    path: "/dataQuery",
    component: DataQuery,
  },
  {
    path: "/certificateRecords",
    component: CertificateRecords,
  },
  {
    path: "/topicInfo",
    component: TopicInfo,
  },
  {
    path: "/train",
    component: Train,
  },
  {
    path: "/classList",
    component: ClassList,
  },
  {
    path: "/personal",
    component: Personal,
  },
  {
    path: "/play",
    component: Play,
  },
  {
    path: "/comments",
    component: Comment,
  },
  {
    path: "/search",
    component: Search,
  },
  {
    path: "/exam",
    component: Exam,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/wechatPay",
    component: WechatPay,
  },
  {
    path: "/payProjectInfo",
    component: PayProjectInfo,
  },
  {
    path: "/cePayProjectInfo",
    component: CePayProjectInfo,
  },
  {
    path: "/platformList",
    component: PlatformList,
  },
  {
    path: "/errorPage/:errcode",
    component: Error,
  },
  {
    path: "/",
    component: Train,
  },
  {
    path: "/passWord",
    component: PassWord,
  },
  {
    path: "*",
    component: Train,
  },
];
const RouterBoxSty = {
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};
function setNavInfo(route) {
  const path = route.path;
  if (routeConfig[path]) {
    const { show } = routeConfig[path];
    changeNavStatus({
      show,
    });
  }
}
function handleUrl(path) {
  let pathArr = [
    "/",
    "/projectInfo",
    "/personal",
    "/play",
    "/train",
    "/login",
    "/platfromplay",
  ];
  if (pathArr.includes(path) && !IsPC()) {
    if (path === "/train") {
      window.location.href = `${window.location.origin}/mobile/#/`;
    } else {
      window.location.href = `${window.location.origin}/mobile/${window.location.hash}`;
    }
  }
}
const handleLoginInvalid = () => {
  message.info('登录失效，请重新登录')
  let href = `${window.location.origin}${window.location.pathname}#/login`;
  window.location.href = href;
};
function RouteWithSubRoutes(route) {
  const { path } = route;
  useEffect(() => {
    eventEmitter.on('loginInvalid', handleLoginInvalid);
    setNavInfo(route);
    return () => {
      eventEmitter.off('loginInvalid', handleLoginInvalid);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const hideFooter = ["/play", "/comments", "/exam", "/platfromplay"];
  return (
    <Route
      path={route.path}
      render={(props) => {
        // 如果是在手机环境直接重定向手机端地址
        if (!IsPC()) {
          handleUrl(route.path);
        } else {
          return (
            <>
              <route.component {...props} routes={route.routes} />
              {!hideFooter.includes(path) && <Footer />}
            </>
          );
        }
      }}
    />
  );
}
let RouteConfigExample = (props) => {
  return (
    <HashRouter>
      <div style={{ ...RouterBoxSty }}>
        {IsPC() && <Header />}
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
        {/* <UserAgentWaring /> */}
      </div>
    </HashRouter>
  );
};
export default RouteConfigExample;
