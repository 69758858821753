/*
 * @Author: cc
 * @LastEditTime: 2023-11-21 13:13:28
 */
import { useEffect, useState } from "react";
import logo from "../../img/logo.png";
import "./register.scss";
import { UserOutlined, UnlockOutlined } from "@ant-design/icons";
import { Form, Input, Button, message } from "antd";
import { encode } from "js-base64";
import fetch from "cross-fetch";
import { Link } from "react-router-dom";
import { HistoryPush } from "../../common/utils";
import store from "../../reducers/index";
import { post, get } from "../../common/fetch";
const { Search } = Input;
interface formProps {
  mobile: string;
  password: string;
  verifyCode: string;
  newPassword: string;
  smsCode: string;
}
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const Register = () => {
  let history = HistoryPush();
  const [form] = Form.useForm();
  const [randomImgUrl, setRandomImgUrl] = useState("");
  useEffect(() => {
    getRandomCode();
  }, []);
  const getRandomCode = async () => {
    const { data } = await get(`/user/api/verify/code`, {}, false);
    setRandomImgUrl(data);
  };
  /**
   * 注册
   * @param values
   * @returns
   */
  const onFinish = (values: formProps) => {
    const { mobile, password, newPassword, smsCode } = values;
    if (password !== newPassword) {
      message.info("两次输入密码不一致");
      return;
    }
    post(
      `/user/api/common/user/register`,
      {
        mobile: encode(mobile),
        password: encode(password),
        smsCode: encode(smsCode),
      },
      false
    ).then(
      (result: any) => {
        history.push("/login");
      },
      (err) => {
        getRandomCode();
      }
    );
  };
  /**
   * 发送验证码
   * @returns
   */
  const sendSmsCode = async () => {
    const { mobile, verifyCode } = form.getFieldsValue();
    if (!mobile) {
      message.info("请输入手机号");
      return;
    }
    if (!verifyCode) {
      message.info("请输入图形验证码");
      return;
    }
    const { code, msg } = await post(
      "/user/api/ce/send/online/sms/code",
      {
        mobile: encode(mobile),
        smsTitle: "医视界",
        verifyCode: encode(verifyCode),
      },
      false
    );
    if (code === 0) {
      message.success("发送成功");
    }
  };
  return (
    <div className="register_box">
      <div className="business">
        <img src={logo} alt="" />
      </div>
      <div className="submit_box">
        <p
          style={{
            color: "blue",
            marginTop: "12px",
            fontSize: "18px",
          }}
        >
          注册
        </p>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            name="mobile"
            rules={[{ required: true, message: "请输入手机号" }]}
          >
            <Input
              prefix={
                <div className="icon_box">
                  <UserOutlined />
                </div>
              }
              placeholder="手机号"
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item
            name="verifyCode"
            rules={[{ required: true, message: "请输入图形验证码" }]}
          >
            <Search
              placeholder="请输入图形验证码"
              enterButton={
                <img
                  src={randomImgUrl}
                  alt="验证码"
                  style={{ width: "100px", height: "38px" }}
                  onClick={getRandomCode}
                />
              }
              style={{ padding: "0" }}
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="smsCode"
            rules={[{ required: true, message: "请输入短信验证码" }]}
          >
            <Search
              placeholder="请输入短信验证码"
              enterButton={
                <span
                  style={{ width: "100px", height: "38px", lineHeight: "38px" }}
                >
                  获取验证码
                </span>
              }
              style={{ padding: "0" }}
              size="large"
              onSearch={() => {
                sendSmsCode();
              }}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "请确认您的密码" }]}
          >
            <Input.Password
              prefix={
                <div className="icon_box">
                  <UnlockOutlined />
                </div>
              }
              placeholder="密码"
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item
            name="newPassword"
            rules={[{ required: true, message: "请输入您的密码" }]}
          >
            <Input.Password
              prefix={
                <div className="icon_box">
                  <UnlockOutlined />
                </div>
              }
              placeholder="确认密码"
            />
          </Form.Item>
          <Form.Item
            style={{
              textAlign: "center",
            }}
          >
            <Button
              style={{ height: "40px" }}
              onClick={() => {
                history.go(-1);
              }}
            >
              返回
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{ height: "40px", marginLeft: "20px" }}
            >
              确认
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default Register;
