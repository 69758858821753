/*
 * @Author: cc
 * @LastEditTime: 2021-05-14 14:35:48
 */
import { OrganProps } from "../actions/index";
const InitIOrganInfo = {
  currentInstituteId: "",
  currentInstituteName: "",
  instituteRoleList: [],
};
export const organInfo = (state = InitIOrganInfo, action: OrganProps) => {
  switch (action.type) {
    case "SET_ORGAN_INFO":
      return {
        ...action,
      };
    default:
      return state;
  }
};
