/*
 * @Author: cc
 * @LastEditTime: 2023-10-27 10:12:45
 */
import { useState, useEffect } from "react";
import "./personal.scss";
import { Menu, Icon } from "../../components/index";
import { Breadcrumb, message } from "antd";
import { HistoryPush } from "../../common/utils";
import MyTrain from "./components/myTrain";
import MyCourse from "./components/myCourse";
import Notes from "./components/notes";
// import TeachActivity from "./components/teachActivity"
import { useQuery } from "../../common/utils";
import Certificate from "./components/certificate";
import { post } from "../../common/fetch";
import { connect } from "react-redux";
import { UserProps } from "../../reducers/video";
import { LoginOutlined } from "@ant-design/icons";
import { OrganProps } from "../../actions/index";
import MyOrder from "./components/myOrder";
import PurchasedOrder from "./components/purchasedOrder";
import MyNotes from "./components/notes"
import TeachActivity from "./components/teachActivity"
interface ProjectPageSetInfo {
  showLogoFlag?: number;
  showIndexUrlFlag?: number;
  showPersonCenterFlag?: number;
  projectAllPathLogo: any;
}
const projectPageSetInfo = JSON.parse(
  sessionStorage.getItem("projectPageSetInfo") || "{}"
);
function RightIcon() {
  return <Icon icon="angle-right" size="1x" theme="dark" />;
}
interface ProjectInfoProps {
  projectCount: number;
  projectWatchTimeText: string;
}
function Personal(props: any) {
  // const query = useQuery();
  /**获取导航跳转地址 */
  // const nav= query.get("nav") as string;
  const {
    userInfo,
    setUserInfo,
    projectType: { type },
  } = props;
  const { name, avatar } = userInfo;
  const history = HistoryPush();
  const [navIndex, setNavIndex] = useState("0");
  const [projectInfo, setProjectInfo] = useState<ProjectInfoProps>(
    {} as ProjectInfoProps
  );
  // 获取学习信息
  const getMyProjectRecordList = () => {
    const url = `/shop/front/user/getMyProjectSummaryInfo`;
    post(url, {}, false).then((res) => {
      const { data } = res;
      const { projectCount, projectWatchTimeText } = data;
      setProjectInfo({ projectCount, projectWatchTimeText });
    });
  };
  // 重新获取用户信息
  const getUserInfo = () => {
    post(`/shop/front/userInfo`, {}, false).then((result) => {
      const { data } = result;
      const { name, avatar, mobile } = data;
      setUserInfo({
        type: "SET_USER_INFO",
        name,
        mobile,
        avatar,
      });
    });
  };
  const upDateUserInfo = (dispatch: any) => {
    return {
      upDateUserInfo: (param: UserProps) => {
        dispatch({ ...param });
      },
      updateOrganInfo: (param: OrganProps) => {
        dispatch({ ...param });
      },
    };
  };
  // 退出登录
  const loginOut = () => {
    upDateUserInfo({
      type: "SET_USER_INFO",
      name: "",
      mobile: "",
      avatar: "",
    });
    document.cookie = "";
    post(`/shop/front/exit`, {}, true).then((res) => {
      message.info("退出登录成功");
      let fromUrl = sessionStorage.getItem("fromUrl");
      if (fromUrl) {
        window.location.href = atob(fromUrl);
      } else {
        history.push("/login");
      }
    });
  };
  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getMyProjectRecordList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="personal_box">
        <div className="menu_box">
          <div className="personal_nav">
            <Breadcrumb separator="/" style={{ fontSize: "18px" }}>
              {projectPageSetInfo.showIndexUrlFlag === 1 && (
                <Breadcrumb.Item href="/#/train">首页</Breadcrumb.Item>
              )}
              <Breadcrumb.Item>个人中心</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div style={{background: '#1890ff',width:"100%",padding:"10px"}}>
            <div>
              <p>
                <img src={avatar} alt="" style={{ width: "80px" }} />
              </p>
              <p style={{ color: "#ffff" }}>{name}</p>
            </div>
            <div className="menu_top">
              <p>
                <span>{projectInfo.projectCount}</span>
                <span>学习项目</span>
              </p>
              <p>
                <span>{projectInfo.projectWatchTimeText}</span>
                <span>学习时长</span>
              </p>
            </div>
            <div>
              <LoginOutlined
                style={{ color: "#fff", fontSize: "24px" }}
                onClick={loginOut}
              />
            </div>
          </div>
          <Menu
            index={navIndex}
            defaultOpenSubMenus={[]}
            mode="vertical"
            onSelect={(e) => {
              setNavIndex(e);
            }}
          >
            <Menu.Item>
              机构培训
              <RightIcon />
            </Menu.Item>
            <Menu.Item>
              机构课程
              <RightIcon />
            </Menu.Item>
            <Menu.Item>
              教学活动
              <RightIcon />
            </Menu.Item>
            <Menu.Item>
              我的笔记
              <RightIcon />
            </Menu.Item>
            {/* <Menu.Item>
          我的考试
          <RightIcon />
        </Menu.Item> */}
            <Menu.Item>
              我的证书
              <RightIcon />
            </Menu.Item>
            <Menu.Item>
              我的订单
              <RightIcon />
            </Menu.Item>
            <Menu.Item>
              个人培训
              <RightIcon />
            </Menu.Item>
          </Menu>
        </div>
        <div className="study_data_box">
          {navIndex === "0" && <MyTrain />}
          {navIndex === "1" && <MyCourse />}
          {navIndex === "2" && <TeachActivity />}
          {navIndex === "3" && <MyNotes />}
          {navIndex === "4" && <Certificate {...props} />}
          {navIndex === "5" && <MyOrder />}
          {navIndex === "6" && <PurchasedOrder />}
        </div>
      </div>
    </>
  );
}
const getNavStatus = (state: {
  userInfo: UserProps;
  projectType: { type: number; activityId?: string };
}) => {
  return {
    userInfo: state.userInfo,
    projectType: state.projectType,
  };
};

const setUserInfo = (dispatch: any) => {
  return {
    setUserInfo: (param: any) => {
      dispatch({ ...param });
    },
  };
};
export default connect(getNavStatus, setUserInfo)(Personal);
