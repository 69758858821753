import { useEffect, useState } from "react";
import { post } from "../../../common/fetch";
import {
    CourseListProps,
    PublicListProps,
    StudyActionListProps,
} from "../../tarin/train";
import { Pagination, Menu, Table, Space, Tooltip, Modal, Button } from "antd";
import { HistoryPush } from "../../../common/utils";
export default function MyCourse() {
    let history = HistoryPush();
    const columns = [
        {
            title: "活动名称",
            dataIndex: "activityName",
            key: "activityName",
        },
        {
            title: "场次名称",
            dataIndex: "className",
            key: "className",
        },
        {
            title: "活动时间",
            dataIndex: "classStartTime",
            key: "classStartTime",
        },
        {
            title: "签到时间",
            dataIndex: "signInTime",
            key: "signInTime",
        },
        {
            title: "签退时间",
            dataIndex: "signOutTime",
            key: "signOutTime",
        },
    ];

    const [totalSize, setTotalSize] = useState(1);
    const [list, setList] = useState<
        StudyActionListProps[]
    >([]);
    const [currentPage, setCurrentPage] = useState(1);
    

    //获取直播记录
    const getList = () => {
        post(
            `/shop/front/ta/activity/view/list`,
            {
                currentPage: currentPage,
                pageSize: 10,
            },
            true
        ).then((res) => {
            if (!res.data) return;
            const { userScanQRCodeRecordList, page } = res.data;
            const { totalResultSize } = page;
            setTotalSize(totalResultSize);
            setList(userScanQRCodeRecordList);
        });
    };
    useEffect(()=>{
        getList();
    },[currentPage])

    return (
        <div className="class-content">
            <Table
                dataSource={list}
                columns={columns}
                pagination={{
                    current:currentPage,
                    pageSize:10,
                    onChange:(page) => {
                        setCurrentPage(page);
                    },
                    total:totalSize
                }}
            ></Table>

        </div>
    );
}
