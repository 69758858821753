const InitProjectType = {
  type: 1,
  activityId: "",
};
export const projectType = (
  state = InitProjectType,
  action: {
    type: string;
    payLoad: {
      type: number;
      activityId?: string;
    };
  }
) => {
  switch (action.type) {
    case "CHANGE_PROJECTTYPE":
      return {
        ...action.payLoad,
      };
    default:
      return state;
  }
};
