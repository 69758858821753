import store from "../reducers/index";
import { post } from "../common/fetch";
import { Modal } from "antd";
import { log } from "console";
// 传入参数
// @initVideoFn
export class CCvideoControlClass {
  public count: number = this.getNumber();
  private ccVideoUrl = `https://yth.mvwchina.com/cc/videourl`; // cc的video接口
  private API_KEY = "Y7gxBFDbz5yBU36vDXCv0RbIjyykV5Dt";
  private userId = "D71B0368E20B4CF5"; // 用户id
  private showConfirm = false; // 判断是否在弹窗中
  // 获取当前随机次数
  getNumber(): number {
    const { videoStatus } = store.getState();
    const { randomPopUpNum = 0 } = videoStatus;
    return randomPopUpNum;
  }
  // 全局计时器
  start(video: any, position: any, time: any, videoStatus: any) {
    const {
      onHookTipInfo,
      videoUniqueCode,
      projectId,
      topicId,
      courseId,
      sectionId,
    } = videoStatus;
    console.log(window.pauseTimer, this.count);
    if (window.pauseTimer === null && this.count > 0) {
      // 随机数
      let restTime = time - parseInt(position);
      let randonNum = Math.floor(Math.random() * (restTime - 0) + 0);
      window.pauseTimer = setInterval(() => {
        console.log("多少秒之后暂停弹窗", randonNum);
        console.log("剩余弹出次数", this.count);
        if (randonNum <= 0) {
          video.pause(); // 暂停视频
          this.pause(); // 清除计时器
          setTimeout(() => {
            let popupUniqueCode = new Date().getTime() + "";
            post(
              "/shop/front/addUserWatchPopupLog",
              {
                projectId,
                topicId,
                courseId,
                sectionId,
                videoUniqueCode,
                popupUniqueCode,
                popupType: 1,
              },
              false
            );
            alert(onHookTipInfo);
            this.count--;
            post(
              "/shop/front/addUserWatchPopupLog",
              {
                projectId,
                topicId,
                courseId,
                sectionId,
                videoUniqueCode,
                popupUniqueCode,
                popupType: 2,
              },
              false
            );
          }, 800);
        } else {
          randonNum--;
        }
      }, 1000);
    }
  }
  // 暂停视频
  pause() {
    clearInterval(window.pauseTimer);
    window.pauseTimer = null;
  }
  // 重置视频
  reset() {
    // 设置弹出次数
    this.count = this.getNumber();
  }
  // 记录视频进度
  async watch(watchEventType: number) {
    const {
      videoStatus,
      projectType: { type, activityId },
    } = store.getState();
    const {
      courseId,
      sectionId,
      projectId,
      preventOnHookFlag,
      videoType,
      topicId,
      watchStartTime = 0,
      videoDuration, // 总时长
    } = videoStatus;
    if (!window.ccPlayerStatus) return;
    let video = window.ccPlayerStatus;
    // 获取视频进度
    let position = window.ccPlayerStatus.getPosition() || watchStartTime; // 获取视频进度
    // 兼容观看记录
    if (position >= videoDuration) {
      position = videoDuration;
    }
    // 调用随机弹窗的方法
    if (preventOnHookFlag === 2) {
      this.start(video, position, videoDuration, videoStatus);
    }
    let url = "";
    let param = {
      courseId,
      sectionId,
      projectId: projectId ? projectId : "",
      videoDuration,
      watchAt: position,
      topicId: "",
      watchEventType,
    };
    // 如果是看到最后，跳过一次请求
    if (watchEventType === 2 && position === video.getDuration()) {
      return;
    }
    if (watchEventType === 3) {
      // 如果是视频结束状态
      Object.assign(param, { totalWatchTime: position - watchStartTime });
    }
    if (videoType === "myself" || videoType === "ourHospital") {
      url = "/shop/institute/course/watch/record";
    } else if (videoType === "public") {
      url = "/shop/course/watch/record";
    } else if (videoType === "topic") {
      url = "/shop/update/project/watch/record2";
      param["topicId"] = topicId;
    } else if (type === 2) {
      url = "/shop/update/project/watch/record2";
      param["activityId"] = activityId;
    } else {
      url = "/shop/update/project/watch/record2";
    } //刷新视频进度
    const { data, code } = await post(url, param, false);
    if (data === "notCurrentWatchVideo" || code === 100005) {
      // 如果是多开的情况，强制暂停视频
      this.preventOpenMoreVideoFn(video);
      if (code === 100005) {
        window.location.reload();
      }
    }
  }
  // 防多开
  async preventOpenMoreVideoFn(video: any) {
    video.pause();
  }
  // 初始化视频功能
  initVideo() {
    window.pauseTimer = null;
    window.onCCH5PlayerLoaded = () => {
      window.ccH5PlayerJsLoaded = true;
    };
    window.on_player_volumechange = (volume: any, vid: any) => {
      console.log("设置音量回调:  ", volume, vid);
    };
    /**CC视频开始播放
     * @param {video}视频信息
     * @param {vid} vid
     * @return {void}
     */
    window.on_CCH5player_play = async (video: any, vid: any) => {
      let { videoStatus } = store.getState();
      // 根据防多开设置判断
      const { preventConcurrentPlayFlag, sectionId } = videoStatus;
      // 如果是非多开模式，直接播放
      if (!preventConcurrentPlayFlag) {
        console.log("----play----");
        clearInterval(window["playing"] as any);
        this.watch(1);
        // 每30秒调用一次接口
        (window["playing"] as any) = setInterval(() => {
          this.watch(2);
        }, 30000);
      } else {
        const { data } = await post(
          "/shop/user/current/watch/section/info",
          {},
          false
        );
        // 如果是多开当前视频，也可可以直接播，跳过多开限制
        if (data === 0 || data === sectionId) {
          console.log("----play----");
          clearInterval(window["playing"] as any);
          this.watch(1);
          // 每30秒调用一次接口
          (window["playing"] as any) = setInterval(() => {
            this.watch(2);
          }, 30000);
        } else {
          video.pause();
          if (!this.showConfirm) {
            setTimeout(() => {
              Modal.confirm({
                title: "提示",
                content:
                  "您已经打开了一个视频，无法打开本视频，请关闭前一个视频再播放本视频",
                okText: "确认",
                onOk: () => {
                  this.showConfirm = false;
                },
              });
            });
            this.showConfirm = true;
          }
        }
      }
    };
    /**CC视频暂停
     * @param {any}
     * @param {vid} vid
     * @return {void}
     */
    window.on_CCH5player_pause = (video: any, vid: string) => {
      console.log("----暂停----");
      this.watch(3);
      this.pause();
      // 清空30秒上传数据接口
      clearInterval(window["playing"]);
    };
    /**CC播放结束
     * @param {any}
     * @param {vid} vid
     * @return {void}
     */
    window.on_CCH5player_ended = (video: any, vid: string) => {
      console.log("----结束----");
      window.video_end();
      // 清空随机数计时器
      // this.watch(3);
      this.pause();
      // 清空30秒上传数据接口
      clearInterval(window["playing"]);
    };
    /**
     *
     * @param mode 是否全屏 0为退出全屏 1为变成全屏
     * @param vid
     */
    // window.on_player_fullscreen = async (mode: 0 | 1, vid: string) => {
    //   if (mode === 1) {
    //     const ccVideoUrl = await cCvideoControlClass.getCCVideoUrlById(vid);
    //     await cCvideoControlClass.openVideo(ccVideoUrl as string);
    //   }
    // };
  }
}
export class AliVideControlClass {
  public count: number = 0;
  public player = null as any;
  private showConfirm = false; // 判断是否在弹窗中
  // 记录视频进度
  async watch(watchEventType: number) {
    const {
      videoStatus,
      projectType: { type, activityId },
    } = store.getState();
    const {
      courseId,
      sectionId,
      projectId,
      preventOnHookFlag,
      videoType,
      topicId,
      watchStartTime = 0,
    } = videoStatus;

    if (!this.player) return;
    console.log("this.player", this.player);

    const videoDuration = parseInt(this.player.getDuration());
    let watchAt = parseInt(this.player.getCurrentTime());
    // 获取总时长
    let time = this.player.getDuration();
    let video = this.player;
    // 获取视频进度
    let position = this.player.getCurrentTime() || watchStartTime; // 获取视频进度
    // 兼容观看记录
    if (watchAt >= time) {
      watchAt = time;
    }
    // 调用随机弹窗的方法
    if (preventOnHookFlag === 2) {
      this.start(video, position, time, videoStatus);
    }
    let url = "";
    let param = {
      courseId,
      sectionId,
      projectId: projectId ? projectId : "",
      videoDuration,
      watchAt,
      topicId: "",
      watchEventType,
    };
    // 如果是看到最后，跳过一次请求
    if (watchEventType === 2 && position === video.getDuration()) {
      return;
    }
    if (watchEventType === 3) {
      // 如果是视频结束状态
      Object.assign(param, { totalWatchTime: position - watchStartTime });
    }
    if (videoType === "myself" || videoType === "ourHospital") {
      url = "/shop/institute/course/watch/record";
    } else if (videoType === "public") {
      url = "/shop/course/watch/record";
    } else if (videoType === "topic") {
      url = "/shop/update/project/watch/record2";
      param["topicId"] = topicId;
    } else if (type === 2) {
      url = "/shop/update/project/watch/record2";
      param["activityId"] = activityId;
    } else {
      url = "/shop/update/project/watch/record2";
    } //刷新视频进度
    const { data, code } = await post(url, param, false);
    if (data === "notCurrentWatchVideo" || code === 100005) {
      // 如果是多开的情况，强制暂停视频
      this.preventOpenMoreVideoFn(video);
      if (code === 100005) {
        window.location.reload();
      }
    }
  }
  // 防多开
  async preventOpenMoreVideoFn(video: any) {
    video.pause();
  }
  // 全局计时器
  start(video: any, position: any, time: any, videoStatus: any) {
    const {
      onHookTipInfo,
      videoUniqueCode,
      projectId,
      topicId,
      courseId,
      sectionId,
    } = videoStatus;
    console.log(window.aliPauseTimer, this.count);
    if (window.aliPauseTimer === null && this.count > 0) {
      // 随机数
      let restTime = time - parseInt(position);
      let randonNum = Math.floor(Math.random() * (restTime - 0) + 0);
      window.aliPauseTimer = setInterval(() => {
        console.log("多少秒之后暂停弹窗", randonNum);
        console.log("剩余弹出次数", this.count);
        if (randonNum <= 0) {
          video.pause(); // 暂停视频
          this.pause(); // 清除计时器
          setTimeout(() => {
            let popupUniqueCode = new Date().getTime() + "";
            post(
              "/shop/front/addUserWatchPopupLog",
              {
                projectId,
                topicId,
                courseId,
                sectionId,
                videoUniqueCode,
                popupUniqueCode,
                popupType: 1,
              },
              false
            );
            alert(onHookTipInfo);
            this.count--;
            post(
              "/shop/front/addUserWatchPopupLog",
              {
                projectId,
                topicId,
                courseId,
                sectionId,
                videoUniqueCode,
                popupUniqueCode,
                popupType: 2,
              },
              false
            );
          }, 800);
        } else {
          randonNum--;
        }
      }, 1000);
    }
  }
  // 暂停视频
  pause() {
    clearInterval(window.aliPauseTimer);
    window.aliPauseTimer = null;
  }
  initAliPlayer(player: any) {
    window.aliPauseTimer = null;
    this.player = player;
    const { videoStatus } = store.getState();
    const { progressbar_enable, rate_allow_change, randomPopUpNum } =
      videoStatus;
    // 实现进度条是否能拖拽
    // this.preventSeeking(progressbar_enable);
    // 设置是否允许倍速
    this.seetSpeed(rate_allow_change);
    // 设置随机弹出数量
    this.count = randomPopUpNum;
    /**
     * 阿里云视频准备完毕
     */
    player.on("ready", () => {
      this.initAliPlayerFullScreen(player, progressbar_enable);
    });
    /**
     * 首次播放
     */
    player.on("play", async () => {
      const { videoStatus } = store.getState();
      // 根据防多开设置判断
      const { preventConcurrentPlayFlag, sectionId } = videoStatus;
      // 更改播放状态
      if (!preventConcurrentPlayFlag) {
        console.log("首次播放");
        clearInterval(window["aliPlaying"] as any);
        this.watch(1);
        // 每30秒调用一次接口
        (window["aliPlaying"] as any) = setInterval(() => {
          this.watch(2);
        }, 30000);
      } else {
        // 判断是否有多开的情况
        const { data } = await await post(
          "/shop/user/current/watch/section/info",
          {},
          false
        );
        // 如果是当前视频多开，跳过判断
        if (data === 0 || data === sectionId) {
          console.log("首次播放");
          clearInterval(window["aliPlaying"] as any);
          this.watch(1);
          // 每30秒调用一次接口
          (window["aliPlaying"] as any) = setInterval(() => {
            this.watch(2);
          }, 30000);
        } else {
          player.pause();
          if (!this.showConfirm) {
            Modal.confirm({
              title: "提示",
              content:
                "您已经打开了一个视频，无法打开本视频，请关闭前一个视频再播放本视频",
              okText: "确认",
              onOk: () => {
                this.showConfirm = false;
              },
            });
            this.showConfirm = true;
          }
        }
      }
    });
    /**
     * 视频暂停
     */
    player.on("pause", () => {
      console.log("----暂停----");
      this.watch(3);
      this.pause();
      // 清空30秒上传数据接口
      clearInterval(window["aliPlaying"]);
    });
    /**
     * 视频播放结束
     */
    player.on("ended", () => {
      console.log("----结束----");
      // 清空随机数计时器
      this.watch(3);
      this.pause();
      // 清空30秒上传数据接口
      clearInterval(window["aliPlaying"]);
    });
  }
  preventSeeking(progressbar_enable: number) {
    if (progressbar_enable === 0) {
      // @ts-ignore
      (
        document.getElementsByClassName("prism-progress-hover") &&
        (document.getElementsByClassName(
          "prism-progress-hover"
        )[0] as HTMLElement)
      ).style["pointer-events"] = "none";
    }
  }
  seetSpeed(rate_allow_change: number) {
    if (rate_allow_change === 0) {
      // @ts-ignore
      (
        document.getElementsByClassName("prism-setting-speed")[0] as HTMLElement
      ).style["display"] = "none";
      (
        document.getElementsByClassName("prism-setting-btn")[0] as HTMLElement
      ).style["display"] = "none";
    }
  }
  initAliPlayerFullScreen(player: any, progressbar_enable: number) {
    console.log("--------------------------------");
    console.log("--------------------------------");
    // console.log(document.querySelector(".ccH5FullsBtn"));
    console.log("--------------------------------");
    console.log(document.body.clientHeight);
    // 是否为全屏状态
    let isFull = false;

    // 获取窗口宽高
    const W = document.body.clientWidth;
    const H = document.body.clientHeight;

    // 因 removeEventlistener 方法无法移除匿名函数
    // 所以删除元素节点，重新插入
    // 空div ccH5Cover
    const wrapper = document.querySelector("#aliLivePlayer") as HTMLElement;

    //进度条
    const progress: HTMLElement = document.querySelector(
      ".prism-progress"
    ) as HTMLElement;
    const newProgress: HTMLElement = document.createElement("div");
    newProgress.className = "ccH5ProgressBar";
    newProgress.innerHTML = `<div class="cch5CurrentTime" id="cch5CurrentTime">
            <p></p>
            <span></span>
            <div class="trangel"></div>
        </div>
        <div class="ccH5LoadBar">
            <div class="ccH5CurrentPro">
                <span class="ccH5DragBtn"></span>
            </div>
        </div>`;
    progress.remove();
    wrapper.appendChild(newProgress);
    let menuWidth =
      document.getElementsByClassName("video_info")[0].clientWidth;
    (document.querySelector(".ccH5ProgressBar") as HTMLElement).style["width"] =
      document.body.clientWidth - menuWidth + "px";

    // 时间阻止器：防止拖动时播放进度改变导致进度按钮回跳
    let arrestTimeUpdate = false;
    var oMedia = document.querySelector("video") as HTMLElement;
    // 播放进度发生改变触发
    oMedia.addEventListener("timeupdate", function () {
      if (arrestTimeUpdate || !window.aliPlayer) return false;
      // 获取总时长
      const duration = player.getDuration();
      // 当前进度 = 当前时长 / 总时长 * 最大宽度
      const barWidth = document.querySelector(".ccH5ProgressBar")
        ?.clientWidth as number;
      const currentPro = document.querySelector(
        ".ccH5CurrentPro"
      ) as HTMLElement;
      if (currentPro) {
        currentPro.style.width =
          (player.getCurrentTime() / duration) * barWidth + "px";
      }
    });

    // 判断：是否有拖动权限
    if (progressbar_enable === 1) {
      /**
       * 根据touch信息获取其处于进度条位置位置
       *
       * @description 点击位置算法
       * 判断是否为全屏，全屏取Y轴（因为） 否则取X轴
       * 元素距离屏幕距离(startOption) - 当前点击位置(currentPosition) = 处于进度条位置
       *
       * @param clientX touch信息
       * @returns
       */
      const getTouchPosition = (client: TouchList) => {
        const startOption = newProgress.offsetLeft;
        const currentPosition = isFull
          ? H - client[0].clientY
          : client[0].clientX;
        return currentPosition - startOption;
      };

      // 进度条拖动事件处理
      const ccH5ProgressBar = document.querySelector(
        ".ccH5ProgressBar"
      ) as HTMLElement;
      ccH5ProgressBar.addEventListener("touchend", (event: TouchEvent) => {
        event.stopPropagation();

        arrestTimeUpdate = false;

        // 进度条算法
        // barTouchPosition: 点击对应进度条位置
        // totalWidth：进度条总宽度；videoTime: 视频总时长
        // jump = 跳转位置 = 点击位置 / 总宽度 * 总时长
        const barTouchPosition = getTouchPosition(event.changedTouches);
        const totalWidth = newProgress.clientWidth;
        const videoTime = player.getDuration();

        const jump = Math.floor((barTouchPosition / totalWidth) * videoTime);
        player.seek(jump);
      });

      const ccH5DragBtn = document.querySelector(".ccH5DragBtn") as HTMLElement;
      ccH5DragBtn.addEventListener("touchstart", () => {
        arrestTimeUpdate = true;
        ccH5DragBtn.ontouchmove = (event: TouchEvent) => {
          const barTouchPosition = getTouchPosition(event.changedTouches);
          const currentPro = document.querySelector(
            ".ccH5CurrentPro"
          ) as HTMLElement;
          const position =
            barTouchPosition > newProgress.clientWidth
              ? newProgress.clientWidth
              : barTouchPosition;
          currentPro.style.width = position + "px";
        };
      });
    }
  }
}
export let CCvideoControlInstance = new CCvideoControlClass();
export let AlivideoControlInstance = new AliVideControlClass();
