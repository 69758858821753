/*
 * @Author: cc
 * @LastEditTime: 2022-03-29 10:22:03
 */
import { createStore, applyMiddleware } from "redux";
import { combineReducers } from "redux";
import { navOptions } from "./options";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { videoStatus, userInfo } from "./video";
import { organInfo } from "./user";
import { projectType } from "./projectType";
import { activityInfo } from "./activityInfo";
import {projectInfo} from "./projectInfo"
// redux 长存储
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
const logger = createLogger();
const storageConfig = {
  key: "root", // 必须有的
  storage, // 缓存机制
};
const reducers = combineReducers({
  navOptions,
  videoStatus,
  userInfo,
  organInfo,
  projectType,
  activityInfo,
  projectInfo
});
const myPersistReducer = persistReducer(storageConfig, reducers);
const store = createStore(
  myPersistReducer,
  applyMiddleware(thunkMiddleware, logger)
);
export const persistor = persistStore(store);
export default store;
