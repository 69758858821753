/*
 * @Author: cc
 * @LastEditTime: 2024-04-18 13:18:59
 */
import { useEffect, useState } from "react";
import { post } from "../../../common/fetch";
import {
  CourseListProps,
  PublicListProps,
  StudyActionListProps,
} from "../../tarin/train";
import { Pagination, Menu, Table, Space, Tooltip, Modal, Button } from "antd";
import { HistoryPush } from "../../../common/utils";
export default function MyCourse() {
  let history = HistoryPush();
  const studyListColumn = [
    {
      title: "序号",
      dataIndex: "key",
      key: "key",
      width: "60px",
    },
    {
      title: "直播时间",
      dataIndex: "leaveTimeLastStr",
      key: "leaveTimeLastStr",
      width: "240px",
    },
    {
      title: "课程名称",
      dataIndex: "courseName",
      key: "courseName",
      width: "240px",
    },
    {
      title: "学习时长",
      dataIndex: "watchTimeSumStr",
      key: "watchTimeSumStr",
      width: "240px",
    },
  ];

  const courseListColumn = [
    {
      title: "序号",
      dataIndex: "key",
      key: "key",
      width: "60px",
    },
    {
      title: "课程名称",
      dataIndex: "courseName",
      key: "courseName",
      width: "240px",
      render: (text: string) => (
        <Tooltip title={text}>{text.substring(0, 25)}...</Tooltip>
      ),
    },
    {
      title: "视频名称",
      dataIndex: "sectionName",
      key: "sectionName",
      width: "240px",
      render: (text: string) => (
        <Tooltip title={text}>{text.substring(0, 25)}...</Tooltip>
      ),
    },
    {
      title: "观看时间",
      dataIndex: "updateDateText",
      key: "updateDateText",
      width: "200px",
    },
    {
      title: "操作",
      key: "action",
      width: "200px",
      render: (text: string, record: CourseListProps & PublicListProps) => (
        <Space size="middle">
          <a
            style={{ color: "#1890ff" }}
            onClick={() => {
              keepWatch(record);
            }}
          >
            继续观看
          </a>
          {record.hasExamRecordFlag !== 0 && (
            <a
              style={{ color: "#1890ff" }}
              onClick={() => {
                queryExamInfo(record.courseId);
              }}
            >
              查看成绩
            </a>
          )}
        </Space>
      ),
    },
  ];

  const [current, setCurrent] = useState("study");
  const [totalSize, setTotalSize] = useState(1);
  const [myCourseList, setMyCourseList] = useState<CourseListProps[]>([]);
  const [studyActionList, setStudyActionList] = useState<
    StudyActionListProps[]
  >([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [examInfoList, setExamInfoList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [publicCourseList, setPublicCourseList] = useState<PublicListProps[]>(
    []
  );
  // 继续观看
  const keepWatch = (record: CourseListProps & PublicListProps) => {
    history.push(
      `/play?&courseId=${record.courseId}&sectionId=${record.sectionId}&type=${record.type}`
    );
  };
  // 查询成绩详情
  const queryExamInfo = (courseId: number) => {
    post(`/shop/front/user/${courseId}/courseExamInfo`, {}, true).then(
      (res) => {
        setExamInfoList(res.data);
        setIsModalVisible(true);
      }
    );
  };
  // 公益课程
  const getPublicCourseList = (page: number) => {
    post(
      `/shop/front/user/getMyselfCourseWatchRecord`,
      {
        currentPage: page,
        pageSize: 12,
      },
      true
    ).then((res) => {
      const { courseWatchRecordList, page } = res.data;
      const list = courseWatchRecordList.map(
        (item: CourseListProps, index: number) => {
          return {
            key: (page.currentPage - 1) * page.pageSize + (index + 1),
            ...item,
            type: "public",
          };
        }
      );
      const { totalResultSize } = page;
      setTotalSize(totalResultSize);
      setPublicCourseList(list.filter((item: any) => item.courseStatus === 0));
    });
  };
  //获取直播记录
  const getStudyActionList = (page: number) => {
    post(
      `/shop/front/getLiveStudyActionList`,
      {
        currentPage: page,
        pageSize: 12,
      },
      true
    ).then((res) => {
      if (!res.data) return;
      const { list, page } = res.data;
      const currentlist = list.map((item: CourseListProps, index: number) => {
        return {
          key: (page.currentPage - 1) * page.pageSize + (index + 1),
          ...item,
          type: "public",
        };
      });
      const { totalResultSize } = page;
      setTotalSize(totalResultSize);
      setStudyActionList(currentlist);
    });
  };
  const getMyCourseList = (page: number) => {
    post(
      `/shop/front/user/getUserInstituteCourseWatchRecord`,
      {
        currentPage: page,
        pageSize: 12,
      },
      true
    ).then((res) => {
      const { courseWatchRecordList, page } = res.data;
      const { totalResultSize } = page;
      const list = courseWatchRecordList.map(
        (item: CourseListProps, index: number) => {
          return {
            key: (page.currentPage - 1) * page.pageSize + (index + 1),
            ...item,
            type: "myself",
          };
        }
      );
      setTotalSize(totalResultSize);
      setMyCourseList(list.filter((item: any) => item.courseStatus === 0));
    });
  };
  // 获取点播课程
  useEffect(() => {
    getStudyActionList(1);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const getExamNote = (userPaperScoreId: number) => {
    const { REACT_APP_BASE_URL, REACT_APP_EXAM_URL } = process.env;
    const examResultUrl = `${REACT_APP_BASE_URL}${REACT_APP_EXAM_URL}/record-list?userPaperScoreId=${userPaperScoreId}&type=courseExam`;
    window.location.href = examResultUrl;
  };
  return (
    <div className="class-content">
      <Menu
        onClick={(e) => {
          setCurrentPage(1);
          setCurrent(e.key as string);
          if (e.key === "study") {
            getStudyActionList(1);
          } else if (e.key === "myself") {
            getMyCourseList(1);
          } else {
            getPublicCourseList(1);
          }
        }}
        selectedKeys={[current]}
        mode="horizontal"
      >
        <Menu.Item key="study">直播课程</Menu.Item>
        <Menu.Item key="myself">点播课程</Menu.Item>
        {/* <Menu.Item key="public">平台课程</Menu.Item> */}
      </Menu>
      {current === "study" && (
        <Table
          dataSource={studyActionList}
          columns={studyListColumn}
          pagination={false}
        ></Table>
      )}
      {current === "myself" && (
        <Table
          dataSource={myCourseList}
          columns={courseListColumn}
          pagination={false}
        ></Table>
      )}
      {/* {current === "public" && (
        <Table
          dataSource={publicCourseList}
          columns={courseListColumn}
          pagination={false}
        ></Table>
      )} */}
      <div className="pagination-box">
        <Pagination
          total={totalSize}
          hideOnSinglePage={true}
          current={currentPage}
          showSizeChanger={false}
          pageSize={12}
          onChange={(page) => {
            setCurrentPage(page);
            current === "study"
              ? getStudyActionList(page)
              : current === "myself"
              ? getMyCourseList(page)
              : getPublicCourseList(page);
          }}
        />
      </div>
      <Modal
        title="查看成绩"
        closable={true}
        footer={null}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width="800px"
      >
        {examInfoList.map((it: any, index: number) => (
          <p
            key={index}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>试卷名称：{it.examPaperName}</span>
            <span>交卷时间：{it.finishTime}</span>
            <span>成绩：{it.score}</span>
            {it.examSystemType === 2 && (
              <Button
                onClick={() => {
                  getExamNote(it.id);
                }}
                type="primary"
              >
                记录
              </Button>
            )}
          </p>
        ))}
      </Modal>
    </div>
  );
}
