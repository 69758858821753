/*
 * @Author: cc
 * @LastEditTime: 2022-06-15 11:35:10
 */
import { useState, useEffect,createRef } from "react";
import { Form, Input,Button,Table } from "antd";
import { post } from "src/common/fetch";
import { useQuery } from "../../common/utils";
import { FormInstance } from "antd/lib/form";
import { useHistory } from "react-router-dom";
let projectId: string;
function CertificateRecords() {
    const query = useQuery();
    const [dataSource,setDataSource] = useState([{name:'张三'}])
    projectId = query.get("projectId") as string;
    const form = createRef<FormInstance>();
    const [currentPage,setCurrentPage] = useState(1)
    const history = useHistory();
    const [pageSize] = useState(10)
    const [total,setTotal] = useState(1)
    const [columns] = useState([
        {
            title: '姓名',
            dataIndex: 'maskName',
            key: 'maskName',
          },
          {
            title: '手机',
            dataIndex: 'maskMobile',
            key: 'maskMobile',
          },
          {
            title: '培训证书名称',
            dataIndex: 'trainCertificateName',
            key: 'trainCertificateName',
          },
          {
            title: '培训证书编号',
            dataIndex: 'trainCertificateNumber',
            key: 'trainCertificateNumber',
          },
          {
            title: '操作',
            dataIndex: 'action',
            render:(_:any,record:any)=>{
                return <a style={{color:'#1890ff'}} onClick={()=>download(record)}>证书下载</a>
            }
          },
    ])
    function getList(currentPage:number){
        post('/shop/api/project/certificate/list',{projectId,page:{currentPage,pageSize}},true).then(res=>{
           setTotal(res.data.page.totalResultSize)
           setDataSource(res.data.userTrainCertificateInfoList)
        })
    }
    function download(record:any){
        post(`/shop/api/project/${projectId}/certificate/${record.id}/download`,{},true).then(res=>{
            var a = document.createElement("a"); // 生成一个a元素
            var event = new MouseEvent("click"); // 创建一个单击事件
            a.download = res.data || "photo"; // 设置图片名称
            a.href = res.data; // 将生成的URL设置为a.href属性
            a.dispatchEvent(event); // 触发a的单击事件
        })
    }
    function changePage(current:number){
        setCurrentPage(current)
        getList(current)
     }
     function filter(){
        const data = form.current?.getFieldsValue()
        Object.keys(data).forEach(item=>{
            if(!data[item]){
                delete data[item]
            }
        })
        post('/shop/api/project/certificate/list',{projectId,page:{currentPage:currentPage,pageSize},...data},true).then(res=>{
            setTotal(res.data.page.totalResultSize)
            setDataSource(res.data.userTrainCertificateInfoList)
         })
     }
     function reset(){
        form.current?.resetFields()
        getList(1)
     }
    useEffect(()=>{
        getList(currentPage)
    },[])// eslint-disable-line

    return <div className="data_query">
        <Form
            style={{padding:"20px"}}
            layout="inline"
            ref={form}
        >
            <Form.Item
                label="姓名"
                name="name"
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="手机号"
                name="mobile"
            >
                <Input />
            </Form.Item>
            <Form.Item
            >
                <Button type="primary" onClick={filter}>查询</Button>
                <Button style={{marginLeft:"20px"}} onClick={reset}>重置</Button>
            </Form.Item>
            <Form.Item style={{flex:1}}
            >
            <a style={{color:'#0d6efd',float:"right",fontSize:"18px"}} onClick={()=>{
                history.goBack()
            }}>返回</a>
            </Form.Item>
        </Form>
        <Table dataSource={dataSource} columns={columns} pagination={ {pageSize:10,total:total,onChange:changePage}} />
    </div>
}
export default CertificateRecords;
