/*
 * @Author: changcheng
 * @LastEditTime: 2023-12-26 11:19:04
 */
import { Modal, Radio, Form, Button } from "antd";
import wechatIcon from "../../img/weChatPay.png";
import aliIcon from "../../img/aliPay.png";
import "./index.scss";
import { post } from "../../common/fetch";
import { HistoryPush } from "../../common/utils";
import { Base64 } from "js-base64";
interface propsType {
  showOrderModal: boolean;
  setShowOrderModal: (show: boolean) => void;
  courseInfo: platFormProps;
}
export interface platFormProps {
  // 课程名称
  name: string;
  // 选修时长
  notRequiredPeriod: number;
  // 必修时长
  requiredPeriod: number;
  // 价格
  salePriceText: string;
  // 是否已购买
  buyClassFlag: number;
  // id
  id: string;
  // 数量
  amount: number;
  // 价格
  salePrice: number;
}
const UserOrderConfirm = (props: propsType) => {
  const history = HistoryPush();
  const {
    showOrderModal,
    setShowOrderModal,
    courseInfo = {
      amount: 0,
      requiredPeriod: 0,
      id: "",
      name: "",
      salePriceText: 0,
      salePrice: 0,
    },
  } = props;
  const { amount, requiredPeriod, salePriceText } = courseInfo;
  console.log("courseInfo", courseInfo);

  /**
   * 支付订单
   * @param values 表单字段
   */
  const confirmOrderInfo = async (values: any) => {
    const { paymentType } = values;
    if (paymentType === "wechat") {
      wechatPay(values);
    } else {
      aliPay();
    }
  };
  /**
   * 微信支付
   * @param values
   */
  const wechatPay = async (values: any) => {
    const { id, name, amount } = courseInfo;
    let price = process.env.REACT_APP_NODE_ENV === 'production' ? amount : 1
    const {
      data: { weChatPayQrCode, tradeNo },
    } = await post(
      "/shop/front/cm/pc/class/create/weChat/pay/order",
      {
        classId: id,
        trainClassName: name,
        trainClassAmount: price,
      },
      true
    );
    setShowOrderModal(false);
    history.push(
      `/wechatPay?amount=${price}&payCode=${Base64.encode(
        weChatPayQrCode
      )}&tradeNo=${tradeNo}`
    );
  };
  /**
   * 阿里支付
   * @param values
   */
  const aliPay = async () => {
    const { id, name, amount } = courseInfo;
    let price = process.env.REACT_APP_NODE_ENV === 'production' ? amount : 1
    const {
      data: { orderNo },
    } = await post(
      "/shop/front/cm/pc/class/create/ali/pay/order",
      {
        classId: id,
        trainClassName: name,
        trainClassAmount: price,
      },
      false
    );
    const returnUrl = encodeURIComponent(`${window.location.href}`);
    window.location.href = `https://api.imed.org.cn/order/createAliPayOrder?payType=ALI_PAY&tradeNo=${orderNo}&returnUrl=${returnUrl}&tradeType=NATIVE`;
  };
  return (
    <Modal
      title="确认订单"
      visible={showOrderModal}
      footer={null}
      onCancel={() => {
        setShowOrderModal(false);
      }}
    >
      <p>中医药专业技术人员综合服务能力提升培训</p>
      <p>必修达标学时：{requiredPeriod}学时</p>
      <p>订单金额：{salePriceText}元</p>
      <Form
        onFinish={confirmOrderInfo}
        initialValues={{
          paymentType: "wechat",
        }}
      >
        <Form.Item name="paymentType">
          <Radio.Group>
            <Radio value="wechat">
              <img src={wechatIcon} alt="" className="payIcon" />
            </Radio>
            <Radio value="ali">
              <img src={aliIcon} alt="" className="payIcon" />
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            支付
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default UserOrderConfirm;
