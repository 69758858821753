import { ProjectList } from "../../components/index";
import { post } from "../../common/fetch";
import { useEffect, useState, useRef } from "react";
import { TrainListProps } from "../tarin/train"
import { Breadcrumb, Input, message, Pagination, Button } from "antd";
import './search.scss'

interface IKeywordProp {
    keyword: string
}

const { Search } = Input;
const SearchComponent: React.FC = (props: any) => {
    const [trainList, setTrainList] = useState<TrainListProps[]>([]);
    const [searchText, setSearchText] = useState('');
    const [totalSize, setTotalSize] = useState(1);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [keywordList, setKeyWordList] = useState<IKeywordProp []>([]);
    const [keywordVis, setKeywordVis] = useState(false);
    const keywordInput = useRef<any>();

    const onSearchHandle = (values: string, currentPage:number = 1) => {
        if (!values) {
            message.info('请输入内容');
            return false;
        }

        post("/shop/front/saveCourseSearchKeywordRecord", {keyword: values}, false);
        setKeyWordList([...keywordList, {keyword: values}]);
        setKeywordVis(false);

        if (values) {
            setLoading(true);
            post('/shop/front/course/search', {
                courseName: values, 
                page: { 
                    currentPage: currentPage,
                    pageSize: 8
                }
            }, false)
            .then(res => {
                if (res.code === 0) {
                    const { data: { myCourseList, page } } = res;
                    setTrainList(myCourseList);
                    setPage(page.currentPage)
                    setTotalSize(page.totalResultSize)
                }
            })
            .finally(() => {
                setLoading(false)
            })

            setSearchText(values);
        } else {
            message.info('请输入关键词')
        }        
    }

    const keywordClick = (str: string) => {
        setKeyword(str);
        keywordInput.current!.focus({
            cursor: 'all',
        });
        onSearchHandle(str)
    }

    const clearClick = () => {
        post("/shop/front/clearUserCourseSearchKeyword", undefined, false);
        setKeywordVis(false);
        setKeyWordList([]);
    }

    useEffect(() => {
        post('/shop/front/getCourseSearchKeywordList', undefined, false)
            .then(res => {
                if (res.code === 0) {
                    setKeyWordList(res.data)
                }
            })
    }, [])

    return (
        <div className="search_container">
            <div className="search_breadcrumb">
                <Breadcrumb
                    separator="/"
                    style={{ fontSize: "20px", marginTop: "20px" }}
                >
                    <Breadcrumb.Item href="/#/classList?type=ourHospital">本院资源</Breadcrumb.Item>
                    <Breadcrumb.Item>搜索</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="search_option">
                <Search 
                    placeholder="输入内容" 
                    enterButton="搜索" 
                    size="large" 
                    loading={loading} 
                    value={keyword}
                    ref={keywordInput}
                    onSearch={(values: string) => {onSearchHandle(values)}}
                    onFocus={() => { 
                        if (keywordList.length) {
                            setKeywordVis(true)
                        }
                    }}
                    onBlur={() => {
                        if (keywordVis) {
                            setTimeout(() => { setKeywordVis(false) }, 500) 
                        }
                    }}
                    onChange={(e) => { setKeyword(e.target.value) }}
                />
                {
                    keywordVis && 
                    <div className="search_history">
                        <ul>
                            {
                                keywordList.map((keyword: IKeywordProp, index: number) => {
                                    return (
                                        <li 
                                            key={index}
                                            className="search_history_item"
                                            onClick={() => { keywordClick(keyword.keyword) }}
                                        > {keyword.keyword} </li>
                                    )
                                })
                            }
                        </ul>
                        <div className="search_history_clear">
                            <Button type="link" onClick={clearClick}>清除记录</Button>
                        </div>
                    </div>
                }
                
            </div>
            <div className="search_container">
                {
                    totalSize === 0 ?
                    <p>搜索无结果</p>
                    :
                    <>
                        <ProjectList classList={trainList} type={"ourHospital"} />
                        <div className="search_pagination">
                            <Pagination
                                total={totalSize}
                                current={page}
                                defaultPageSize={8}
                                hideOnSinglePage={true}
                                showSizeChanger={false}
                                onChange={(page) => {
                                    onSearchHandle(searchText, page)
                                }}
                            />
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default SearchComponent