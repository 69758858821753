/* eslint-disable react-hooks/exhaustive-deps */
/*
 * @Author: cc
 * @LastEditTime: 2023-08-22 13:45:41
 */
import { useEffect, useState } from "react";
import { useQuery } from "../../common/utils";
import { post, get } from "../../common/fetch";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  TrainListProps,
  CourseListProps,
  PublicListProps,
  LiveLisrProps,
} from "../tarin/train";
import { ProjectList } from "../../components/index";
import { Pagination, Breadcrumb, BackTop, Radio } from "antd";
import { VerticalAlignTopOutlined, SearchOutlined } from "@ant-design/icons";
import Segmenteds from "../../components/Segmenteds/index";
import "./classList.scss";
export interface classifty {
  name: String;
  id: number;
  childSubjectList: Array<{ name: String }>;
}
export interface secondClassifty {
  [x: string]: any;
  name: String;
}

function ClassList(props: any) {
  const history = useHistory();
  const query = useQuery();
  const type = query.get("type");
  const [page, setPage] = useState(1);
  const [totalSize, setTotalSize] = useState(1);
  const [firstClassifyList, setFirstClassift] = useState<classifty[]>([]);
  const [dataList, setDateList] = useState(["全部", "今天", "明天"]);
  const [secondClassifyList, setSecondClassifyList] = useState<
    secondClassifty[]
  >([]);
  const [isInitData, setIsInitData] = useState(0);
  const [currentFirstSelect, setFirstSelect] = useState(0);
  const [currentSecondSelect, setSecondSelect] = useState(0);
  const [currentDateSelect, setDateSelect] = useState(0);
  const [trainList, setTrainList] = useState<TrainListProps[]>([]);
  const [myLiveList, setMyLiveList] = useState<LiveLisrProps[]>([]);
  const [myCourseList, setMyCourseList] = useState<CourseListProps[]>([]);
  const [publicCourseList, setPublicCourseList] = useState<PublicListProps[]>(
    []
  );
  const defaultCourseType = [
    {
      title: "全部",
      key: "",
      show: true,
    },
    { title: "理论知识", key: 1, show: true },
    { title: "手术操作", key: 2, show: true },
  ];
  // 本院资源分类树
  const pickerBase = {
    title: "全部",
    key: 1,
    show: true,
  };
  const myselfClass: any[] = [
    { title: "本院课程", key: 2, show: true, children: [] },
    { title: "授权课程", key: 3, show: true, children: [] },
  ];
  const [pickerData, setPickerData] = useState(myselfClass);
  const [pickerSelected, setPickerSelected] = useState<number[]>([1]);
  const [pickerOptions, setPickerOptions] = useState([myselfClass]);
  const [courseKind, setCourseKind] = useState("");
  const [courseType, setCourseType] = useState<any[]>([]);
  const [currentZeroSelect, setCurrentZeroSelect] = useState<string>('直播课程')
  const { activityInfo } = props;
  // 我的培训
  const getMyProjectList = () => {
    post(`/shop/front/getMyProjectList`, {}, true).then((res) => {
      const { data } = res;
      const projectIdArr = [484, 485, 486, 419, 420];
      let handleArr = data.filter(
        (item: any) => !projectIdArr.includes(item.projectId)
      );
      setTrainList(handleArr);
    });
  };
  // 公益课程
  const getPublicCourseList = (page: number) => {
    post(
      `/shop/front/getPublicCourseList`,
      {
        currentPage: page,
        pageSize: 8,
      },
      true
    ).then((res) => {
      const { publicCourseList, page } = res.data;
      const { totalResultSize, currentPage } = page;
      setPage(currentPage);
      setTotalSize(totalResultSize);
      setPublicCourseList(publicCourseList);
    });
  };
  // 直播课程
  const getMyLiveist = (page: number) => {
    console.log(secondClassifyList[currentSecondSelect] ,firstClassifyList,currentFirstSelect, firstClassifyList[currentFirstSelect].id)
    post(
      `/shop/front/getPcLiveCourseList`,
      {
        currentPage: page,
        pageSize: 8,
        subjectId: secondClassifyList[currentSecondSelect]?.id || firstClassifyList[currentFirstSelect].id,
        queryLiveStartTimeStr: dataList[currentDateSelect],
      },
      true
    ).then((res) => {
      // const { page } = res.data;
      // const { totalResultSize, currentPage } = page;
      if (res.data) {
        setPage(res.data.page.currentPage);
        setTotalSize(res.data.page.totalResultSize);
      }
      if (res.data) {
        setMyLiveList(res.data.list);
      } else {
        setMyLiveList([]);
      }
    });
  };
  // 本院资源
  const getMyCourseList = (pages: number, picker?: number[]) => {
    let subject: number | undefined;
    if (picker && picker.length > 1) {
      if (picker.length !== 2 || picker[1] !== 1) {
        subject =
          picker[picker.length - 1] === 1
            ? picker[picker.length - 2]
            : picker[picker.length - 1];
      }
    }
    let param = {
      subjectId: subject,
      courseSearchType: picker !== undefined ? picker[0] : 1,
      page: {
        currentPage: pages,
        pageSize: 8,
      },
    };
    if (courseKind) param["courseKind"] = courseKind;
    post("/shop/front/course/query", param, false).then((res) => {
      const { myCourseList, page } = res.data;
      const { totalResultSize, currentPage } = page;
      setPage(currentPage);
      setTotalSize(totalResultSize);
      setMyCourseList(myCourseList);
    });

    // 未加分类使用接口：获取全部本院资源
    // post(
    //   `/shop/front/getMyCourseList`,
    //   {
    //     currentPage: page,
    //     pageSize: 8,
    //   },
    //   true
    // ).then((res) => {
    //   const { myCourseList, page } = res.data;
    //   const { totalResultSize, currentPage } = page;
    //   setPage(currentPage);
    //   setTotalSize(totalResultSize);
    //   setMyCourseList(myCourseList);
    // });
  };
  const getCourseInfo = async () => {
    const {
      data: { instituteCourseCount },
    } = await post(`/shop/front/institute/course/info`, {}, true);
    if (instituteCourseCount === 0) {
      myselfClass[0].show = false;
      setPickerOptions([[...myselfClass]]);
    }
  };
  useEffect(() => {
    getCourseInfo();
  }, []);
  // 获取本院资源
  useEffect(() => {
    if (type === "ourHospital") {
      getMyCourseList(1);
      // 设置分类树内容
      post("/shop/front/local/course/subject/list", {}, true).then((res) => {
        if (res.data.code === 0) {
          setPickerData((prevCount) => {
            let newCount = [...prevCount];
            newCount[0].children = res.data.data;
            return prevCount;
          });
        }
      });
      post(
        "/shop/front/authorized/course/subject/list",
        { courseKind },
        true
      ).then((res) => {
        if (res.data.code === 0) {
          setPickerData((prevCount) => {
            let newCount = [...prevCount];
            newCount[1].children = res.data.data;
            return prevCount;
          });
        }
      });
    } else if (type === "public") {
      getPublicCourseList(1);
    } else if (type === "live") {
      getLiveInitData();
    } else if (type === "activity") {
    } else {
      getMyProjectList();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (type === "ourHospital") {
      getMyCourseList(page, pickerSelected);
    }
  }, [courseKind]);
  //调用直播页面初始数据
  const getLiveInitData = async () => {
    await getTypeList();
    await getPcLiveCourseDateList();
  };
  useEffect(() => {
    if (isInitData === 2) {
      getMyLiveist(1);
    }
  }, [isInitData]);
  //获取直播课程分类菜单
  const getTypeList = () => {
    post("/shop/front/getLiveCourseSubjectTreeList", {}, true).then((res) => {
      if(res.data?.subjectList){
        let newData = [
          { id: null, name: "全部", childSubjectList: [] },
          ...res.data.subjectList,
        ];
        newData.forEach((item) => {
          if (item.childSubjectList.length) {
            item.childSubjectList.unshift({ id: null, name: "全部" });
          }
        });
        setFirstClassift(newData);
        setSecondClassifyList(newData[0].childSubjectList);
        setIsInitData((prevState) => prevState + 1);
      }
    });
  };
  //获取直播课程时间列表
  const getPcLiveCourseDateList = () => {
    get("/shop/front/getPcLiveCourseDateList", {}, true).then((res) => {
      if (res.data) {
        setDateList(res.data);
        setIsInitData((prevState) => prevState + 1);
      }
    });
  };
  const firstClassClick = (index: number) => {
    setFirstSelect(index);
    setSecondSelect(0);
    setSecondClassifyList(firstClassifyList[index].childSubjectList);
  };
  useEffect(() => {
    if (isInitData === 2) {
      if(currentZeroSelect === '直播课程'){
        console.log('111111111111')
        getMyLiveist(1);
      }else{
        console.log('222222222222')
        getPlayBackLive(1)
      }
    }
  }, [currentFirstSelect, currentSecondSelect, currentDateSelect]);
  const secondClassClick = (index: number) => {
    setSecondSelect(index);
  };

  const onPickerChangeHandle = (checkted: number, level: number) => {
    let newPickerSelected = [...pickerSelected];

    newPickerSelected = newPickerSelected.splice(0, level + 1);
    newPickerSelected[level] = checkted;

    // 判断：分类只展示三层
    if (level !== 4 && checkted !== 1) {
      newPickerSelected[level + 1] = 1;
    }

    setPickerSelected(newPickerSelected);
    setPickerOptions(() => {
      const newCount = [];
      let choose = pickerData;
      let n = 0;

      while (n < newPickerSelected.length) {
        newCount.push(choose);
        choose = // eslint-disable-next-line no-loop-func
          choose?.find((data) => {
            return data.key === newPickerSelected[n];
          })?.children || [];
        n++;
      }

      return newCount;
    });

    getMyCourseList(1, newPickerSelected);
  };
  const getPlayBackLive = (page: number) => {
    post(
      `/shop/front/getPcLiveCoursePlayBackList`,
      {
        currentPage: page,
        pageSize: 8,
        subjectId:secondClassifyList[currentSecondSelect]?.id || firstClassifyList[currentFirstSelect].id,
      },
      true
    ).then((res) => {
      if (res.data) {
        setPage(res.data.page.currentPage);
        setTotalSize(res.data.page.totalResultSize);
      }
      if (res.data) {
        setMyLiveList(res.data.list);
      } else {
        setMyLiveList([]);
      }
    });
  }
  return (
    <div className="classList-content">
      <div style={{ display: "flex", alignItems: "center" }}>
        <Breadcrumb
          separator="/"
          style={{ fontSize: "20px", marginTop: "20px" }}
        >
          <Breadcrumb.Item href="/#/train">首页</Breadcrumb.Item>
          <Breadcrumb.Item>
            {type === "train" && "我的培训"}
            {type === "ourHospital" && "本院资源"}
            {type === "public" && "平台课程"}
            {type === "live" && "直播课程"}
            {type === "activity" && "活动中心"}
          </Breadcrumb.Item>
        </Breadcrumb>
        {type === "ourHospital" && (
          <div
            className="myselfClass_search"
            onClick={() => {
              history.push("/search");
            }}
          >
            <SearchOutlined />
          </div>
        )}
      </div>
      {/* 类型为本院资源时，页面筛选条件 */}
      {type === "ourHospital" && (
        <div className="myselfClass">
          <div className="myselfClass_lable">分&emsp;&emsp;类：</div>
          <div className="myselfClass_container">
            {pickerOptions.map((item, index) => {
              return (
                <Segmenteds
                  key={index}
                  datasourse={[pickerBase, ...item]}
                  onChange={(id: number) => {
                    setCourseKind("");
                    onPickerChangeHandle(id, index);
                    if (id === 1 || id === 2) {
                      setCourseType([]);
                    } else if (id === 3) {
                      setCourseType(defaultCourseType);
                    }
                  }}
                />
              );
            })}
          </div>
        </div>
      )}
      {courseType.length > 0 && (
        <div className="myselfClass">
          <div className="myselfClass_lable">课程种类：</div>
          <div className="myselfClass_container">
            <Segmenteds
              datasourse={[...courseType]}
              onChange={(id: string) => {
                setCourseKind(id);
              }}
            />
          </div>
        </div>
      )}
      {type === "live" && (
        <div>
          <div className="liveContainerSelect">
            <div className="left">分类：</div>
            <div className="right">
              <div className="firstClassify">
                <span className={currentZeroSelect === '直播课程' ? 'select_span' : ''} onClick={() => {
                  setCurrentZeroSelect('直播课程')
                  setFirstSelect(0)
                  setSecondSelect(0)
                 currentFirstSelect===0 && currentSecondSelect ===0 && getMyLiveist(1)
                }}>直播课程</span>
                <span className={currentZeroSelect === '直播回放' ? 'select_span' : ''} onClick={() => {
                  setCurrentZeroSelect('直播回放')
                  setFirstSelect(0)
                  setSecondSelect(0)
                  currentFirstSelect===0 && currentSecondSelect ===0 && getPlayBackLive(1)
                }}>直播回放</span>
              </div>
              <div className="secondClassify">
                {firstClassifyList &&
                  firstClassifyList.map((item, index) => {
                    return (
                      <span
                        key={index}
                        className={
                          currentFirstSelect === index ? "select_span" : ""
                        }
                        onClick={() => {
                          firstClassClick(index);
                        }}
                      >
                        {item.name}
                      </span>
                    );
                  })}
              </div>
              {secondClassifyList.length > 0 && (
                <div className="secondClassify">
                  {secondClassifyList &&
                    secondClassifyList.map((item, index) => {
                      return (
                        <span
                          key={index}
                          className={
                            currentSecondSelect === index ? "select_span" : ""
                          }
                          onClick={() => secondClassClick(index)}
                        >
                          {item.name}
                        </span>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
          {currentZeroSelect === '直播课程' && <div
            className="liveContainerSelect"
            style={{ border: "none", marginTop: "0", padding: "0 10px" }}
          >
            <div className="left">日期：</div>
            <div className="right">
              {dataList &&
                dataList.map((item, index) => {
                  return (
                    <span
                      key={index}
                      className={
                        currentDateSelect === index ? "select_span" : ""
                      }
                      onClick={() => {
                        setDateSelect(index);
                      }}
                    >
                      {item}
                    </span>
                  );
                })}
            </div>
          </div>}
        </div>
      )}
      {type === "train" && <ProjectList classList={trainList} type={"train"} />}
      {type === "ourHospital" && (
        <ProjectList classList={myCourseList} type={"ourHospital"} />
      )}
      {type === "public" && (
        <ProjectList classList={publicCourseList} type={"public"} />
      )}
      {type === "live" && <ProjectList classList={myLiveList} type={"live"} />}
      {type === "activity" && (
        <ProjectList classList={activityInfo} type={"activity"} />
      )}
      <div className="pagination-box">
        <Pagination
          total={totalSize}
          current={page}
          defaultPageSize={8}
          showSizeChanger={false}
          onChange={(page) => {
            if (type === "ourHospital") {
              getMyCourseList(page, pickerSelected);
            } else if (type === "public") {
              getPublicCourseList(page);
            } else if (type === "live") {
              if(currentZeroSelect === '直播课程'){
                getMyLiveist(page);
              }else{
                getPlayBackLive(page)
              }
            }
          }}
        />
      </div>
      <BackTop visibilityHeight={100}>
        <div>
          <VerticalAlignTopOutlined style={{ fontSize: "24px" }} />
        </div>
      </BackTop>
    </div>
  );
}
const getActivityInfo = (state: { activityInfo: Array<any> }) => {
  return {
    activityInfo: state.activityInfo,
  };
};
export default connect(getActivityInfo)(ClassList);
