/*
 * @Author: cc
 * @LastEditTime: 2023-11-15 13:13:29
 */
import { useEffect, useState } from "react";
import logo from "../../img/logo.png";
import "./login.scss";
import { UserOutlined, UnlockOutlined } from "@ant-design/icons";
import { Form, Input, Button } from "antd";
import { encode } from "js-base64";
import { Link } from "react-router-dom";
import { HistoryPush } from "../../common/utils";
import store from "../../reducers/index";
import { post, get } from "../../common/fetch";
const { Search } = Input;
interface formProps {
  userName: string;
  password: string;
  randomCode: string;
}
const Login = () => {
  let history = HistoryPush();
  const [randomImgUrl, setRandomImgUrl] = useState("");
  useEffect(() => {
    getRandomCode();
  }, []);
  const getRandomCode = async () => {
    const { data } = await get(`/user/api/verify/code`, {}, false);
    setRandomImgUrl(data);
  };
  const onFinish = (values: formProps) => {
    const { userName, password, randomCode } = values;
    const verifyCode = randomCode.replace(/\s/g, "");
    post(
      `/user/api/ce/user/login`,
      {
        mobile: encode(userName),
        password: encode(password),
        verifyCode: encode(verifyCode),
      },
      false
    ).then(
      (result: any) => {
        const { data, code } = result;
        if (code === -1) {
          getRandomCode();
        } else {
          const { name, avatar, mobile } = data;
          store.dispatch({
            type: "SET_USER_INFO",
            name,
            avatar,
            mobile,
          });
          history.push("/train");
        }
      },
      (err) => {
        getRandomCode();
      }
    );
  };
  const onFinishFailed = () => {};
  return (
    <div className="login_box">
      <div className="business">
        <img src={logo} alt="" />
      </div>
      <div className="submit_box">
        <p
          style={{
            color: "blue",
            marginTop: "12px",
            fontSize: "18px",
          }}
        >
          登录
        </p>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="userName"
            rules={[{ required: true, message: "请输入用户名" }]}
          >
            <Input
              prefix={
                <div className="icon_box">
                  <UserOutlined />
                </div>
              }
              placeholder="用户名"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "请输入您的密码" }]}
          >
            <Input.Password
              prefix={
                <div className="icon_box">
                  <UnlockOutlined />
                </div>
              }
              placeholder="密码"
            />
          </Form.Item>
          <Form.Item
            name="randomCode"
            rules={[{ required: true, message: "请输入图形验证码" }]}
          >
            <Search
              placeholder="请输入图形验证码"
              enterButton={
                <img
                  src={randomImgUrl}
                  alt="验证码"
                  style={{ width: "100px", height: "38px" }}
                  onClick={getRandomCode}
                />
              }
              style={{ padding: "0" }}
              size="large"
            />
          </Form.Item>
          <Form.Item name="remember" valuePropName="checked">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Link to="/register">注册账户</Link>
              <Link to="/modifyPassWord">忘记密码</Link>
            </div>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              style={{ height: "40px" }}
            >
              登录
            </Button>
            <p style={{ marginTop: "20px" }}>
              提示：账号和密码在《医视在线平台》和《国家医学电子书包App》通用
            </p>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default Login;
