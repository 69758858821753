/*
 * @Author: changcheng
 * @LastEditTime: 2023-11-21 13:06:44
 */
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { useLocation } from "react-router-dom";
import { post } from "../../common/fetch";
import "./index.scss";
import { useEffect } from "react";
import { HistoryPush } from "../../common/utils";
import { Base64 } from "js-base64";
let waitPayStatus: any = null;
function WechatPay() {
  const query = new URLSearchParams(useLocation().search);
  const amount = query.get("amount");
  const payCode = Base64.decode(query.get("payCode") as string);
  const tradeNo = query.get("tradeNo");
  const history = HistoryPush();
  /**查询微信支付状态 */
  const searchWechatPayStatus = () => {
    waitPayStatus = setInterval(async () => {
      const {
        data: { code },
      } = await post(`/shop/front/ce/order/${tradeNo}/pay/status`, {}, false);
      if (code === 0) {
        clearInterval(waitPayStatus as number);
        history.go(-1);
      }
    }, 3000);
  };
  useEffect(() => {
    searchWechatPayStatus();
    return () => {
      clearInterval(waitPayStatus);
    };
  }, []);
  return (
    <div className="weChatPayModal">
      <p
        onClick={() => {
          history.go(-1);
        }}
        className="back"
      >
        <Space>
          <ArrowLeftOutlined />
          <span className="icon">返回</span>
        </Space>
      </p>
      <div className="content">
        <h3>微信支付</h3>
        <p>请扫描二维码付款</p>
        <p>¥&nbsp;{Number(amount) / 100}</p>
        <img src={payCode} alt="" width={"300px"} />
      </div>
    </div>
  );
}
export default WechatPay;
