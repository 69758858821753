/*
 * @Author: changcheng
 * @LastEditTime: 2023-10-20 17:11:32
 */
import { Modal, Form, Space, Input, Button, message } from "antd";
import { post } from "../../../../common/fetch";
export function InvoinceModal(props: any) {
  const { showInvoinceModal, setShowInvoinceModal, orderInfo, getList } = props;
  const { orderAmount } = orderInfo;
  const onFinish = async (values: any) => {
    const { receiptEmail, receiptMobile, taxPayerId, unitName } = values;
    const { tradeNo } = orderInfo;
    const { msg } = await post(
      `/shop/front/ce/user/apply/receipt`,
      {
        receiptEmail,
        receiptMobile,
        taxPayerId,
        tradeNo,
        unitName,
      },
      false
    );
    message.info(msg);
    setShowInvoinceModal(false);
    getList();
  };
  return (
    <div>
      <Modal
        visible={showInvoinceModal}
        title="申请发票"
        onOk={() => {
          setShowInvoinceModal(false);
        }}
        onCancel={() => {
          setShowInvoinceModal(false);
        }}
        footer={null}
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          style={{
            width: "300px",
          }}
        >
          <Form.Item label="发票类型">
            <span>电子发票</span>
          </Form.Item>
          <Form.Item
            label="单位名称"
            name="unitName"
            rules={[{ required: true, message: "请输入单位名称" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="纳税人识别号"
            name="taxPayerId"
            rules={[{ required: true, message: "请输入纳税人识别号" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="发票内容">
            <span>医视健康继续教育系统V1.0</span>
          </Form.Item>
          <Form.Item label="发票金额">
            <span>{orderAmount}¥</span>
          </Form.Item>
          <Form.Item
            label="收票人手机号"
            name="receiptMobile"
            rules={[{ required: true, message: "请输入收票人手机号" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="收票人邮箱"
            name="receiptEmail"
            rules={[{ required: true, message: "请输入收票人邮箱" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              确认
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
