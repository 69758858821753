/*
 * @Author: cc
 * @LastEditTime: 2023-12-18 16:22:56
 */
import { useState, useEffect } from "react";
import { Button, Breadcrumb, message, Modal } from "antd";
import project_bg from "../../img/project_bg.png";
import "./my-project-info.scss";
import { ExclamationCircleFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { FinishCourse } from "../../components/index";
import { IconName } from "@fortawesome/fontawesome-common-types";
import { post, get } from "../../common/fetch";
import { useQuery } from "../../common/utils";
import { useHistory } from "react-router-dom";
import courseImg from "../../img/course.svg";
import refresherImg from "../../img/refresher.svg";
import scheduleImg from "../../img/schedule.svg";
import SelectCourse from "./component/selectCourse";
import { UserInfoConfirm, UserOrderConfirm } from "../../components/index";
import defaultProjectCover from "../../img/project_default.jpg";
import {CountDown, Menu, Icon } from "../../components/index";
import store from "../../reducers";
const { confirm } = Modal;
export type IconType = {
  iconStr: IconName;
};
export interface projectListInfo {
  // 课程名称
  title: string;
  // 主讲人
  teacher: string;
  // 单位
  employer: string;
  // 学时
  period: number;
  // 背景图
  cover: string;
  // 课程ID
  id: string;
  // 考试状态
  userStatus: number;
  // 课程类型
  requiredType: number;
  // 考试类型
  examType: number;
}
interface trainClassInfo {
  // 价格
  amount: number;
  // 是否购买
  buyClassFlag: number;
  //
  certType: number;
  // 描述
  desc: string;
  examType: number;
  // 课程id
  id: string;
  // 名称
  name: string;
  notRequiredPeriod: number;
  noteType: number;
  // 需要完成的学时
  requiredPeriod: number;
  // 用户学习状态
  userStatus: number;
  // 价格
  salePrice: number;
  // 价格
  salePriceText: string;
  // 选修完成时长
  finishedNotRequiredPeriod: number;
  // 必修完成时长
  finishedRequiredPeriod: number;
  // 试卷类型
  examSystemType: number;
}
interface navListProps {
  title: string;
  sub: string;
  icon: any;
  disabled: boolean;
}
interface ExamInfoList {
  name: string;
  examPaperName: string;
  examPaperId: string;
  examStatus: number;
  examTimeRange: string;
  examStatusText: string;
  examManagerId?: string;
  examSystemType: number;
  activityId?: string;
}

interface ProjectPageSetInfo {
  showLogoFlag?: number;
  showIndexUrlFlag?: number;
  showPersonCenterFlag?: number;
  projectAllPathLogo: any;
}
// 考试状态
const examStatusArray = ["未开始", "学习中", "学习中", "已通过"];
// 申请证书状态
const applyCertificateStatusArr = ["审核通过", "待审核", "驳回", "返回修改"];
let navList = [
  { title: "培训须知", sub: "1", icon: "bullhorn", disabled: false },
  { title: "课程列表", sub: "2", icon: "list-ul", disabled: true },
  { title: "问卷考试", sub: "3", icon: "landmark", disabled: true },
];
let manageId: string = "";
function ProjectInfo(props: any) {
  const query = useQuery();
  const history = useHistory();
  const courseId = query.get("courseId") as string;
  const projectId = query.get("projectId") as string;
  const [canBuyProjectList, setCanBuyProjectList] = useState(
    [] as projectListInfo[]
  );
  const [projectName, setProjectName] = useState('')
  const [showUserConfirmModal, setShowUserConfirmModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [showSelectModal, setShowSelectModal] = useState(false);
  const [projectList, setProjectList] = useState([] as projectListInfo[]);
  const [trainClassInfo, setTrainClassInfo] = useState({} as trainClassInfo);
  const [navIndex, setNavIndex] = useState("0");
  const [navlist, setNavList] = useState<navListProps[]>(navList);
  const [introduction, setIntroduction] = useState('')
  const [cmTrainPaperType, setCmTrainPaperType] = useState<number>(0)
  const [trainExamInfo, setTrainExamInfo] = useState({} as any)
  const [projectExamInfoList, setProjectExamInfoList] = useState([] as ExamInfoList[])
  const [learnRuleInfo, setLearnRuleInfo] = useState({} as any)
  const [examSystemType, setExamSystemType] = useState(2)
  const [hasReadFlag, setHasReadFlag] = useState(false);
  const projectPageSetInfo: ProjectPageSetInfo = store.getState().projectInfo;
  const getProjectInfo = async () => {
    const url = `/shop/front/project/cm/train/info/${projectId}`;
    get(url, {}, true).then(res => {
      console.log(res)
      const { projectName, trainClassInfo, canBuyProjectList, introduction, cmTrainPaperType, trainExamInfo, projectExamInfoList, learnRuleInfo, examSystemType,mustReadNoticeFlag,hasReadNoticeFlag,projectPageSet} = res.data;
      const { projectList } = trainClassInfo;
      setProjectName(projectName)
      setCanBuyProjectList(canBuyProjectList);
      setTrainClassInfo(trainClassInfo);
      setTrainExamInfo(trainExamInfo)
      setProjectList(projectList);
      setIntroduction(introduction)
      setCmTrainPaperType(cmTrainPaperType)
      setProjectExamInfoList(projectExamInfoList)
      setLearnRuleInfo(learnRuleInfo)
      setExamSystemType(examSystemType)
      let hasReadFlag = learnRuleInfo.mustReadNoticeFlag === 1 && hasReadNoticeFlag === 0;
      setHasReadFlag(hasReadFlag);
      if(projectPageSet){
        store.dispatch({
          type: "CHANGE_PROJECTINFO",
          payLoad: {
            ...projectPageSet,
          },
        });
        sessionStorage.setItem(
          "projectPageSetInfo",
          JSON.stringify(projectPageSet)
        );
      }
      if (!hasReadFlag) {
        setNavIndex("1")
        setNavList(
          navlist.map((item, index) =>
            index > 0
              ? {
                  title: item.title,
                  sub: item.sub,
                  icon: item.icon,
                  disabled: false,
                }
              : item
          )
        );
      }
    })
  };
  useEffect(() => {
    getProjectInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  /**
   * 检查用户信息是否完善
   */
  const checkUserInfo = async (amount: number) => {
    const { code } = await post(
      "/shop/front/cm/train/user/info/check",
      {},
      false
    );
    if (code === 2000) {
      setShowUserConfirmModal(true);
    } else {
      // 免费参加
      if (amount === 0) {
        joinFreeCourse();
      } else {
        setShowOrderModal(true);
      }
    }
  };
  /**
   * 参加免费课程
   */
  const joinFreeCourse = async () => {
    await post(
      `/shop/front/cm/train/class/${trainClassInfo.id}/free/join`,
      {},
      false
    );
    getProjectInfo();
  };
  /**
   * 申请证书
   */
  const applyCertificate = async () => {
    const { data } = await post(
      `/shop/front/cm/user/get/${trainClassInfo.id}/cert`,
      {},
      false
    );
    if (!data) {
      const {
        data: { status },
      } = await post(
        `/shop/front/cm/user/apply/${trainClassInfo.id}/cert`,
        {},
        false
      );
      // 证书已通过
      if (status === 1) {
        downLoadDiploma();
      } else {
        message.info(applyCertificateStatusArr[status - 1]);
      }
    } else if (data.status === 1) {
      confirm({
        title: "恭喜您已经通过培训和证书审核，请按以下流程进行证书领取",
        icon: <ExclamationCircleFilled />,
        content: (
          <div>
            <p>1、打开网站: https://www.zyjjgl.org.cn/</p>
            <p>2、在网站下方找到【证书查询及申领】并点击</p>
            <p>3、微信扫码登录</p>
            <p>4、登录后即可查到自己的证书了</p>
          </div>
        ),
        okText: "关闭",
        cancelText: "前往网站",
        onCancel() {
          window.open("https://www.zyjjgl.org.cn/");
        },
      });
    } else if (data.status === 2) {
      Modal.success({
        title: "领取证书",
        content: (
          <div>
            <h6>资格审核中</h6>
            <p>说明：证书将在培训结束后统一进行审核发放，请耐心等待</p>
          </div>
        ),
      });
    } else if (data.status === 3) {
      Modal.success({
        title: "领取证书",
        content: (
          <div>
            <p>您的证书申请已被驳回，有问题请联系中华中医药学会资讯详情。</p>
          </div>
        ),
      });
    }
  };
  // 下载文件
  const download = (url: string, name?: string) => {
    const filename: any = url.split("/").pop();
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute("download", name || filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  /**
   * 下载证书
   */
  const downLoadDiploma = async () => {
    const {
      data: { url },
    } = await await post(
      `/shop/front/cm/user/get/${trainClassInfo.id}/cert`,
      {},
      false
    );
    download(url);
  };
  const checkProjectExam = (
    examManagerId: string
  ): Promise<{
    projectCanExamNum: number;
    projectExamLimitFlag: number;
    projectExamLimitNum: number;
  }> => {
    return new Promise((resolve, reject) => {
      const url = `/shop/exam/checkProjectExam`;
      post(
        url,
        {
          projectId,
          examManagerId,
        },
        true
      ).then((res) => {
        const { projectCanExamNum, projectExamLimitFlag, projectExamLimitNum } =
          res.data;
        resolve({
          projectCanExamNum,
          projectExamLimitFlag,
          projectExamLimitNum,
        });
      });
    });
  };
  const getProjectExamUrl = (
    it: ExamInfoList,
    projectCanExamNum: number,
    projectExamLimitFlag: number
  ) => {
    // 如果剩余次数是0次，则不跳转，并且不是无限次
    if (projectCanExamNum === 0 && projectExamLimitFlag === 1) {
      return;
    }
    const { REACT_APP_NODE_ENV } = process.env;
    if (examSystemType === 2) {
      const { activityId } = it;
      window.location.href = `${window.origin}/${REACT_APP_NODE_ENV === "development" || REACT_APP_NODE_ENV === "test"
        ? "testing-mvw-exam-mobile"
        : "studentmobile"
        }/exam-list/examList?fromType=projectExam&projectId=${projectId}&examManagerId=${manageId}&activityId=${activityId}`;
    } else {
      const url = `/shop/exam/getProjectExamUrl`;
      post(
        url,
        {
          projectId,
        },
        true
      ).then((res) => {
        const { projectExamUrl } = res.data;
        window.location.href = projectExamUrl;
      });
    }
  };
  // 项目考试
  const checkExamFlag = async (it: ExamInfoList) => {
    const { examManagerId = "" } = it;
    manageId = examManagerId;
    // 获取考试剩余次数
    const { projectCanExamNum, projectExamLimitFlag, projectExamLimitNum } =
      await checkProjectExam(manageId);
    if (projectExamLimitFlag === 1) {
      Modal.confirm({
        title: "提示",
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            <p>本场共{projectExamLimitNum}次机会</p>
            <p>当前剩余{projectCanExamNum}次</p>
          </div>
        ),
        okText: "确认",
        cancelText: "取消",
        onOk() {
          getProjectExamUrl(it, projectCanExamNum, projectExamLimitFlag);
        },
      });
    } else {
      getProjectExamUrl(it, projectCanExamNum, projectExamLimitFlag);
    }
  };
  const saveReadNotic = async () => {
    const url = `/shop/front/project/${projectId}/saveReadNotice`;
    post(url, {}, false).then((res) => {
      setNavList(
        navlist.map((item, index) =>
          index > 0
            ? {
                title: item.title,
                sub: item.sub,
                icon: item.icon,
                disabled: false,
              }
            : item
        )
      );
      setHasReadFlag(false);
    });
  };
  return (
    <div className="myPayProjectInfo_box">
      <div className="project_header">
        <img className="header_img" src={project_bg} alt="" />
        <div className="project_title">
          <Breadcrumb
            separator="/"
            style={{ fontSize: "20px", marginBottom: "30px" }}
          >
            {projectPageSetInfo.showIndexUrlFlag === 1 && (
              <Breadcrumb.Item href="/#/train">首页</Breadcrumb.Item>
            )}
            <Breadcrumb.Item>
              <span style={{ color: "#1890ff" }}>项目主页</span>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="project_detail_box">
            <div>
              <h2>{projectName}</h2>
              {/* <p className="introduction">{trainClassInfo.desc}</p> */}
              <div className="courseLearn_progress">
                <div className="progress_content">
                  <p>
                    <img src={courseImg} alt="" />
                    <p className="course_type">必修课</p>
                  </p>
                  <div className="progress">
                    <p>需要完成{trainClassInfo.requiredPeriod}学时</p>
                    <p>已完成{trainClassInfo.finishedRequiredPeriod}学时</p>
                  </div>
                </div>
                <div className="progress_content">
                  <p>
                    <img src={refresherImg} alt="" />
                    <p className="course_type">选修课</p>
                  </p>
                  <div className="progress">
                    <p>需要完成{trainClassInfo.notRequiredPeriod}学时</p>
                    <p>已完成{trainClassInfo.finishedNotRequiredPeriod}学时</p>
                  </div>
                  <p className="progress">
                    <Button
                      type="primary"
                      onClick={() => {
                        setShowSelectModal(true);
                      }}
                      disabled={
                        canBuyProjectList.length === 0 ||
                        trainClassInfo.buyClassFlag === 0
                      }
                    >
                      选课
                    </Button>
                  </p>
                </div>
              </div>
              {/* {["Vq3QBDNk", "oVDob3BA"].includes(courseId) && (
                <div className="prompt">
                  <p> 学习说明：</p>
                  <p>
                    1、需要达到条件要求的必修/选修课时才可以进行项目考试，项目考试通过后进行证书申请。
                  </p>
                  <p>
                    2、单个课件完成，必修/选修学时不统计，完成整个课程才会获得学时。
                  </p>
                  <p>3、若课程/课件存在考试，考试通过才视为学习完成。</p>
                  <p>
                    4、证书发放将在项目结束后统一进行审核，证书申请后请耐心进行等待。
                  </p>
                </div>
              )} */}
            </div>
          </div>


          <Menu
            style={{ position: "absolute", bottom: "0", left: "0" }}
            index={navIndex}
            defaultOpenSubMenus={[]}
            mode="horizontal"
            onSelect={(index) => {
              if (index === "3") {
                // history.push(`./dataQuery?projectId=${projectId}`);
              } else if (index === "4") {
                // history.push(`./certificateRecords?projectId=${projectId}`);
              }
              setNavIndex(index);
            }}
          >
            {navlist.map((it: navListProps, index: number) => (
              <Menu.Item key={index} disabled={it.disabled}>
                <Icon
                  icon={it.icon}
                  size="1x"
                  theme="dark"
                  style={{ marginRight: "8px" }}
                />
                <span>{it.title}</span>
              </Menu.Item>
            ))}
          </Menu>
        </div>
      </div>
      <div style={{ width: "1000px", margin: "0 auto", minHeight: "300px", background: "#fff" }}>
        {navIndex === "0" && (
          <div
            dangerouslySetInnerHTML={{
              __html: introduction,
            }}
            style={{
              padding: "6px 12px",
              width: "100%",
              overflowWrap: "anywhere",
            }}
          ></div>
        )}
        {navIndex === "0" && hasReadFlag && (
          <CountDown time={learnRuleInfo.readNoticeTime} sureClick={saveReadNotic} />
        )}
        {navIndex === '1' && trainClassInfo.buyClassFlag === 1 && <div className="project_list">
          {projectList.map((item) => (
            <div
              className="course_content"
              onClick={async () => {
                const { code } = await post(
                  "/shop/front/cm/train/user/info/check",
                  {},
                  false
                );
                if (code === 2000) {
                  setShowUserConfirmModal(true);
                } else {
                  if (trainClassInfo.buyClassFlag === 0) {
                    const { amount } = trainClassInfo;
                    checkUserInfo(amount);
                  } else {
                    history.push(
                      `/platfromplay?classId=${trainClassInfo.id}&projectId=${item.id}&examType=${item.examType}`
                    );
                  }
                }
              }}
            >
              <div style={{position:"relative"}}>
                <img src={item.cover || defaultProjectCover} alt="" />
                {!item.cover&&<p className={!item.cover?'defaultCover course_title':'course_title'}  >{item.title}</p>}
                {item.teacher && <p>主讲人：{item.teacher}</p>}
                {item.employer && <p>单位：{item.employer}</p>}
              </div>
              <p className="course_title">{item.title}</p>
              <p>
                <span className="compulsoryCourse">
                  {item.requiredType === 2 ? "选修课" : "必修课"}
                </span>
                <span className="hours">{item.period}学时</span>
              </p>
              {/* {trainClassInfo.userStatus === 2 && (
                  <div className="finish_icon">
                    <FinishCourse text="学完" />
                  </div>
                )} */}
              <div
                className={`learn_status ${item.userStatus < 2 ? "disabled" : ""
                  }`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {examStatusArray[item.userStatus]}
              </div>
              {![0, 9].includes(item.examType) && (
                <div
                  className={
                    item.userStatus >= 2
                      ? "exam_status"
                      : "exam_status exam_disabled"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (item.userStatus >= 2) {
                      history.push(
                        `/exam?partnerClassId=${courseId}&examType=${2}&projectId=${item.id
                        }`
                      );
                    }
                  }}
                >
                  {item.userStatus === 3 ? "已通过" : "开始考试"}
                </div>
              )}
            </div>
          ))}
        </div>}
        {navIndex === '2' && <div style={{ background: "#fff", padding: "40px" }}>
          {cmTrainPaperType === 1 && <div>
            <div>
              <p>{trainExamInfo.title}</p>
              <p
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>状态：{trainExamInfo.examViewStatus}</span>
                <Button
                  type="primary"
                  disabled={trainClassInfo.userStatus !== 2}
                  onClick={() => {
                    history.push(
                      `/exam?partnerClassId=${trainClassInfo.id
                      }&examType=${1}`
                    );
                  }}
                >
                  进入考试
                   </Button>
              </p>
            </div>
          </div>}
          {cmTrainPaperType === 2 && <div>
            {projectExamInfoList &&
              projectExamInfoList.map((item, index) => (
                <div key={index}>
                  <p>{item.examPaperName}</p>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>状态：{item.examStatusText}</span>
                    <Button
                      type="primary"
                      onClick={() => {
                        checkExamFlag(item)
                      }}
                      disabled={
                        item.examStatus !== 1
                      }
                    >
                      进入考试
                         </Button>
                  </p>
                </div>
              ))}
          </div>}
        </div>}
      </div>
      <SelectCourse
        showSelectModal={showSelectModal}
        setShowSelectModal={setShowSelectModal}
        canBuyProjectList={canBuyProjectList}
        classId={trainClassInfo.id}
        className={trainClassInfo.name}
      />
      <UserInfoConfirm
        showUserConfirmModal={showUserConfirmModal}
        setShowUserConfirmModal={setShowUserConfirmModal}
      />
      <UserOrderConfirm
        showOrderModal={showOrderModal}
        setShowOrderModal={setShowOrderModal}
        courseInfo={trainClassInfo}
      />
    </div>
  );
}
export default ProjectInfo;
