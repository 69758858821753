/*
 * @Author: cc
 * @LastEditTime: 2022-06-15 11:35:10
 */
import { useState, useEffect, createRef } from "react";
import { Form, Input,Button,Table } from "antd";
// import project_bg from "../../img/project_bg.png";
// import no_class from "../../img/no_class.png";
import "./data_query.scss";
// import { CountDown, Menu, Icon } from "../../components/index";
// import { IconName } from "@fortawesome/fontawesome-common-types";
import { post } from "../../common/fetch";
import { useQuery } from "../../common/utils";
import { useHistory } from "react-router-dom";
// import liveIcon from "../../img/live-icon.png";
// import { TopicCourseSingle } from "./component/topicExamSingle/topicSingleCouse";
// import { TopicCourseDouble } from "./component/topicExamDouble/topicDoubleCourse";
import { FormInstance } from "antd/lib/form";
interface infoType{
    avgLearnDuration:number,
    courseNum:number,
    examLearnRatio:number,
    examLearnRatioText:string,
    examPersonNum:number,
    finishPercent:number,
    finishPersonNum:number,
    learnFinishRatio:number,
    learnFinishRatioText:string,
    planTrainPersonNum:number,
    realTrainPersonNum:number,
    totalTrainRatio:number,
    totalTrainRatioText:string,
    videoNum:number
}
const initInfo = {
    avgLearnDuration:0,
    courseNum:0,
    examLearnRatio:0,
    examLearnRatioText:'',
    examPersonNum:0,
    finishPercent:0,
    finishPersonNum:0,
    learnFinishRatio:0,
    learnFinishRatioText:'',
    planTrainPersonNum:0,
    realTrainPersonNum:0,
    totalTrainRatio:0,
    totalTrainRatioText:'',
    videoNum:0
}
let projectId: string;
function DataQuery() {
    const query = useQuery();
    projectId = query.get("projectId") as string;
    const history = useHistory();
    const form = createRef<FormInstance>();
    const [info,setInfo] = useState<infoType>(initInfo)
    const [list,setList] = useState([])
    const [currentPage,setCurrentPage] = useState(1)
    const [pageSize] = useState(10)
    const [total,setTotal] = useState(1)
    const [columns] = useState([
        {
            title: '姓名',
            dataIndex: 'maskName',
            key: 'maskName',
          },
          {
            title: '手机',
            dataIndex: 'maskMobile',
            key: 'maskMobile',
          },
          {
            title: '学习总时长',
            dataIndex: 'projectWatchTimeText',
            key: 'projectWatchTimeText',
          },
          {
            title: '项目最高成绩',
            dataIndex: 'projectMaxScore',
            key: 'projectMaxScore',
          },
          {
            title: '项目考试次数',
            dataIndex: 'projectExamNum',
            key: 'projectExamNum',
          },
          {
            title: '项目完成率',
            dataIndex: 'learnProgressText',
            key: 'learnProgressText ',
          },
          {
            title: '视频完成数',
            dataIndex: 'videoFinishNum',
            key: 'videoFinishNum',
          },
          {
            title: '最近学习时长',
            dataIndex: 'lastLearnTime',
            key: 'lastLearnTime',
          },
    ])
    function getInfo(){
        post(`/shop/api/project/${projectId}/summary/info`,{},true).then(res=>{
            console.log(res.data)
           setInfo(res.data)
        })
    }
    function getList(page:number){
        post('/shop/api/project/record/list',{projectId,page:{currentPage:page,pageSize}},true).then(res=>{
            setTotal(res.data.page.totalResultSize)
            setList(res.data.userProjectRecordDataList)
         })
    }
    function changePage(current:number){
       setCurrentPage(current)
       getList(current)
    }
    function fileter(){
        const data = form.current?.getFieldsValue()
        post('/shop/api/project/record/list',{projectId,page:{currentPage:currentPage,pageSize},...data},true).then(res=>{
            setTotal(res.data.page.totalResultSize)
            setList(res.data.userProjectRecordDataList)
         })
    }
    function reset(){
        form.current?.resetFields()
        getList(1)
     }
    useEffect(()=>{
        getInfo()
        getList(currentPage)
    },[])// eslint-disable-line
    return <div className="data_query">
        <div className="filter_box">
            <h4 style={{padding:"0 10px"}}>项目概况  <a style={{color:'#0d6efd',float:"right"}} onClick={()=>{
                history.goBack()
            }}>返回</a></h4>
            <div className="nav_list">
                <div className="nav_list_item">
                    <div>课程量/视频量</div>
                    <div>{info?.courseNum}</div>
                </div>
                <div className="nav_list_item">
                    <div>实际参培/计划参培</div>
                    <div>{info?.realTrainPersonNum}/{info?.planTrainPersonNum}</div>
                </div>
                <div className="nav_list_item">
                    <div>总参培率</div>
                    <div>{info?.totalTrainRatioText}</div>
                </div>
                <div className="nav_list_item">
                    <div>完成人数/实际参培</div>
                    <div>{info?.finishPersonNum}/{info?.realTrainPersonNum}</div>
                </div>
                <div className="nav_list_item">
                    <div>学习完成率</div>
                    <div>{info?.learnFinishRatioText}</div>
                </div>
                <div className="nav_list_item">
                    <div>人均学习时长</div>
                    <div>{info?.avgLearnDuration}</div>
                </div>
                <div className="nav_list_item">
                    <div>参考人数/实际参培</div>
                    <div>{info?.examPersonNum}/{info?.realTrainPersonNum}</div>
                </div>
                <div className="nav_list_item">
                    <div>参考率</div>
                    <div>{info?.examLearnRatioText}</div>
                </div>
            </div>
        </div>
        <Form
            style={{padding:"20px"}}
            layout="inline"
            ref={form}
        >
            <Form.Item
                label="姓名"
                name="name"
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="手机号"
                name="mobile"
            >
                <Input />
            </Form.Item>
            <Form.Item
            >
                <Button type="primary" onClick={fileter}>查询</Button>
                <Button style={{marginLeft:"20px"}} onClick={reset}>重置</Button>
            </Form.Item>
        </Form>
        <div>
            <Table style={{display:"block"}} dataSource={list} columns={columns} pagination={ {pageSize:pageSize,total:total,onChange:changePage}} />
        </div>
    </div>
}
export default DataQuery;
