/*
 * @Author: changcheng
 * @LastEditTime: 2022-07-04 17:09:55
 */
import { connect } from "react-redux";
function ActivityEntry(props: any) {
  // useEffect(() => {
  //   queyActivityList();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // const queyActivityList = () => {
  //   post(`/shop/front/activity/getActivityProjectList`, {}, true).then(
  //     (res) => {
  //       const { data = [] } = res;
  //       setActivityInfo(data);
  //     }
  //   );
  // };
  return (
    <div
      style={{
        display: "flex",
      }}
    >
      {/* <h4 style={{ ...sty }}>首页</h4> */}
      {/* {activityInfo.length > 0 && (
        <h4
          style={{ ...sty }}
          onClick={(e) => {
            e.stopPropagation();
            history.push("/classList?type=activity");
          }}
        >
          活动中心
        </h4>
      )} */}
    </div>
  );
}
const getActivityInfo = (state: { activityInfo: Array<any> }) => {
  return {
    activityInfo: state.activityInfo,
  };
};
const activityInfoFnProps = (dispatch: any) => {
  return {
    setActivityInfo: (data: Array<any>) => {
      dispatch({
        type: "CHANGE_ACTIVITY_INFO",
        payLoad: data,
      });
    },
  };
};
export default connect(getActivityInfo, activityInfoFnProps)(ActivityEntry);
