/*
 * @Author: cc
 * @LastEditTime: 2023-11-20 17:35:33
 */
import { message } from "antd";
// 兼容低版本fetch
import "whatwg-fetch";
import eventEmitter from '../eventEmitter';

const BASE_URL = process.env.REACT_APP_BASE_URL || "";
// console.log(BASE_URL);
interface RequestParams {
  timestamp?: number;
  tissueId?: string;
  userId?: string;
  paramets?: any;
}

export const requestParams: RequestParams = {
  timestamp: new Date().getTime(), // Number, 非空，接口请求时间毫秒数
};

/**
 * 处理url
 * @param url
 * @param param
 * @returns {*}
 */
function handleURL(url: string, param: any) {
  let completeUrl = "";
  if (
    url.match(
      /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i
    )
  ) {
    completeUrl = url;
  } else {
    completeUrl = BASE_URL + url;
  }
  if (param) {
    if (completeUrl.indexOf("?") === -1) {
      completeUrl = `${completeUrl}?${ObjToURLString(param)}`;
    } else {
      completeUrl = `${completeUrl}&${ObjToURLString(param)}`;
    }
  }
  return completeUrl;
}

function handleUrl(url: string) {
  console.log(BASE_URL,'1111111111111')
  let completeUrl = "";
  if (
    url.match(
      /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i
    )
  ) {
    completeUrl = url;
  } else {
    completeUrl = BASE_URL + url;
  }
  console.log(completeUrl,'22222222222222')
  return completeUrl;
}

/**
 * 将参数对象转化为'test=1&test2=2'这种字符串形式
 * @param param
 * @returns {string}
 * @constructor
 */
function ObjToURLString(param: any[]) {
  let str = "";
  if (Object.prototype.toString.call(param) === "[object Object]") {
    const list = Object.entries(param).map((item) => {
      return `${item[0]}=${item[1]}`;
    });
    str = list.join("&");
  }
  return str;
}

// 获取
export async function intergrationGet(url: string, param: any) {
  const completeUrl = handleURL(url, param);
  const response = await fetch(completeUrl, {
    credentials: "include",
    method: "GET",
  });
  const result = await response.json();
  return result;
}

// POST提交
export async function loginPost(url: string, param: any) {
  const completeUrl = handleUrl(url);
  const response = await fetch(completeUrl, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify(param),
  });
  const result = await response.json();
  if (result.status !== true) {
    return {
      status: false,
      reason: result.reason,
    };
  }
  requestParams.userId = result.userID;
  return result;
}

// const controller = new AbortController();
// const signal = controller.signal;

export async function checkNet() {}

/**
 * 文件图片上传
 * @param methods 请求方法 post
 * @param url  请求连接
 * @param param  参数
 * @param isShowLoading  是否显示加载loading
 * @param status 状态 randomTakePic 随机拍照  firstUpload 首次上传人脸  faceRecognition 人脸验证
 */
// 公共http  ajax请求
const common_upload_file = (
  methods: string,
  url: string,
  param: any,
  isShowLoading: boolean,
  status: string
) => {
  return new Promise<any>(async (resolve, reject) => {
    if (isShowLoading) {
      loadingCount++; // loading调用次数
    }
    const completeUrl = handleUrl(url);
    const xhr = new XMLHttpRequest();
    // 构建一个 FormData 对象，用于存储文件或其他参数
    const formData = new FormData();
    if (status === "randomTakePic") {
    }
    // console.log(formData);
    xhr.open("POST", completeUrl);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          if (isShowLoading) {
            loadingCount = loadingCount === 0 ? 0 : loadingCount - 1;
            if (loadingCount === 0) {
              loadingCount = 0;
            }
          }

          const result = JSON.parse(xhr.responseText);
          console.log("190", result);
          if (result.code !== 200) {
            let msg = "";
            if (result.message === "0001") {
              msg = "未检测到人脸信息";
            } else if (result.message === "0002") {
              msg = "检测到多张人脸信息";
            } else if (result.message === "0003") {
              msg = "您上传的图片无法识别";
            } else if (result.message === "0004") {
              msg = "照片不符合要求，请重新上传";
            } else if (result.message === "InvalidParameter.TooLarge") {
              msg = "待检测内容过大，请确保检测的内容在API的限制范围内";
            } else {
              msg = "文件上传失败！";
            }
            reject(message.info(msg));
          } else {
            resolve(result.data);
          }
        } else {
          if (loadingCount !== 0) {
            loadingCount = 0;
          }
          if (isShowLoading) {
          }
          reject(message.info("请求异常", 1));
        }
      }
    };
    xhr.send(formData);
  });
};

// 先定义延时函数
const ajax_delay = (timeOut = 12 * 1000) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // reject(new Error('网络超时'));
      resolve({ code: 504, message: "请求超时", data: null });
      // controller.abort();
    }, timeOut);
  });
};

let loadingCount = 0; // loading发起时加的计数锁
let isLoginInvalid = false;
let isHandlingLoginInvalid = false;
// 公共http  ajax请求
const common_ajax = (
  methods: string,
  url: string,
  param: any,
  isShowLoading: boolean
) => {
  return new Promise<any>(async (resolve, reject) => {
    if (isShowLoading) {
      loadingCount++; // loading调用次数
    }

    const completeUrl = handleUrl(url);
    // requestParams.timestamp = new Date().getTime();
    // requestParams.paramets = Object.assign({}, param);
    let requestBody: any = {
      credentials: "include", // 携带cookie信息
      method: methods,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    if (param) {
      requestBody.body = JSON.stringify(param);
    }
    if (methods === "GET") {
      requestBody = {
        credentials: "include", // 携带cookie信息
        method: methods,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        // signal,
      };
    }
    fetch(completeUrl, requestBody)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // reject(new Error('服务器异常'));
          if (loadingCount !== 0) {
            loadingCount = 0;
          }
          if (isShowLoading) {
          }
          reject(message.info("服务器异常", 1));
        }
      })
      .then((responseJson) => {
        const { code, msg } = responseJson;
        if (isShowLoading) {
          loadingCount = loadingCount === 0 ? 0 : loadingCount - 1;
          if (loadingCount === 0) {
            loadingCount = 0;
          }
        }
        // 状态码非0就是报错
        if (code !== 0 && code !== 2000) {
          // 登录失效的判断
          if (code === 101 || code === 81) {
            isLoginInvalid = true;
            if (!isHandlingLoginInvalid) {
              isHandlingLoginInvalid = true;
              eventEmitter.emit('loginInvalid');
            }
          }else{
            message.info(msg)
            resolve(responseJson);
          }
        } else if (code === 0 || code === 2000) {
          resolve(responseJson);
        }
      })
      .catch((err) => {
        reject(new Error(err));
        if (loadingCount !== 0) {
          loadingCount = 0;
        }
        if (isShowLoading) {
        }
        if(err !== '登录失效'){
          reject(message.info("请求异常", 1));
        }
      });
  });
};

// Promise race任务
const ajax_fetch = (fetchPromise: any, timeout: any) => {
  return Promise.race([fetchPromise, ajax_delay(timeout)]);
};

// get获取
export async function get(url: string, param: any, isShowLoading: boolean) {
  return ajax_fetch(common_ajax("GET", url, param, isShowLoading), 12 * 1000);
}

// POST提交
export function post(url: string, param: any, isShowLoading: boolean) {
  return ajax_fetch(common_ajax("POST", url, param, isShowLoading), 12 * 1000);
}

// put修改
export async function put(url: string, param: any, isShowLoading: boolean) {
  return ajax_fetch(common_ajax("PUT", url, param, isShowLoading), 12 * 1000);
}

// 删除
export async function del(url: string, param: any, isShowLoading: boolean) {
  return ajax_fetch(
    common_ajax("delete", url, param, isShowLoading),
    12 * 1000
  );
}

// 上传图片
export async function upload(
  url: string,
  param: any,
  isShowLoading: boolean,
  status: string
) {
  return ajax_fetch(
    common_upload_file("POST", url, param, isShowLoading, status),
    12 * 1000
  );
}
