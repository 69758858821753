/*
 * @Author: cc
 * @LastEditTime: 2024-03-27 19:47:25
 */
import { useState, useEffect } from "react";
import { Button, Breadcrumb, Modal } from "antd";
import project_bg from "../../img/project_bg.png";
import "./topicInfo.scss";
import { Menu, Icon } from "../../components/index";
import { IconName } from "@fortawesome/fontawesome-common-types";
import { post } from "../../common/fetch";
import { useQuery } from "../../common/utils";
import { connect } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { TopicCourseDoubleCourse } from "./component/topicExamDouble/topicDoubleCourseList";
import store from "../../reducers";
export type IconType = {
  iconStr: IconName;
};
interface navListProps {
  title: string;
  sub: string;
  icon: any;
}
interface classInfoProps {
  // 课程名称
  projectName: string;
  // 学习时间范围
  topicTimeRange: string;
  // 课程总时长
  topicDurationText: string;
  // 学习进度
  projectProgress: string;
  // 专题介绍
  intro: string;
  // 是否已经阅读
  topicProgress: number;
  // 项目封面图
  topicPicture: string;
  // 考试类型
  examSystemType: number;
  // 专题考试名称
  topicExamName: string;
  topicId: number;
  projectId: number;
  topicName?: string; // 专题名称
}
interface projectInfo {
  showCourseDurationFlag: number;
  topicDuration: string;
  topicDurationText: string;
  showWatchTimeFlag: number;
  topicWatchTime: string;
  topicWatchTimeText: string;
  showCourseCountFlag: number;
  topicCourseCount: number;
  showLearnedCourseCountFlag: number;
  topicLearnedCourseCount: number;
  showCourseExamCountFlag: number;
  topicCourseExamCount: number;
  showFinishCourseExamCountFlag: number;
  topicFinishCourseExamCount: number;
  showLearnProgressFlag: number;
  topicProgress: string;
}

interface ExamProps {
  hasTopicExamFlag: number;
  hasTopicQuestionnaireFlag: number;
  topicExamStatus: number;
  topicQuestionnaireStatus: number;
}
function HeaderIcon(props: IconType) {
  const { iconStr } = props;
  return <Icon icon={iconStr} size="1x" theme="gray" />;
}

let projectId: string;
const QuestionStatus = ["不可以调查问卷", "可以调查问卷", "完成调查问卷"];
const ExamStatus = new Map([
  ["0", "学习进度未达标"],
  ["1", "可以考试"],
  ["2", "通过考试"],
  ["7", "未完成调查问卷"],
  ["8", "未考试"],
]);
function TopicInfo(props: any) {
  const query = useQuery();
  projectId = query.get("projectId") as string;
  const {
    projectType: { type },
  } = props;
  const [navIndex, setNavIndex] = useState("1");
  const [projectInfo, setProjectInfo] = useState<projectInfo>(
    {} as projectInfo
  );
  const topicId = query.get("topicId") as string;
  const [navlist] = useState<navListProps[]>([
    { title: "专题介绍", sub: "1", icon: "bullhorn" },
    { title: "课程列表", sub: "2", icon: "list-ul" },
    { title: "专题考试", sub: "3", icon: "landmark" },
  ]);
  const [classInfo, setClassInfo] = useState<classInfoProps>(
    {} as classInfoProps
  );
  const [questionExamInfo, setQuestionExamInfo] = useState<ExamProps>(
    {} as ExamProps
  );
  const getProjectInfo = () => {
    const url = `/shop/front/project/${projectId}/topic/${topicId}/info`;
    post(url, {}, true).then((res) => {
      const { data } = res;
      data.topicProgress = Number(parseFloat(data.topicProgress));
      const {
        hasTopicExamFlag,
        hasTopicQuestionnaireFlag,
        projectPageSetInfo,
        topicExamStatus,
        topicQuestionnaireStatus,
      } = data;
      setProjectInfo(data);
      setQuestionExamInfo({
        hasTopicExamFlag,
        hasTopicQuestionnaireFlag,
        topicExamStatus,
        topicQuestionnaireStatus,
      });
      setClassInfo(data);
      store.dispatch({
        type: "CHANGE_PROJECTINFO",
        payLoad: {
          ...projectPageSetInfo,
        },
      });
      sessionStorage.setItem(
        "projectPageSetInfo",
        JSON.stringify(projectPageSetInfo)
      );
    });
  };
  const getTopicQuestionnaireUrl = () => {
    const url = `/shop/questionnaire/getProjectTopicStarQuestionnaireUrl`;
    post(url, { projectId, topicId }, true).then((res) => {
      const { questionnaireUrl } = res.data;
      window.location.href = questionnaireUrl;
    });
  };
  // 项目考试
  const checkExamFlag = async (it: classInfoProps) => {
    // 获取考试剩余次数
    const { topicCanExamNum, topicExamLimitFlag, topicExamLimitNum } =
      await checkProjectExam();
    if (topicExamLimitFlag === 1) {
      Modal.confirm({
        title: "提示",
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            <p>本场共{topicExamLimitNum}次机会</p>
            <p>当前剩余{topicCanExamNum}次</p>
          </div>
        ),
        okText: "确认",
        cancelText: "取消",
        onOk() {
          getProjectExamUrl(it, topicCanExamNum, topicExamLimitFlag);
        },
      });
    } else {
      getProjectExamUrl(it, topicCanExamNum, topicExamLimitFlag);
    }
  };
  const checkProjectExam = (): Promise<{
    topicCanExamNum: number;
    topicExamLimitFlag: number;
    topicExamLimitNum: number;
  }> => {
    return new Promise((resolve, reject) => {
      post(
        `/shop/exam/checkProjectTopicExam`,
        {
          projectId,
          topicId,
        },
        true
      ).then((res) => {
        const { topicCanExamNum, topicExamLimitFlag, topicExamLimitNum } =
          res.data;
        resolve({
          topicCanExamNum,
          topicExamLimitFlag,
          topicExamLimitNum,
        });
      });
    });
  };
  const getProjectExamUrl = (
    it: classInfoProps,
    topicCanExamNum: number,
    topicExamLimitFlag: number
  ) => {
    const { examSystemType, topicId, projectId } = it;
    // 如果剩余次数是0次，则不跳转，并且不是无限次
    if (topicCanExamNum === 0 && topicExamLimitFlag === 1) {
      return;
    }
    if (examSystemType === 2) {
      const { REACT_APP_BASE_URL, REACT_APP_EXAM_URL } = process.env;
      window.location.href = `${REACT_APP_BASE_URL}${REACT_APP_EXAM_URL}/exam-list/examList?fromType=topicProjectExam&projectId=${projectId}&topicId=${topicId}`;
    } else {
      post(
        `/shop/exam/getProjectTopicExamUrl`,
        {
          projectId,
          topicId,
        },
        true
      ).then((res) => {
        const { topicExamUrl } = res.data;
        window.location.href = topicExamUrl;
      });
    }
  };
  useEffect(() => {
    getProjectInfo();
  }, [type]); // eslint-disable-line react-hooks/exhaustive-deps
  // 根据项目区分隐藏项目时长和项目总时长
  // const xjProjectFlag = useMemo(() => {
  //   const projectArr = ["487", "488", "489"];
  //   return projectArr.includes(projectId);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [projectId]);
  const {
    hasTopicExamFlag,
    hasTopicQuestionnaireFlag,
    topicExamStatus,
    topicQuestionnaireStatus,
  } = questionExamInfo;
  return (
    <div className="projectInfo_box">
      <div className="project_header">
        <img className="header_img" src={project_bg} alt="" />
        <div className="project_title">
          <Breadcrumb
            separator="/"
            style={{ fontSize: "20px", marginBottom: "30px" }}
          >
            <Breadcrumb.Item
              href={`/#/projectInfo?projectId=${projectId}&projectType=1`}
            >
              项目主页
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>专题主页</span>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="project_detail_box">
            <img
              src={classInfo?.topicPicture}
              alt=""
              style={{ width: "469px", height: "300px" }}
            />
            <div style={{ paddingLeft: "20px" }}>
              <h2>{classInfo?.topicName}</h2>
              <div className="time">
                {classInfo?.topicTimeRange && (
                  <p>开放时间：{classInfo?.topicTimeRange}</p>
                )}

                {/* {!xjProjectFlag && classInfo?.topicDurationText!=="00:00:00" && (
                  <p>
                      专题时长：{classInfo?.topicDurationText}
                  </p>
                )}
                {classInfo?.topicProgress > 0 && <p>
                  <HeaderIcon iconStr={"calendar-week"} />
                    学习进度：{classInfo?.topicProgress}%
                </p>} */}
                <div>
                  {projectInfo.showCourseDurationFlag === 1 &&
                    projectInfo?.topicDurationText !== "00:00:00" && (
                      <p style={{ width: "50%" }}>
                        课程时长：{projectInfo?.topicDurationText}
                      </p>
                    )}
                  {projectInfo.showWatchTimeFlag === 1 && (
                    <p>已学时长：{projectInfo?.topicWatchTimeText}</p>
                  )}
                </div>
                <div>
                  {/* 课程项目总时长 */}

                  {projectInfo.showCourseCountFlag === 1 && (
                    <p style={{ width: "50%" }}>
                      课程数量：
                      {projectInfo.topicCourseCount
                        ? projectInfo.topicCourseCount
                        : 0}
                    </p>
                  )}
                  {projectInfo.showLearnedCourseCountFlag === 1 && (
                    <p>
                      课程学完数量：
                      {projectInfo.topicLearnedCourseCount
                        ? projectInfo.topicLearnedCourseCount
                        : 0}
                    </p>
                  )}
                </div>
                <div>
                  {projectInfo.showCourseExamCountFlag === 1 && (
                    <p style={{ width: "50%" }}>
                      练习数量：
                      {projectInfo.topicCourseExamCount
                        ? projectInfo.topicCourseExamCount
                        : 0}
                    </p>
                  )}
                  {projectInfo.showFinishCourseExamCountFlag === 1 && (
                    <p>
                      练习通过数量：
                      {projectInfo.topicFinishCourseExamCount
                        ? projectInfo.topicFinishCourseExamCount
                        : 0}
                    </p>
                  )}
                </div>
                <div>
                  {projectInfo.showLearnProgressFlag === 1 && (
                    <p style={{ width: "50%" }}>
                      学习进度：
                      {projectInfo.topicProgress}%
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="project_list">
            <div>
              <Menu
                index={navIndex}
                defaultOpenSubMenus={[]}
                mode="horizontal"
                onSelect={(index) => {
                  setNavIndex(index);
                }}
              >
                {navlist.map((it: navListProps, index: number) => (
                  <Menu.Item key={index}>
                    <Icon
                      icon={it.icon}
                      size="1x"
                      theme="dark"
                      style={{ marginRight: "8px" }}
                    />
                    <span>{it.title}</span>
                  </Menu.Item>
                ))}
              </Menu>
              <div className="video_box">
                {navIndex === "0" && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: classInfo?.intro as string,
                    }}
                  ></div>
                )}
                {/* 课程 */}
                {navIndex === "1" && (
                  <>
                    <TopicCourseDoubleCourse
                      projectId={projectId}
                      topicId={topicId}
                      projectInfo={projectInfo}
                    />
                  </>
                )}
                {navIndex === "2" && (
                  <div className="examInfo_box">
                    {hasTopicQuestionnaireFlag === 1 && (
                      <div>
                        <p>项目问卷</p>
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            状态：
                            {QuestionStatus[topicQuestionnaireStatus]}
                          </span>
                          <Button
                            type={
                              topicQuestionnaireStatus === 0 ||
                              topicQuestionnaireStatus === 2
                                ? "ghost"
                                : "primary"
                            }
                            disabled={
                              topicQuestionnaireStatus === 0 ||
                              topicQuestionnaireStatus === 2
                            }
                            onClick={getTopicQuestionnaireUrl}
                          >
                            进入问卷
                          </Button>
                        </p>
                      </div>
                    )}
                    {hasTopicExamFlag === 1 && (
                      <div>
                        <p>{classInfo?.topicExamName}</p>
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            状态：{ExamStatus.get(topicExamStatus.toString())}
                          </span>
                          <Button
                            type="primary"
                            onClick={() => {
                              checkExamFlag(classInfo);
                            }}
                            disabled={
                              topicExamStatus === 0 ||
                              topicExamStatus === 10 ||
                              topicExamStatus === 6 ||
                              topicExamStatus === 7
                            }
                          >
                            进入考试
                          </Button>
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const getProjectType = (state: {
  projectType: { type: number; activityId?: string };
}) => {
  return {
    projectType: state.projectType,
  };
};
export default connect(getProjectType)(TopicInfo);
