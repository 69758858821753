/*
 * @Author: cc
 * @LastEditTime: 2024-04-28 15:21:23
 */
import { useEffect, useState } from "react";
import { Collapse, Modal, message, Button, Table } from "antd";
import no_class from "../../../img/no_class.png";
import { post } from "../../../common/fetch";
import { connect } from "react-redux";
import store from "../../../reducers/index";
import { CourseTrainLog } from "./trainComponent/courseTrainLog";
import { TopicCourseTrainLog } from "./trainComponent/topicCourseTrainLog";
interface trainListProps {
  userId: number;
  instituteId: string;
  projectId: number;
  projectName: string;
  projectDurationText: string;
  projectWatchTimeText: string;
  projectTrainStatusText: string;
  statusText: string;
  projectTimeRange: string;
  projectCreditInfo?: string;
  trainCertificateNumber?: string;
  seeExamRecordFlag: number;
  trainCertificateApplyId: string;
  courseForm?: number; //课程类型
  courseSource: number;
  cmTrainClassId: string;
}
interface currentExamProps {
  projectId: number;
  type: number;
}
const { Panel } = Collapse;
function MyTrain(props: any) {
  const { activityInfo } = props;
  const [isExamModalVisible, setIsExamModalVisible] = useState(false);
  const [trainList, setTrainList] = useState<trainListProps[]>([]);
  const [examList, setExamList] = useState<any>([]);
  const [examTotal, setExamTotal] = useState(1);
  const [examCurrent, setExamCurrent] = useState(1);
  const [currentExam, setCurrentExam] = useState<currentExamProps>({
    projectId: 1,
    type: 1,
  });
  const [projectCourseInfo, setProjectCourseInfo] = useState({
    type: "course",
    projectId: 0,
    show: false,
    cmTrainClassId: "0",
    courseSource: 1,
  });
  const showExamDetail = (id: number, currentType: number) => {
    post(
      `/shop/front/user/project/examList`,
      {
        projectId: id,
        page: {
          currentPage: examCurrent,
          pageSize: 10,
        },
      },
      true
    ).then((res) => {
      const { examInfo } = res.data;
      if (examInfo) {
        setExamList(res.data);
        setExamCurrent(res.data.page.currentPage);
        setExamTotal(res.data.page.totalResultSize);
        showExamModal();
      } else {
        message.info("暂无考试成绩");
      }
    });
  };
  const getUserCourseWatchRecord = () => {
    post(`/shop/front/user/getMyProjectRecordList`, {}, true).then((res) => {
      const { data = [] } = res;
      const list = data.map((item: trainListProps, index: number) => {
        return {
          key: index + 1,
          ...item,
        };
      });
      setTrainList(list);
    });
  };

  const showExamModal = () => {
    setIsExamModalVisible(true);
  };
  const callback = (key: any) => {
    console.log(key);
  };
  useEffect(() => {
    getUserCourseWatchRecord();
  }, []);
  const ProjectList = () => {
    return (
      <div>
        <div className="train_box">
          {trainList.map((item, index) => (
            <div className="train_item" key={index}>
              <p>项目名称：{item.projectName}</p>
              <p>
                <span>项目时间：{item.projectTimeRange}</span>
                <span>项目状态：{item.statusText}</span>
                {item.courseSource === 1 && (
                  <span>项目时长：{item.projectDurationText}</span>
                )}
              </p>
              <p>
                {/* <span>培训状态：{item.projectTrainStatusText}</span> */}
                {item.courseSource === 1 && (
                  <span>学习时长：{item.projectWatchTimeText}</span>
                )}
                {item.projectCreditInfo && (
                  <span>学分：{item.projectCreditInfo}</span>
                )}
              </p>
              {/* <p>
            {item.trainCertificateNumber && (
              <span>
                培训证书：
                <a
                  className="downloadCerImg"
                  onClick={() => {
                    getCertificateUrl(item);
                  }}
                >
                  {item.trainCertificateNumber}
                </a>
              </span>
            )}
          </p> */}
              <span className="train_group">
                <Button
                  type="primary"
                  onClick={() => {
                    // showCourseDetail(item.projectId);
                    setProjectCourseInfo({
                      projectId: item.projectId,
                      cmTrainClassId: item.cmTrainClassId,
                      courseSource: item.courseSource,
                      type: item.courseForm === 2 ? "topicCourse" : "course",
                      show: true,
                    });
                  }}
                >
                  查看日志
                </Button>
                {item.seeExamRecordFlag === 1 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      let type = activityInfo.length ? 2 : 1;
                      store.dispatch({
                        type: "CHANGE_PROJECTTYPE",
                        payLoad: {
                          type: 2,
                          activityId: "",
                        },
                      });
                      setCurrentExam({ projectId: item.projectId, type: type });
                      showExamDetail(item.projectId, type);
                    }}
                  >
                    查看成绩
                  </Button>
                )}
              </span>
            </div>
          ))}
          {!trainList.length && (
            <div className="no_class">
              <img src={no_class} alt="暂无数据" />
              <p>暂无数据</p>
            </div>
          )}
        </div>
        {/* 课程 */}
        {/* {projectCourseInfo.show && projectCourseInfo.type === "course" && (
      <CourseTrainLog
        id={projectCourseInfo.projectId}
        setProjectCourseInfo={setProjectCourseInfo}
      />
    )} */}
        {/* 专题课程 */}
        {/* {projectCourseInfo.show && projectCourseInfo.type === "topicCourse" && (
      <TopicCourseTrainLog
        id={projectCourseInfo.projectId}
        setProjectCourseInfo={setProjectCourseInfo}
      />
    )} */}
      </div>
    );
  };
  const ExapList = () => {
    const getExamNote = (userPaperScoreId: number) => {
      const { REACT_APP_BASE_URL, REACT_APP_EXAM_URL } = process.env;
      const examResultUrl = `${REACT_APP_BASE_URL}${REACT_APP_EXAM_URL}/record-list?userPaperScoreId=${userPaperScoreId}&type=projectExam`;
      window.open(examResultUrl);
    };
    const [current, setCurrent] = useState(1);
    const [total, setTotal] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const columns = [
      {
        title: "项目/课程名称",
        dataIndex: "projectName",
        render: (_: any, record: any) => {
          switch (record.examTargetType) {
            case 1:
              return <span>{record.projectName}</span>;
            case 2:
              return <span>{record.courseName}</span>;
            case 6:
              return <span>{record.courseName}</span>;
            case 5:
              return <span>{record.topicName}</span>;
            default:
              return <span></span>;
          }
        },
      },
      {
        title: "类型",
        dataIndex: "examTargetTypeName",
        key: "examTargetTypeName",
      },
      {
        title: "试卷名称",
        dataIndex: "examPaperName",
        key: "examPaperName",
      },
      {
        title: "交卷次数",
        dataIndex: "examCount",
        key: "examCount",
      },
      {
        title: "最高成绩",
        dataIndex: "maxScore",
        key: "maxScore",
      },
      {
        title: "操作",
        dataIndex: "action",
        key: "action",
        render: (_: any, record: any) => {
          if (
            record.projectAnswerRecordShowFlag === 1 &&
            record.examSystemType === 2
          ) {
            return (
              <a
                style={{ color: "#2a82e4" }}
                onClick={() => getExamNote(record.userPaperScoreId)}
              >
                最新答题记录
              </a>
            );
          } else {
            return null;
          }
        },
      },
    ];
    return (
      <div style={{ background: "#fff", padding: "20px" }}>
        <h5>
          查看成绩
          <span
            style={{ float: "right", cursor: "pointer", color: "#2a82e4" }}
            onClick={() => {
              setIsExamModalVisible(false);
            }}
          >
            返回
          </span>
        </h5>
        <div style={{ padding: "10px 0" }}>
          {examList.projectMaxScoreText && (
            <span>项目考试最高分：{examList.projectMaxScoreText}</span>
          )}
          {examList.courseSumScoreText && (
            <span style={{ marginLeft: "40px" }}>
              课程练习最高分合计：{examList.courseSumScoreText}{" "}
            </span>
          )}
        </div>
        <Table
          columns={columns}
          dataSource={examList.examInfo}
          pagination={{
            current: examCurrent,
            total: examTotal,
            showSizeChanger: false,
            pageSize: 10,
            onChange: (current, pageSize) => {
              setCurrent(current);
              setPageSize(pageSize || 1);
              post(
                `/shop/front/user/project/examList`,
                {
                  projectId: currentExam.projectId,
                  type: currentExam.type,
                  page: {
                    currentPage: current,
                    pageSize: 10,
                  },
                },
                true
              ).then((res) => {
                const { examInfo } = res.data;
                if (examInfo) {
                  setExamList(res.data);
                  setExamCurrent(res.data.page.currentPage);
                  setExamTotal(res.data.page.totalResultSize);
                  showExamModal();
                } else {
                  message.info("暂无考试成绩");
                }
              });
            },
          }}
        ></Table>
        {/* <Collapse defaultActiveKey={["1"]} onChange={callback}>
        <Panel header={examList.projectName} key={1}>
          {examList.examInfo &&
            examList.examInfo.map((it: any) => (
              <p
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span
                  style={{
                    width: "220px",
                  }}
                >
                  试卷名称：{it.examPaperName}
                </span>
                &emsp;
                <span
                  style={{
                    width: "220px",
                  }}
                >
                  考试时间：{it.beginTime}
                </span>
                &emsp;
                <span
                  style={{
                    width: "100px",
                  }}
                >
                  成绩：{it.score}
                </span>
                &emsp;
                <span
                  style={{
                    width: "100px",
                  }}
                >
                  {it.projectAnswerRecordShowFlag === 1 &&
                    it.examSystemType === 2 && (
                      <p className="exam_Note_box">
                        <Button
                          type="primary"
                          onClick={() => {
                            getExamNote(it.userPaperScoreId);
                          }}
                        >
                          记录
                        </Button>
                      </p>
                    )}
                </span>
              </p>
            ))}
        </Panel>
      </Collapse> */}
      </div>
      // </Modal>
    );
  };
  return (
    <div>
      {!isExamModalVisible && !projectCourseInfo.show && <ProjectList />}
      {projectCourseInfo.show && projectCourseInfo.type === "course" && (
        <CourseTrainLog
          id={projectCourseInfo.projectId}
          {...projectCourseInfo}
          setProjectCourseInfo={setProjectCourseInfo}
        />
      )}
      {projectCourseInfo.show && projectCourseInfo.type === "topicCourse" && (
        <TopicCourseTrainLog
          id={projectCourseInfo.projectId}
          setProjectCourseInfo={setProjectCourseInfo}
        />
      )}
      {isExamModalVisible && <ExapList />}
    </div>
  );
}
const getProjectType = (state: {
  projectType: { type: number; activityId?: string };
  activityInfo: Array<any>;
}) => {
  return {
    projectType: state.projectType,
    activityInfo: state.activityInfo,
  };
};
export default connect(getProjectType)(MyTrain);
