/*
 * @Author: cc
 * @LastEditTime: 2021-06-02 16:34:23
 */
import { useEffect, useState } from "react";
import { Button } from "../index";
type TimeProps = {
  time: number;
  sureClick?: () => void;
};
interface CountDownInterface extends React.FC<TimeProps> {}
let setTimer: any;
const CountDown: CountDownInterface = (props) => {
  const { sureClick } = props;
  const [countDown, setCountDown] = useState({
    minute: Math.floor(props.time / 60),
    second: props.time % 60,
  });
  // 阅读标识
  const [flag, setFlag] = useState(0);
  const getTime = (minute: number, second: number) => {
    console.log(second, minute);
    if (second <= 0 && minute <= 0) {
      setCountDown({
        minute: 0,
        second: 0,
      });
      clearTimeout(setTimer);
      setFlag(1);
    } else {
      setCountDown({
        minute: second === 0 ? --minute : minute,
        second: second === 0 ? 59 : --second,
      });
    }
  };
  const transFromTime = (time: number) => {
    return time < 10 ? `0${time}` : time;
  };
  useEffect(() => {
    setTimer = setTimeout(() => {
      const { minute, second } = countDown;
      getTime(minute, second);
    }, 1000);
  }, [countDown]);
  return (
    <>
      <div className="countDown_box">
        {flag === 0 && (
          <span>
            阅读倒计时：{transFromTime(countDown.minute)}:
            {transFromTime(countDown.second)}
          </span>
        )}
        {flag === 1 && (
          <Button
            btnType="primary"
            size="sm"
            onClick={() => {
              sureClick!();
              setFlag(2);
            }}
          >
            我已阅读以上须知
          </Button>
        )}
      </div>
    </>
  );
};
export default CountDown;
