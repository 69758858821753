/*
 * @Author: cc
 * @LastEditTime: 2023-11-15 13:13:29
 */
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { Result, Button } from "antd";
import { HistoryPush } from "../../common/utils";
import { post, get } from "../../common/fetch";
const Error = () => {
    let { errcode = 0 } = useParams<any>()
    function getTitle() {
        let title = ""
        console.log(errcode)
        switch (errcode) {
            case "601" :
                title = "该用户不存在";
                break;
            case "602" :
                title = "登录失败";
                break;
            case "603" :
                title = "一体化返回错误";
                break;
            case "604" :
                title = "参数不能为空";
                break;
            case "605" :
                title = "机构不匹配";
                break;
            case "606" :
                title = "该机构不存在";
                break;
            case "607" :
                title = "机构未开通该产品";
                break;
            case "608" :
                title = "其它错误";
                break;
            case "609" :
                title = "此机构不能自动跳转";
                break;
            default :
                title = "其它错误"
                break; 
        }
        return title
    }
    useEffect(() => {
        console.log(errcode)
    }, []);
    return (
        <div>
            <Result
                status="error"
                title={getTitle()}
                subTitle="如果存在问题请联系相关负责人"
                extra={[
                    <Button type="primary" key="console"><a href="javascript:window.opener=null;window.open('','_self');window.close();">关闭</a></Button>,
                ]}
            >
            </Result>,
        </div>
    );
};
export default Error;
