/*
 * @Author: changcheng
 * @LastEditTime: 2023-10-26 15:10:31
 */
import { Button, Radio, Modal, Table, message } from "antd";
import { useState } from "react";
import wechatIcon from "../../../img/weChatPay.png";
import aliIcon from "../../../img/aliPay.png";
import { post } from "../../../common/fetch";
import { useHistory } from "react-router-dom";
import { Base64 } from "js-base64";
import { useQuery } from "../../../common/utils";
export interface projectListInfo {
  // 课程名称
  title: string;
  // 主讲人
  teacher: string;
  // 单位
  employer: string;
  // 学时
  period: number;
  // 背景图
  cover: string;
  // 课程ID
  id: string;
}
const columns = [
  {
    title: "课程",
    dataIndex: "title",
    width: "200px",
  },
  {
    title: "学时",
    dataIndex: "period",
  },
  {
    title: "主讲人",
    dataIndex: "teacher",
  },
  {
    title: "课程费用",
    dataIndex: "salePriceText",
  },
];
export default function SelectCourse(props: {
  canBuyProjectList: projectListInfo[];
  classId: string;
  className: string;
  showSelectModal: boolean;
  setShowSelectModal: (show: boolean) => void;
}) {
  const query = useQuery();
  const history = useHistory();
  const courseId = query.get("courseId") as string;
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [paymentType, setPayMentType] = useState("wechat");
  const {
    canBuyProjectList,
    classId,
    className,
    showSelectModal,
    setShowSelectModal,
  } = props;
  const [childCourseId, setChildCourseId] = useState([] as any[]);
  const [trainProjectList, setTrainProjectList] = useState<any[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    let childId: any[] = [];
    const totalPeriod = Number(
      newSelectedRowKeys.reduce((prev, current) => {
        childId.push(canBuyProjectList[current].id);
        return prev + canBuyProjectList[current].salePrice;
      }, 0)
    );
    setChildCourseId(childId);
    setTrainProjectList(
      newSelectedRowKeys.map((key) => {
        const { period, title, id } = canBuyProjectList[key];
        return {
          amount: period,
          projectName: title,
          projectId: id,
        };
      })
    );
    setSelectedRowKeys(newSelectedRowKeys);
    setTotalPrice(totalPeriod);
  };
  const projectList = canBuyProjectList.map((item, index) => {
    return { key: index, ...item };
  });
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  /**
   * 支付订单
   * @param values 表单字段
   */
  const confirmOrderInfo = async () => {
    if (paymentType === "wechat") {
      wechatPay();
    } else {
      aliPay();
    }
  };
  /**
   * 微信支付
   * @param values
   */
  const wechatPay = async () => {
    const {
      data: { weChatPayQrCode, tradeNo },
    } = await post(
      "/shop/front/cm/pc/project/create/weChat/pay/order",
      {
        classId,
        className,
        totalAmount: totalPrice,
        trainProjectList,
      },
      true
    );
    setShowSelectModal(false);
    history.push(
      `/wechatPay?amount=${totalPrice}&payCode=${Base64.encode(
        weChatPayQrCode
      )}&tradeNo=${tradeNo}`
    );
  };
  /**
   * 阿里支付
   * @param values
   */
  const aliPay = async () => {
    const {
      data: { orderNo },
    } = await post(
      "/shop/front/cm/pc/project/create/ali/pay/order",
      {
        classId,
        className,
        totalAmount: totalPrice,
        trainProjectList,
      },
      false
    );
    setShowSelectModal(false);
    const returnUrl = encodeURIComponent(`${window.location.href}`);
    window.location.href = `https://api.imed.org.cn/order/createAliPayOrder?payType=ALI_PAY&tradeNo=${orderNo}&returnUrl=${returnUrl}&tradeType=NATIVE`;
  };
  /**
   * 支付免费订单
   */
  const payFreeCourse = async () => {
    await post(
      `/shop/front/cm/train/class/project/free/join`,
      {
        partnerClassId: courseId,
        projectIdList: childCourseId,
      },
      false
    );
    setShowSelectModal(false);
    window.location.reload();
  };
  return (
    <Modal
      visible={showSelectModal}
      title="选择课程"
      footer={null}
      onCancel={() => {
        setShowSelectModal(false);
      }}
    >
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={projectList}
      />
      <p
        style={{
          textAlign: "center",
        }}
      >
        <h6>订单金额:{totalPrice}</h6>
        <p>
          {totalPrice > 0 && (
            <Radio.Group
              value={paymentType}
              onChange={(e) => {
                setPayMentType(e.target.value);
              }}
            >
              <Radio value="wechat">
                <img src={wechatIcon} alt="" className="payIcon" />
              </Radio>
              <Radio value="ali">
                <img src={aliIcon} alt="" className="payIcon" />
              </Radio>
            </Radio.Group>
          )}
        </p>
        <Button
          type="primary"
          onClick={() => {
            if (!trainProjectList.length) {
              message.info("请选择课程");
              return;
            }
            if (totalPrice) {
              confirmOrderInfo();
            } else {
              payFreeCourse();
            }
          }}
        >
          确认
        </Button>
      </p>
    </Modal>
  );
}
