/*
 * @Author: cc
 * @LastEditTime: 2022-07-14 14:15:35
 */
import { memo, useState } from "react";
import "./_style.scss";
import { HistoryPush } from "../../common/utils";
import { Icon } from "../../components/index";
import { post } from "../../common/fetch";
import {
  TrainListProps,
  CourseListProps,
  PublicListProps,
  LiveLisrProps,
  ActivityInfoProps,
  newCouseListProps,
} from "../../pages/tarin/train";
import no_class from "../../img/no_class.png";
import { message, Modal, Radio, Space } from "antd";
import store from "../../reducers/index";
interface typeProps {
  type: string;
}
type ProjectProps = Partial<
  TrainListProps &
  CourseListProps &
  PublicListProps &
  LiveLisrProps &
  typeProps &
  ActivityInfoProps &
  newCouseListProps
>;
interface needSelectInstituteInfoProps {
  selectInstituteId?: number; // 选中的机构Id
  defaultValue?: number; // 机构Id默认值
  status?: number; //选择机构Id弹窗状态
  projectId: number; // 保存项目Id
  instituteList?: Array<{
    // 处理选择机构列表
    label: string;
    value: number;
  }>;
}
const ProjectList = memo(
  (props: { classList: ProjectProps[]; type?: string }) => {
    let history = HistoryPush();
    const { classList, type } = props;
    const [needSelectInstituteInfo, setNeedSelectInstituteInfo] =
      useState<needSelectInstituteInfoProps>({
        status: 0,
        instituteList: [],
        projectId: 0,
      });
    const getLiveRoomUrl = (item: ProjectProps) => {
      const { liveRoomId, ccUserId } = item;
      const url = `/shop/ccLiveFunction/getUserProjectLiveUrl?roomId=${liveRoomId}&ccUserId=${ccUserId}`;
      window.open(url);
    };
    const saveSelectInstituteId = () => {
      post(
        "/shop/front/activity/saveActivityInstitute",
        {
          id:
            needSelectInstituteInfo.selectInstituteId ||
            needSelectInstituteInfo.defaultValue,
        },
        true
      ).then((res) => {
        history.push(
          `/projectInfo?projectId=${needSelectInstituteInfo.projectId
          }&projectType=${"2"}`
        );
      });
    };
    return (
      <div className="train_container">
        {classList.map((item, index) => {
          return (
            <div
              className="class_box"
              key={index}
              onClick={async () => {
                // 直播课程
                if (item.liveRoomId) {
                  getLiveRoomUrl(item);
                } else if (item.projectId) {
                  // 判断是否开始
                  if (item.studyStatus !== 1) {
                    message.info("项目未开始");
                  } else {
                    store.dispatch({
                      type: "CHANGE_PROJECTTYPE",
                      payLoad: {
                        type: item.projectType,
                        activityId: item.activityId,
                      },
                    });
                    if (item.needSelectInstituteFlag === 1) {
                      // 需要选择机构
                      setNeedSelectInstituteInfo({
                        status: item.needSelectInstituteFlag,
                        instituteList:
                          item.activityHumanInstituteList &&
                          item.activityHumanInstituteList.map((it) => {
                            return {
                              label: it.instituteName,
                              value: it.id,
                            };
                          }),
                        projectId: item.projectId,
                        defaultValue:
                          item.activityHumanInstituteList &&
                          item.activityHumanInstituteList[0].id,
                      });
                    } else {
                      const data = await post(
                        "/shop/front/project/isCollectProjectUserExtendInfo",
                        { projectId: item.projectId },
                        true
                      );
                      if (data.data === "Y" && item.projectType === 1) {
                        history.push(
                          `/collectionInfo?projectId=${item.projectId}&projectType=${item.projectType}`
                        );
                      } else {
                        if (item.courseSource === 2) {
                          history.push(
                            `/cePayProjectInfo?projectId=${item.projectId}&projectType=${item.projectType}`
                          );
                        }else{
                          history.push(
                            `/projectInfo?projectId=${item.projectId}&projectType=${item.projectType}`
                          );
                        }
                      }
                    }
                  }
                } else {
                  history.push(`/play?courseId=${item.courseId}&type=${type}`);
                }
              }}
            >
              <img
                src={
                  item.courseAllPathPicture ||
                  item.coursePicture ||
                  item.projectPicture ||
                  item.courseLogo
                }
                alt=""
              />
              <div>
                {type === "live" && (
                  <p className="c-ccc fs12" style={{ marginTop: "10px" }}>
                    直播时间：{item.liveStartTimeStr}
                  </p>
                )}
                <p>
                  {(type === "myself" || type === "public") && (
                    <>
                      <span>
                        <Icon icon="clock" size="1x" theme="gray" />
                        &nbsp;课件:
                        {item.courseVideoNum ? item.courseVideoNum : 0}
                      </span>
                      {item.startTimeText && (
                        <span className="time">
                          {item.startTimeText}-{item.endTimeText}
                        </span>
                      )}
                      {item.courseDuration && item.courseDuration !== 0 ? (
                        <span>时长: {item.courseDurationText}</span>
                      ) : null}
                    </>
                  )}
                  {item.studyStatusText && (
                    <span
                      className={
                        item.studyStatus === 1 ? "started" : "notStarted"
                      }
                    >
                      {item.studyStatusText}
                    </span>
                  )}
                  {item.startTimeText && (
                    <span className="time">
                      {item.startTimeText}-{item.endTimeText}
                    </span>
                  )}
                </p>
                <p className="project-box" style={{ fontWeight: "bolder" }}>
                  {item.courseName || item.projectName}
                </p>
                {type === "live" && (
                  <p className="c-ccc fs12">{item.teacherNames}</p>
                )}
              </div>
            </div>
          );
        })}
        <Modal
          visible={!!needSelectInstituteInfo.status}
          title={"请选择机构"}
          onOk={saveSelectInstituteId}
          onCancel={() => {
            setNeedSelectInstituteInfo({
              ...needSelectInstituteInfo,
              status: 0,
            });
          }}
        >
          <Radio.Group
            onChange={(e) => {
              const { value } = e.target;
              setNeedSelectInstituteInfo({
                selectInstituteId: value,
                ...needSelectInstituteInfo,
              });
            }}
            defaultValue={needSelectInstituteInfo.defaultValue}
          >
            <Space direction="vertical">
              {needSelectInstituteInfo.instituteList &&
                needSelectInstituteInfo.instituteList.map((item) => (
                  <Radio key={item.value} value={item.value}>
                    {item.label}
                  </Radio>
                ))}
            </Space>
          </Radio.Group>
        </Modal>
        {!classList && (
          <div className="no_class">
            <img src={no_class} alt="暂无数据" />
            <p>暂无数据</p>
          </div>
        )}
      </div>
    );
  }
);
export default ProjectList;
