/*
 * @Author: cc
 * @LastEditTime: 2023-12-26 11:18:53
 */
import { memo, useState } from "react";
import "./_style.scss";
import { post } from "../../common/fetch";
import { platFormProps } from "../../pages/tarin/train";
import no_class from "../../img/no_class.png";
import { Button } from "antd";
import { UserInfoConfirm, UserOrderConfirm } from "../index";
import { FinishCourse } from "../../components/index";
import { useHistory } from "react-router-dom";
const PlatformResource = memo(
  (props: {
    classList: platFormProps[];
    type?: string;
    getPlatFromList: () => void;
  }) => {
    const [showUserConfirmModal, setShowUserConfirmModal] = useState(false);
    const [showOrderModal, setShowOrderModal] = useState(false);
    const [courseInfo, setCourseInfo] = useState<any>();
    const { classList, getPlatFromList } = props;
    const history = useHistory();
    /**
     * 检查用户信息是否完善
     */
    const checkUserInfo = async (item: platFormProps) => {
      const { code } = await post(
        "/shop/front/cm/train/user/info/check",
        {},
        false
      );
      if (code === 2000) {
        setShowUserConfirmModal(true);
      } else {
        setShowOrderModal(true);
        setCourseInfo(item);
      }
    };
    /**
     * 参加免费课程
     */
    const joinFreeCourse = async (item: platFormProps) => {
      const { code } = await post(
        "/shop/front/cm/train/user/info/check",
        {},
        false
      );
      if (code === 2000) {
        setShowUserConfirmModal(true);
      } else {
        const { id } = item;
        await post(`/shop/front/cm/train/class/${id}/free/join`, {}, false);
        getPlatFromList();
      }
    };
    return (
      <div className="platform_train_container">
        {classList.map((item, index) => {
          return (
            <div className="class_box" key={index}>
              <h5>{item.name}</h5>
              <div>
                <p className="project-box">
                  必修达标时长：{item.requiredPeriod}学时
                </p>
                <p className="project-box">
                  选修达标时长：{item.notRequiredPeriod}学时
                </p>
                <p className="project-box">
                  证书情况：{item.certType ? "有" : "无"}
                </p>
                <p className="project-box">培训费用：{item.salePriceText}</p>
                {item.amount !== 0 && item.buyClassFlag === 0 && (
                  <p>
                    <Button
                      danger
                      type="primary"
                      block
                      onClick={() => {
                        checkUserInfo(item);
                      }}
                      size="large"
                    >
                      购买
                    </Button>
                    <Button
                      block
                      type="primary"
                      onClick={() => {
                        history.push(`/payProjectInfo?courseId=${item.id}`);
                      }}
                      size="large"
                    >
                      查看
                    </Button>
                  </p>
                )}
                {item.amount === 0 && item.buyClassFlag === 0 && (
                  <p>
                    <Button
                      danger
                      type="primary"
                      block
                      onClick={() => {
                        joinFreeCourse(item);
                      }}
                      size="large"
                    >
                      获取
                    </Button>
                    <Button
                      block
                      type="primary"
                      onClick={() => {
                        history.push(`/payProjectInfo?courseId=${item.id}`);
                      }}
                      size="large"
                    >
                      查看
                    </Button>
                  </p>
                )}
              </div>
              {item.buyClassFlag === 1 && (
                <p>
                  <Button
                    type="primary"
                    block
                    onClick={() => {
                      history.push(`/payProjectInfo?courseId=${item.id}`);
                    }}
                    size="large"
                  >
                    进入学习
                  </Button>
                </p>
              )}
              {item.buyClassFlag === 1 && (
                <div className="finish_course">
                  <FinishCourse text={"已拥有"} />
                </div>
              )}
            </div>
          );
        })}
        {!classList && (
          <div className="no_class">
            <img src={no_class} alt="暂无数据" />
            <p>暂无数据</p>
          </div>
        )}
        <UserInfoConfirm
          showUserConfirmModal={showUserConfirmModal}
          setShowUserConfirmModal={setShowUserConfirmModal}
        />
        <UserOrderConfirm
          showOrderModal={showOrderModal}
          setShowOrderModal={setShowOrderModal}
          courseInfo={courseInfo}
        />
      </div>
    );
  }
);
export default PlatformResource;
