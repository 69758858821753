/*
 * @Author: cc
 * @LastEditTime: 2023-10-11 15:17:44
 */
import { LeftOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import "./play.scss";
import { useQuery, HistoryPush } from "../../common/utils";
import { post } from "../../common/fetch";
import { VideoProps } from "../../reducers/video";
import { connect } from "react-redux";
// import like from "../../img/like.png";
// import cancalLike from "../../img/cancelLike.png";
import { Modal, Input, Button, message, Space, Tooltip } from "antd";
import { ranDomNum } from "../../common/utils";
import { FilePdfOutlined, YoutubeOutlined } from "@ant-design/icons";
import { Icon } from "../../components/index";
import { useHistory } from "react-router";
import moment from "moment";
import {
  CCvideoControlInstance,
  AlivideoControlInstance,
} from "../../common/video";
// import { watch, preventOpenMoreVideoFn } from "../../common/utils";
interface ListPropsType {
  id: number;
  name: string;
  showName: string;
  videoWatchProgress: string;
  durationText: string;
  type?: 2 | 4; // 2:视频课件 4:pdf
}
interface videoInfoProps {
  content: string;
  photo: string;
  name: string;
  title: string;
  institution: string;
}
interface sectionListProps {
  courseName: string;
  courseContent: string;
  progress?: string;
  userPraiseFlag: boolean;
  courseWatchTimeText?: string;
  list: ListPropsType[];
  hasExamFlag?: number;
  hasQuestionnaireFlag?: number;
  examSystemType?: number;
  evaluateFormId?: number;
}
interface videoWaterMaskProps {
  fixedPositionType: number;
  id: number;
  imageSize: string;
  imageUrl: string;
  projectId: number;
  showTimeType: number;
  showType: number;
  type: number;
  fixedText: string;
  textFontColor: string;
  textFontSize: number;
  intervalTime: number;
  rollType: number;
  imageUrlFullPath: string;
}
const { TextArea } = Input;
let siteid: number;
const { REACT_APP_PDF_DOMAIN, REACT_APP_PDF_URL } = process.env;
// 默认讲师头像图片
const teacherDefaultAvator =
  "https://shiziimg.mvwchina.com/20200106/teacher/aZeZnG4rWtjtzYcmT2BZ.jpg";
window.ccPlayerStatus = null;
function Play(props: any) {
  const history = HistoryPush();
  const routeHistory = useHistory();
  const { setVideoStatus, videoInfo, projectType } = props;
  const [content, setContent] = useState("");
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const livePlayerRef = useRef<any>();
  let [currentSectionInfo, setCurrentSectionInfo] = useState({
    type: "video",
    index: 0,
    sectionId: 0,
  });
  // 视频类型 1:cc 3:阿里云
  const [videoSource, setVideoSource] = useState<"" | number>(3);
  const [videoTeacherInfoList, setVideoTeacherInfoList] =
    useState<videoInfoProps>({} as videoInfoProps);
  const [sectionList, setSectionList] = useState<sectionListProps>(
    {} as sectionListProps
  );
  const [livePlayer, setLivePlayer] = useState<any>(null);

  let query = useQuery();
  const topicId = query.get("topicId");
  const projectId = query.get("projectId");
  const courseId = query.get("courseId");
  let sectionId = query.get("sectionId");
  const { hasQuestionnaireFlag, hasExamFlag, examSystemType } = sectionList;
  // 判断当前是否是点播课程
  const type = query.get("type");
  const isMySelf = type === "myself";
  const initVideo = (
    vid: string,
    siteid: number,
    progressbar_enable: number,
    watchStartTime: number,
    rate_allow_change: number,
    videoWaterMask: videoWaterMaskProps
  ) => {
    if (window.createCCH5Player) {
      const { REACT_APP_NODE_ENV } = process.env;
      if (videoWaterMask) {
        let videoWaterInfo: any = {
          loop: "-1",
          type: videoWaterMask.type === 1 ? "text" : "image",
          action: [
            {
              index: "0",
              duration: "10000",
              start: {
                alpha: "0.8",
              },
              end: {
                alpha: "0.8",
              },
            },
            {
              index: "1",
              duration: "3000",
              start: {
                xpos: "0.1",
                ypos: "0.1",
                alpha: "0",
              },
              end: {
                xpos: "1",
                ypos: "1",
                alpha: "0",
              },
            },
          ],
        };
        if (videoWaterMask.showType === 1) {
          if (videoWaterMask.showTimeType === 1) {
            videoWaterInfo.action.pop();
          } else {
            videoWaterInfo.action[1].duration =
              videoWaterMask.intervalTime * 1000 + "";
          }
          if (videoWaterMask.fixedPositionType === 2) {
            videoWaterInfo.action[0].start.xpos = "0.8";
            videoWaterInfo.action[0].start.ypos = "0.01";
            videoWaterInfo.action[0].end.xpos = "0.8";
            videoWaterInfo.action[0].end.ypos = "0.01";
          } else {
            videoWaterInfo.action[0].start.xpos = "0.05";
            videoWaterInfo.action[0].start.ypos = "0.01";
            videoWaterInfo.action[0].end.xpos = "0.05";
            videoWaterInfo.action[0].end.ypos = "0.01";
          }
        } else {
          if (videoWaterMask.rollType === 2) {
            videoWaterInfo.action[0].start.xpos = "0.8";
            videoWaterInfo.action[0].start.ypos = "0.01";
            videoWaterInfo.action[0].end.xpos = "0.05";
            videoWaterInfo.action[0].end.ypos = "0.8";
          } else {
            videoWaterInfo.action[0].start.xpos = "0.05";
            videoWaterInfo.action[0].start.ypos = "0.01";
            videoWaterInfo.action[0].end.xpos = "0.8";
            videoWaterInfo.action[0].end.ypos = "0.8";
          }
          if (videoWaterMask.showTimeType === 1) {
            videoWaterInfo.action.pop();
          } else {
            videoWaterInfo.action[1].duration =
              videoWaterMask.intervalTime * 1000 + "";
          }
        }
        if (videoWaterMask.type === 1) {
          videoWaterInfo.text = {
            content: videoWaterMask.fixedText,
            font_size: videoWaterMask.textFontSize,
            color: videoWaterMask.textFontColor,
          };
          window.ccPlayerStatus = window.createCCH5Player({
            vid,
            siteid,
            autoStart: false,
            width: "100%",
            rate: 1,
            height: "100%",
            banDrag: "false",
            playtype: 1,
            forward: 0, //左右方向键控制快进退步长
            rate_allow_change: isMySelf
              ? true
              : rate_allow_change
              ? true
              : false,
            // 是否可以拖动
            progressbar_enable: isMySelf ? true : progressbar_enable,
            // 初始化观看时间
            watchStartTime,
            parentNode: document.getElementById("livePlayer"),
            marquee: JSON.stringify(videoWaterInfo),
            isShowUrlBtn: false,
            forceWasm: "true",
          });
        } else {
          let image = new Image();
          image.src = videoWaterMask.imageUrlFullPath;
          console.log(videoWaterMask.imageUrlFullPath);
          image.onload = () => {
            videoWaterInfo.image = {
              image_url: videoWaterMask.imageUrlFullPath,
              width: image.width,
              height: image.height,
            };
            window.ccPlayerStatus = window.createCCH5Player({
              vid,
              siteid,
              autoStart: false,
              width: "100%",
              rate: 1,
              height: "100%",
              banDrag: "false",
              playtype: 1,
              forward: 0, //左右方向键控制快进退步长
              rate_allow_change: isMySelf
                ? true
                : rate_allow_change
                ? true
                : false,
              // 是否可以拖动
              progressbar_enable: isMySelf ? true : progressbar_enable,
              // 初始化观看时间
              watchStartTime,
              parentNode: document.getElementById("livePlayer"),
              marquee: JSON.stringify(videoWaterInfo),
              isShowUrlBtn: false,
              forceWasm:"true",
            });
          };
        }
      } else {
        window.ccPlayerStatus = window.createCCH5Player({
          vid,
          siteid,
          autoStart: false,
          width: "100%",
          rate: 1,
          height: "100%",
          banDrag: "false",
          playtype: 1,
          forward: 0, //左右方向键控制快进退步长
          rate_allow_change: isMySelf ? true : rate_allow_change ? true : false,
          // 是否可以拖动
          progressbar_enable: isMySelf ? true : progressbar_enable,
          // 初始化观看时间
          watchStartTime,
          parentNode: document.getElementById("livePlayer"),
          isShowUrlBtn: false,
          forceWasm:"true",
        });
      }
    }
  };
  //推荐课程查询
  const getSectionInfo = () => {
    let url = "";
    if (type === "topic") {
      url = "/shop/front/project/topic/section/Info";
    } else {
      url = `/shop/front/project/section/Info`;
    }
    post(
      url,
      {
        projectId,
        courseId,
        sectionId,
        topicId,
        videoType: "mySelf",
      },
      false
    ).then((res) => {
      const new_time = new Date().getTime() + "";
      setResultInfo({ ...res.data, videoUniqueCode: new_time });
    });
  };
  // 点播课程
  const getMyself = () => {
    post(
      `/shop/front/institute/course/learn`,
      {
        courseId,
      },
      false
    ).then((res) => {
      const new_time = new Date().getTime() + "";
      setResultInfo({ ...res.data, videoUniqueCode: new_time });
    });
  };
  // 公益课程
  const getPublic = () => {
    post(
      `/shop/front/myself/course/learn`,
      {
        courseId,
      },
      false
    ).then((res) => {
      const new_time = new Date().getTime() + "";
      setResultInfo({ ...res.data, videoUniqueCode: new_time });
    });
  };
  // 设置视频缓存数据
  const setResultInfo = (res: any) => {
    setVideoInfo(res);
  };
  // 设置视频参数等
  const setVideoInfo = (data: any) => {
    const { index } = currentSectionInfo;
    const {
      courseInfo,
      projectWatchRule,
      courseTeacherList,
      videoUniqueCode,
      videoWaterMask,
    } = data;

    const {
      sectionList,
      courseName,
      courseWatchTimeText,
      progress,
      courseContent,
      userPraiseFlag,
      hasQuestionnaireFlag,
      examSystemType,
      hasExamFlag,
      evaluateFormId,
    } = courseInfo;
    const {
      videoUrl,
      videoWatchAt,
      courseId,
      videoWatchProgress,
      type,
      id,
      videoSource,
      videoWatchTime,
      ccUserId,
      duration,
    } = courseInfo.sectionList[index];
    siteid = projectId === "1154" ? "AA247F3728571B49" : ccUserId;
    if (type === 4) {
      setSectionList({
        courseName,
        evaluateFormId,
        userPraiseFlag: userPraiseFlag === 0 ? false : true,
        courseContent,
        list: sectionList,
      });
      setCurrentSectionInfo({
        ...currentSectionInfo,
        sectionId: id,
        type: "pdf",
      });
      setTimeout(() => {
        // livePlayerRef.current.src = getPdfUrlBySectionId(id);
        // livePlayerRef.current.style.width = "100%";
        setLivePlayer(getPdfUrlBySectionId(id));
      });
      return;
    }
    setCurrentSectionInfo({
      ...currentSectionInfo,
      type: "video",
    });
    let canDragFlag = 0;
    let fastForwardFlag = 0;
    // 是否允许拖拽
    if (projectWatchRule) {
      // 课程学满可拖动
      if (projectWatchRule.canDragFlag === 3 && videoWatchProgress >= 100) {
        canDragFlag = 1;
      } else if (projectWatchRule.canDragFlag === 2) {
        canDragFlag = 1;
      } else {
        canDragFlag = 0;
      }
      if (projectWatchRule.fastForwardFlag === 3 && videoWatchProgress >= 100) {
        fastForwardFlag = 1;
      } else if (projectWatchRule.fastForwardFlag === 2) {
        fastForwardFlag = 1;
      } else {
        fastForwardFlag = 0;
      }
    }

    setSectionList({
      userPraiseFlag: userPraiseFlag === 0 ? false : true,
      courseName,
      courseContent,
      courseWatchTimeText,
      progress,
      list: sectionList,
      hasQuestionnaireFlag,
      examSystemType,
      hasExamFlag,
      evaluateFormId,
    });
    // 获取讲师信息
    if (courseTeacherList && courseTeacherList[0]) {
      setVideoTeacherInfoList(courseTeacherList[0]);
    } else {
      setVideoTeacherInfoList({} as any);
    }
    setVideoStatus({
      type: "SET_VIDEO_STATUS",
      vid: videoUrl,
      siteid,
      videoUniqueCode,
      progressbar_enable: canDragFlag,
      rate_allow_change: fastForwardFlag,
      watchStartTime: videoWatchAt,
      projectId: Number(projectId),
      courseId,
      forward: 0, //左右方向键控制快进退步长
      sectionId: id,
      videoType: query.get("type"),
      topicId,
      //是否防挂机 0 不弹出 1.定时弹出 2.随机弹出
      preventOnHookFlag: projectWatchRule
        ? projectWatchRule.preventOnHookFlag
        : 0,
      // 弹出次数
      randomPopUpNum: projectWatchRule ? projectWatchRule.randomPopUpNum : 0,
      // 放挂机提示语
      onHookTipInfo: projectWatchRule
        ? projectWatchRule.onHookTipInfo
        : "辛苦，请继续观看！",
      // 防多开设置
      preventConcurrentPlayFlag: projectWatchRule
        ? projectWatchRule.preventConcurrentPlayFlag
        : 0,
      videoSource,
      videoDuration: duration,
    });
    // 如果是阿里云视频播放
    if (videoSource === 3) {
      setVideoSource("");
      setVideoSource(videoSource);
      const sectionItem = courseInfo.sectionList[index];
      initAliPlayer(sectionItem);
    } else {
      setVideoSource(videoSource);
      if (!window.ccPlayerStatus) {
        CCvideoControlInstance.pause();
        CCvideoControlInstance.reset();
        window.pauseTimer = null;

        initVideo(
          videoUrl,
          siteid,
          canDragFlag,
          videoWatchProgress === "100" ? 0 : videoWatchTime,
          fastForwardFlag,
          videoWaterMask
        );
      }
    }
  };
  /**
   * 初始化阿里云
   * @param videoId
   */
  const initAliPlayer = async (sectionItem: any) => {
    const { videoUrl, videoWatchTime } = sectionItem;
    const { data } = await post(
      "/shop/front/ali/get/upload/play/auth",
      { videoId: videoUrl },
      false
    );
    const { playAuth } = data;
    // @ts-ignore
    window.aliPlayer = new Aliplayer(
      {
        id: "aliLivePlayer",
        vid: videoUrl,
        playauth: playAuth,
        qualitySort: "asc",
        format: "mp4",
        mediaType: "video",
        width: "100%",
        height: "100%",
        autoplay: false,
        isLive: false,
        rePlay: false,
        preload: true,
        useH5Prism: true,
        x5_type: "h5",
        // 启动同层播放
        playsinline: false,
        controlBarVisibility: "always",
      },
      (player: any) => {
        // 设置上次播放的时间
        player.seek(videoWatchTime);
        // 设置倍速是否被允许
        AlivideoControlInstance.initAliPlayer(player); // 初始化阿里云播放相关方法
      }
    );
  };
  const chooseSetVideoFn = () => {
    if (type === "myself" || type === "ourHospital") {
      getMyself();
    } else if (type === "public") {
      getPublic();
    } else {
      getSectionInfo();
    }
  };
  const onChange = ({ target: { value } }: any) => {
    setContent(value);
  };
  const showModal = () => {
    setContent("");
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const addNotes = () => {
    const { sectionId } = videoInfo;
    let type;
    const paramType = query.get("type");
    if (paramType === "public") {
      type = 2;
    } else if (paramType === "myself" || paramType === "ourHospital") {
      type = 3;
    } else {
      type = 1;
    }
    let param = {
      courseId: Number(courseId),
      sectionId: Number(sectionId),
      type,
      content,
    };
    if (projectId) {
      param["projectId"] = projectId;
    }
    if (content) {
      if (content.length > 1000) {
        message.info("笔记最多为1000字");
        return;
      }
      const url = `/shop/front/addUserLearnNote`;
      post(url, param, false).then((res) => {
        message.info("提交成功");
        handleCancel();
      });
    } else {
      message.info("笔记不能为空");
    }
  };
  const getCourseQuestionnaireUrl = () => {
    if (hasQuestionnaireFlag !== 1) {
      return;
    }
    post(
      `/shop/questionnaire/getCourseStarQuestionnaireUrl/${courseId}`,
      {},
      true
    ).then((res) => {
      const { questionnaireUrl } = res.data;
      window.location.href = questionnaireUrl;
    });
  };
  const getExamUrl = () => {
    if (hasExamFlag !== 1) {
      return;
    }
    examSystemType === 1 ? getExamType4Url() : getExamType5Url();
  };
  // 获取4.0课程考试链接
  const getExamType4Url = () => {
    post(`/shop/exam/getCourseExamUrl/${courseId}`, {}, true).then((res) => {
      const { courseExamUrl } = res.data;
      window.location.href = courseExamUrl;
    });
  };
  // 获取5.0课程考试链接
  const getExamType5Url = () => {
    post(`/shop/exam/getCourseExamPaperInfo`, { courseId }, true).then(
      (res) => {
        const { REACT_APP_BASE_URL, REACT_APP_EXAM_URL } = process.env;
        window.location.href = `${REACT_APP_BASE_URL}${REACT_APP_EXAM_URL}/exam-list/examList?fromType=courseExamForC&&courseId=${courseId}`;
      }
    );
  };
  /**
   * 切换视频
   * @param item
   * @param index
   */
  window.video_end = function () {
    let { index } = currentSectionInfo;
    const item = sectionList.list[index + 1];
    if (item && item.type === 2) {
      checkoutVideo(item, index + 1);
    } else {
      return;
    }
  };
  const setStartTime = (id?: number) => {
    let data = {
      time: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      sectionId: id || currentSectionInfo.sectionId,
      type: currentSectionInfo.type,
    };
    sessionStorage.setItem("currentSection", JSON.stringify(data));
  };
  const durationRecordFn = () => {
    let currentSection = JSON.parse(
      sessionStorage.getItem("currentSection") || ""
    );
    let typeText = query.get("type");
    if (currentSection.type === "pdf") {
      setEndTime(new Date());
      const data = {
        watchBeginTimeText: currentSection.time,
        watchEndTimeText: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        projectId: Number(projectId) || null,
        topicId,
        courseId,
        sectionId: currentSection.sectionId || null,
      };
      let url =
        typeText === "ourHospital"
          ? "/shop/course/pdf/watch/record/save"
          : "/shop/project/pdf/watch/record/save";
      post(url, { ...data }, false);
    }
  };
  useEffect(() => {

    chooseSetVideoFn();
    return () => {
      try {
        let currentSection = JSON.parse(
          sessionStorage.getItem("currentSection") || ""
        );
        console.log("currentSection", currentSection);

        if (currentSection && currentSection.type === "pdf") {
          durationRecordFn();
          sessionStorage.removeItem("currentSection");
        }
      } catch (error) {
        console.log("error", error);
      }
      window.ccPlayerStatus && window.ccPlayerStatus.destroy();
      window.ccPlayerStatus = null;
      window.video_end = null;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (currentSectionInfo.type === "pdf") {
      console.log(currentSectionInfo);
      setStartTime(currentSectionInfo.sectionId);
    }
  }, [currentSectionInfo]);

  /**
   * 切换视频或者pdf
   * @param item
   * @param index
   */
  const checkoutVideo = (item: ListPropsType, index: number) => {
    const { type, id } = item;
    currentSectionInfo = {
      index,
      type: type === 2 ? "video" : "pdf",
      sectionId: id,
    };
    // durationRecordFn();
    //如果当前是视频
    if (type === 2) {
      // 切换页面调用watch(3)进行暂停记录
      window["playing"] && CCvideoControlInstance.watch(3);
      window["aliPlaying"] && AlivideoControlInstance.watch(3);

      window.ccPlayerStatus && window.ccPlayerStatus.destroy();
      window.ccPlayerStatus = null;
      chooseSetVideoFn();
    } else {
      setCurrentSectionInfo({
        ...currentSectionInfo,
      });
      setTimeout(() => {
        // livePlayerRef.current.src = getPdfUrlBySectionId(id);
        // livePlayerRef.current.style.width = "100%";
        setLivePlayer(getPdfUrlBySectionId(id));
      });
    }
  };
  /**
   * 获取pdf地址
   * @param id
   */
  const getPdfUrlBySectionId = (id: number) => {
    return `${REACT_APP_PDF_DOMAIN}/static/common/pdfjs/generic/web/viewer.html?file=${REACT_APP_PDF_URL}/shop/section/file/preview/${id}`;
  };

  //
  const onCommentClickHandle = () => {
    let uri = `#/comments?courseId=${courseId}&evaluateFormId=${
      sectionList.evaluateFormId
    }${projectId ? "&projectId=" + projectId : ""}${
      topicId ? "&topicId=" + topicId : ""
    }`;
    window.open(uri);
  };

  useEffect(() => {
    chooseSetVideoFn();
    return () => {
      // 直接返回页面的情况，调用watch(3)进行record
      window["playing"] && CCvideoControlInstance.watch(3);
      window["aliPlaying"] && AlivideoControlInstance.watch(3);

      window.ccPlayerStatus && window.ccPlayerStatus.destroy();
      window.ccPlayerStatus = null;
      window.video_end = null;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div className="video_title">
        <p
          className="left"
          onClick={() => {
            routeHistory.go(-1);
          }}
        >
          <LeftOutlined />
          &emsp;<p>{sectionList.courseName}</p>
        </p>
        <p
          style={{ fontSize: "20px" }}
          onClick={() => {
            history.push("/personal");
          }}
        >
          个人中心
        </p>
      </div>
      <div className="playInfo_box">
        {currentSectionInfo.type === "video" && (
          <>
            {videoSource === 1 && (
              <div id="livePlayer" className="disableFullsBtns"></div>
            )}
            {videoSource === 3 && (
              <div id="aliLivePlayer" className="disableFullsBtns"></div>
            )}
          </>
        )}
        {currentSectionInfo.type === "pdf" && (
          <iframe
            // src=""
            scrolling="false"
            // ref={livePlayerRef}
            src={livePlayer}
            title="pdf"
            // key={Math.random()}
          ></iframe>
        )}
        <div className="video_info">
          <div style={{ paddingBottom: "60px", overflowY: "scroll" }}>
            <div
              className="project_name_box"
              style={{
                background: "#999999",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                fontSize: "16px",
                color: "#fff",
                height: "42px",
                lineHeight: "42px",
              }}
            >
              {/* <h6>{sectionList.courseName}</h6>
              <img
                src={sectionList.userPraiseFlag ? like : cancalLike}
                alt="点赞"
                className="live_style"
                onClick={saveUserPraiseInfo}
              /> */}
              课件列表
              {sectionList.progress && (
                <span
                  style={{ marginLeft: "20px" }}
                  className="refresh_box"
                  onClick={() => {
                    chooseSetVideoFn();
                  }}
                >
                  <Space>
                    <Icon icon="circle-notch" />
                    <a style={{ color: "#fff", fontSize: "14px" }}>刷新进度</a>
                  </Space>
                </span>
              )}
            </div>

            <p className="show-info">
              {sectionList.list &&
                sectionList.list.map((item, index) => (
                  <div
                    onClick={() => {
                      checkoutVideo(item, index);
                    }}
                    style={{
                      marginTop: "10px",
                      cursor: "pointer",
                      wordBreak: "break-all",
                      width: "100%",
                    }}
                  >
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {item.type === 2 && (
                          <YoutubeOutlined style={{ fontSize: "14px" }} />
                        )}
                        {item.type === 4 && (
                          <FilePdfOutlined style={{ fontSize: "14px" }} />
                        )}
                        <Tooltip placement="topLeft" title={item.name}>
                          <span
                            className={
                              currentSectionInfo.index === index ? "check" : ""
                            }
                            style={{
                              fontSize: "14px",
                              marginLeft: "10px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            {item.name}
                          </span>
                        </Tooltip>
                      </div>
                      {item.type === 2 && (
                        <Space>
                          <span>
                            {item.durationText}·
                            {item.videoWatchProgress === "100"
                              ? "已学完"
                              : `已学习${item.videoWatchProgress}%`}
                          </span>
                        </Space>
                      )}
                    </>
                  </div>
                ))}
            </p>

            <div>
              <div>
                <div
                  style={{
                    background: "#999999",
                    color: "#fff",
                    fontSize: "16px",
                    height: "42px",
                    lineHeight: "42px",
                    padding: "0 10px",
                  }}
                >
                  讲师介绍
                </div>
                {videoTeacherInfoList.photo && (
                  <div className="teacher_info">
                    <div>
                      <img
                        style={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "50%",
                        }}
                        src={
                          videoTeacherInfoList.photo ===
                          "https://mvw-shiziimg.oss-cn-beijing.aliyuncs.com"
                            ? teacherDefaultAvator
                            : videoTeacherInfoList.photo
                        }
                        alt=""
                      />
                    </div>
                    <div style={{ marginLeft: "20px", fontSize: "16px" }}>
                      <div>{videoTeacherInfoList.name}</div>
                      <div>{videoTeacherInfoList.title}</div>
                    </div>
                  </div>
                )}
                <div style={{ padding: "10px 8px" }}>
                  <div
                    className="teacher_detail"
                    dangerouslySetInnerHTML={{
                      __html: videoTeacherInfoList.content
                        ? videoTeacherInfoList.content
                        : "<div style='text-align:center;font-size:14px'>本课程暂无讲师介绍</div>",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div>
              <div
                style={{
                  background: "#999999",
                  color: "#fff",
                  fontSize: "16px",
                  height: "42px",
                  lineHeight: "42px",
                  padding: "0 10px",
                }}
              >
                课程介绍
              </div>
              <div
                style={{ paddingLeft: "10px" }}
                dangerouslySetInnerHTML={{
                  __html: sectionList.courseContent,
                }}
              ></div>
            </div>
          </div>

          <ul
            style={{
              position: "absolute",
              bottom: "0",
              left: 0,
              width: "100%",
            }}
          >
            <li onClick={showModal}>记笔记</li>
            {(type === "project" || type === "topic") &&
            sectionList.evaluateFormId ? (
              <li onClick={onCommentClickHandle}>点评</li>
            ) : null}
            {type !== "project" && type !== "topic" && (
              <li onClick={getCourseQuestionnaireUrl}>
                {hasQuestionnaireFlag !== 1 ? "无评价" : "课程评价"}
              </li>
            )}
            {type !== "project" && type !== "topic" && (
              <li onClick={getExamUrl}>
                {hasExamFlag !== 1 ? "无练习" : "课程练习"}
              </li>
            )}
          </ul>
        </div>
      </div>
      <Modal
        title="笔记"
        footer={null}
        closable={true}
        visible={isModalVisible}
        className="notesModal"
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <TextArea
          value={content}
          placeholder=""
          onChange={onChange}
          showCount
          maxLength={1000}
          autoSize={true}
          className="notes_modal"
          style={{ height: 300 }}
        />
        <div style={{ textAlign: "right", padding: "40px 0 20px" }}>
          <Button
            type="primary"
            onClick={() => {
              addNotes();
            }}
            style={{ cursor: "pointer" }}
          >
            提交
          </Button>
        </div>
      </Modal>
    </>
  );
}
const getNavStatus = (state: {
  videoStatus: VideoProps;
  projectType: { type: number; activityId?: string };
}) => {
  return {
    videoInfo: state.videoStatus,
    projectType: state.projectType,
  };
};

const playFnProps = (dispatch: any) => {
  return {
    setVideoStatus: (param: VideoProps) => {
      dispatch({ ...param });
    },
  };
};
export default connect(getNavStatus, playFnProps)(Play);
