/*
 * @Author: changcheng
 * @LastEditTime: 2023-08-22 09:59:44
 */
import { useEffect, useState } from "react";
import "./_style.scss";

interface segmentedProps {
  datasourse: any[];
  selected?: number;
  onChange?: any;
}

const Segmenteds: React.FC<segmentedProps> = (props) => {
  const [active, setActive] = useState<number | null>(null);

  const { datasourse, selected } = props;
  console.log("datasourse", datasourse);

  const getClass = (key: number | null, index: number) => {
    let str = "mvw_segmenteds_item";

    if (key === active) {
      str += " mvw_segmenteds_item-selected";
    }

    return str;
  };

  const onItemClickHandle = (key: number) => {
    setActive(key);
    props.onChange(key);
  };

  useEffect(() => {
    if (!selected) {
      setActive(datasourse[0].key);
    } else {
      setActive(selected);
    }
  }, []); //eslint-disable-line

  return (
    <>
      {datasourse.length > 1 && (
        <div className="mvw_segmenteds">
          {datasourse.map((item, index) => {
            return (
              <span
                key={index}
                className={getClass(item.key, index)}
                onClick={() => {
                  onItemClickHandle(item.key);
                }}
                style={{
                  display: item.show === false ? "none" : "block",
                }}
              >
                {item.title}
              </span>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Segmenteds;
