/*
 * @Author: changcheng
 * @LastEditTime: 2022-08-25 16:47:52
 */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { post } from "../../../../common/fetch";
import { Collapse,Pagination  } from "antd";
import { isTypeNode } from "typescript";
interface propsType {
  id: number;
  setProjectCourseInfo: (arg: any) => void;
}
const { Panel } = Collapse;
const TopicCourseTrainLog = <T extends propsType>(props: T) => {
  const { id, setProjectCourseInfo } = props;
  const [studyLogList, setStudyLogList] = useState<any>([]);
  const [collapseList,setCollapseList] = useState<any>(["0"])
  const showCourseTrainDetail =() => {
    const url = `/shop/front/user/project/topicRecordList`;
    post(url, {projectId:id}, true).then((res) => {
      const { data } = res;
      post(`/shop/front/user/project/topic/courseRecordList`,{projectId:id,topicId:data[0].topicId,page: {currentPage: 1, pageSize: 4}},true).then(res=>{
        const list = res.data.courseWatchRecordList
        data[0].children = [...list]
        data[0].page = {
          currentPage:1,
          total: res.data.page.totalResultSize,
          pageSize:4
        }
        setStudyLogList(data);
      })
    });
  };
  function getChildren(index:number){
    let data = [...studyLogList]
    if(data[index].children){
      return 
    }
    post(`/shop/front/user/project/topic/courseRecordList`,{projectId:id,topicId:data[index].topicId,page: {currentPage: 1, pageSize: 4}},true).then(res=>{
      const list = res.data.courseWatchRecordList
      data[index].children = [...list]
      data[index].page = {
        currentPage: res.data.page.currentPage,
        total: res.data.page.totalResultSize,
        pageSize:res.data.page.pageSize 
      }
      setStudyLogList(data);
    })
  }
  function onChange(page:Number,item:any,index:number){
    let data = [...studyLogList]
    data[index].page.currentPage=page 
    post(`/shop/front/user/project/topic/courseRecordList`,{projectId:id,topicId:data[index].topicId,page: {currentPage: page, pageSize: item.page.pageSize}},true).then(res=>{
      const list = res.data.courseWatchRecordList
      data[index].children = [...list]
      data[index].page = {
        currentPage: res.data.page.currentPage,
        total: res.data.page.totalResultSize,
        pageSize:res.data.page.pageSize 
      }
      setStudyLogList(data);
    })
  }
  useEffect(() => {
    showCourseTrainDetail();
  }, []);
  // useEffect(()=>{
  //   showCourseTrainDetail();
  // },[collapseList])
  return (
    // <Modal
    //   title="日志详情"
    //   closable={true}
    //   footer={null}
    //   visible={true}
    //   onCancel={() => {
    //     setProjectCourseInfo({
    //       type: "topicCourse",
    //       projectId: 0,
    //       show: false,
    //     });
    //   }}
    //   width="800px"
    // >
    <div style={{background:"#fff",padding:"20px"}}>
       <h5>培训详情<span style={{float:"right", cursor: "pointer",color:"#2a82e4"}} onClick={()=>{
         setProjectCourseInfo({
                type: "topicCourse",
                projectId: 0,
                show: false,
        });
       }}>返回</span></h5>
            {studyLogList.map((item: any, index: number) => (
            <Collapse key={index} defaultActiveKey={collapseList} onChange={(val:any)=>{
              getChildren(index)
            }}>
   
          <Panel header={item.topicName} key={index}>
            {item.children&&item.children.map((it: any, idx: number) => (
              <div key={idx}>
              <p style={{display:"flex",justifyContent:"space-around"}}>
                <span style={{flex:"2",overflow:"hidden",textOverflow:'ellipsis',whiteSpace: 'nowrap',padding:"0 10px" }}>课程名称：{it.courseName}</span>&emsp;
                <span  style={{flex:"1"}}>课程时长：{it.courseDurationText}</span>
                &emsp;
                <span  style={{flex:"1"}}>学习进度：{it.courseLearnProgressText}</span>
              </p>
              </div>
            ))}
            {console.log(item.page)}
            {item.page&&<div style={{display:"flex",justifyContent:"flex-end"}}>
            <Pagination current={item.page.currentPage} pageSize={item.page.pageSize} total={item.page.total} onChange={(val)=>onChange(val,item,index)}>
              </Pagination></div>}
          </Panel>
      </Collapse>
        ))}

      <div style={{display:"flex",justifyContent:"flex-end",marginTop:"20px"}}>
      </div>
      </div>
    // </Modal>
  );
};
export { TopicCourseTrainLog };
