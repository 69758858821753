/*
 * @Author: cc
 * @LastEditTime: 2023-11-15 13:16:58
 */
import { useEffect, useState } from "react";
import classNames from "classnames";
import "./_style.scss";
import logo from "../../img/logo_header.png";
import doctorAssLogo from "../../img/doctorAssLogo.png";
import { connect } from "react-redux";
import { UserProps } from "../../reducers/video";
import { HistoryPush, getCookie, useQuery } from "../../common/utils";
import { post } from "../../common/fetch";
import { Dropdown, Menu, Button } from "antd";
import { OrganProps } from "../../actions/index";
import { DownOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import ActivityEntry from "./components/ActivityEntry";
import store from "../../reducers/index";
const Header: React.FC = (props: any) => {
  let path: any = useLocation();
  const query = useQuery();
  const projectId = query.get("projectId");
  const history = HistoryPush();
  const [instituteRoleInfo, setInstituteInfo] = useState<any>({
    currentInstituteName: "",
    currentInstituteId: "",
    instituteRoleList: [],
  });
  interface ProjectInfoProps {
    showLogoFlag?: number;
    showIndexUrlFlag?: number;
    showPersonCenterFlag?: number;
    projectAllPathLogo: any;
  }
  const [projectInfo, setProjectInfo] = useState<ProjectInfoProps>(
    store.getState().projectInfo
  );
  store.subscribe(() => {
    setProjectInfo(store.getState().projectInfo);
  });
  const cnames = classNames("mvw-header");
  let sid = getCookie("X-MVW-userID");
  const upDateOrgan = (currentInstituteId: string) => {
    post(
      `/user/front/changeCurrentInstitute`,
      { currentInstituteId },
      true
    ).then((res) => {
      window.location.reload();
    });
  };
  // 获取用户机构
  const getUserInstituteList = () => {
    if (path.pathname !== "/login" && sid !== null) {
      post(`/shop/front/userInstituteList`, {}, true).then((res) => {
        let { data } = res;
        data.instituteRoleList = data.instituteRoleList.map(
          (item: { instituteName: string; instituteId: string }) => {
            return {
              label: item.instituteName,
              value: item.instituteId,
            };
          }
        );
        setInstituteInfo({
          currentInstituteName: data.currentInstituteName,
          currentInstituteId: data.currentInstituteId,
          instituteRoleList: data.instituteRoleList,
        });
      });
    }
  };

  const loginOutMenu = (
    <Menu>
      <Menu.Item>
        <a
          rel="noopener noreferrer"
          onClick={() => {
            history.push("/personal");
          }}
        >
          个人中心
        </a>
      </Menu.Item>
      {/* <Menu.Item>
        <a rel="noopener noreferrer" onClick={loginOut}>
          退出
        </a>
      </Menu.Item> */}
    </Menu>
  );
  useEffect(() => {
    const { pathname } = path;
    if (pathname === "/" || pathname === "/train") {
      getUserInstituteList();
    }
  }, [path.pathname]); // eslint-disable-line react-hooks/exhaustive-deps
  const jumpPerson = () => {
    history.push("/personal");
  };
  const pathArray = [
    "/login",
    "/play",
    "/comments",
    "/register",
    "/platfromplay",
    "/modifyPassWord",
  ];
  return (
    <>
      {/* {(path.pathname  === '/' || path.pathname  === '/train') && <img src={happyNewYear} alt="" style={{width:"100%"}}  onClick={()=>{
        const url = "http://qt.mvwchina.com/vj/r4ag1gh.aspx"
          window.location.href = url;
      }}/>} */}
      {!pathArray.includes(path.pathname) && (
        <div className="header-box">
          <div className={cnames}>
            <div
              className="logo_box"
              onClick={() => {
                history.push("/train");
              }}
            >
              {projectInfo.showLogoFlag === 1 && (
                <div>
                  {projectId === "1345" ? (
                    <img src={doctorAssLogo} alt="" width={"240px"} />
                  ) : (
                    <img
                      src={
                        projectInfo.projectAllPathLogo
                          ? projectInfo.projectAllPathLogo
                          : logo
                      }
                      alt=""
                    />
                  )}
                </div>
              )}
              {sid && <ActivityEntry />}
            </div>
            <div style={{ display: "flex" }}>
              {sid &&
                (path.pathname === "/train" || path.pathname === "/") &&
                instituteRoleInfo.instituteRoleList.length > 0 && (
                  <div className="mechanism_box">
                    <Dropdown
                      overlay={
                        <Menu>
                          {instituteRoleInfo.instituteRoleList.map(
                            (item: any, index: number) => (
                              <Menu.Item key={index}>
                                <a
                                  rel="noopener noreferrer"
                                  style={{
                                    color:
                                      item.value ===
                                      instituteRoleInfo.currentInstituteId
                                        ? "blue"
                                        : "",
                                  }}
                                  onClick={() => {
                                    upDateOrgan(item.value);
                                  }}
                                >
                                  {item.label}
                                </a>
                              </Menu.Item>
                            )
                          )}
                        </Menu>
                      }
                      placement="bottomLeft"
                      arrow
                    >
                      <div>
                        <span>机构选择：</span>
                        <span style={{ fontSize: "14px" }}>
                          {instituteRoleInfo.currentInstituteName}
                        </span>
                        <DownOutlined
                          style={{ fontSize: "12px", marginLeft: "6px" }}
                        />
                      </div>
                    </Dropdown>
                  </div>
                )}
              {sid &&
                path.pathname !== "/modifyPassWord" && path.pathname !== "/error" &&
                projectInfo.showPersonCenterFlag === 1 && (
                  <div
                    className="user_center"
                    onClick={() => {
                      jumpPerson();
                    }}
                  >
                    <span style={{ fontSize: "14px" }}>个人中心</span>
                  </div>
                )}
              {!sid && path.pathname !== "/modifyPassWord" && (
                <Button
                  onClick={() => {
                    history.push("/login");
                  }}
                  type="primary"
                >
                  登录
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const upDateUserInfo = (dispatch: any) => {
  return {
    upDateUserInfo: (param: UserProps) => {
      dispatch({ ...param });
    },
    updateOrganInfo: (param: OrganProps) => {
      dispatch({ ...param });
    },
  };
};
const getNavStatus = (state: {
  navOptions: { show: boolean };
  userInfo: UserProps;
  organInfo: OrganProps;
}) => {
  return {
    navOptions: state.navOptions,
    userInfo: state.userInfo,
  };
};
export default connect(getNavStatus, upDateUserInfo)(Header);
