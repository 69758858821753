/*
 * @Author: cc
 * @LastEditTime: 2023-11-20 17:43:06
 */
import { createRef, useEffect, useState } from "react";
import { Form, Input, Button, message } from "antd";
import { UserOutlined, UnlockOutlined } from "@ant-design/icons";
import { useGetRandomCode } from "../../hooks/randomCode";
import { encode } from "js-base64";
import { post, get } from "../../common/fetch";
import { FormInstance } from "antd/lib/form";
import { useHistory } from "react-router-dom";
import logo from "../../img/logo.png";
import "./index.scss";
const { Search } = Input;
interface modifProps {
  mobile: string;
  randomCode?: string;
  smsCode: string;
  password: string;
  newPassword: string;
}
const PassWord: React.FC = () => {
  const [randomImgUrl, setRandomImgUrl] = useState<string>("");
  const history = useHistory();
  const formRef = createRef<FormInstance>();
  const onFinish = (props: modifProps) => {
    const { mobile, smsCode, password, newPassword } = props;
    if (newPassword !== password) {
      message.info("两次输入密码不一致");
    } else {
      post(
        `/user/api/front/modifyPassword`,
        {
          mobile: encode(mobile),
          smsCode: encode(smsCode),
          password: encode(password),
        },
        true
      ).then((res) => {
        message.info("修改成功");
        history.push("/login");
      });
    }
  };
  function getRandomImgFn() {
    fetch(`/main/ran/random`)
      .then(
        (response) => response.blob(),
        (error) => console.log("An error occurred.", error)
      )
      .then((blob: any) => {
        const url = window.URL.createObjectURL(blob);
        setRandomImgUrl(url);
      });
  }
  useEffect(() => {
    getRandomImgFn();
  }, []);
  const onFinishFailed = () => {};
  const getSmsCode = () => {
    const mobile = formRef.current!.getFieldValue("mobile");
    const randomCode = formRef.current!.getFieldValue("randomCode");
    if (!mobile) {
      message.info("请填写手机号");
      return;
    }
    if (!randomCode) {
      message.info("请填写图形验证码");
      return;
    }
    const RegRandomCode = randomCode.replace(/\s/g, "");
    post(
      `/user/api/front/smsCodeInfo`,
      {
        mobile: encode(mobile),
        randomCode: encode(RegRandomCode),
        type: encode("mvw"),
      },
      true
    ).then((res) => {
      if (res.code === 200) {
        message.info("发送验证码成功");
      }
    });
  };
  return (
    <>
      <div className="register_box">
        <div className="business">
          <img src={logo} alt="" />
        </div>
        <div className="submit_box">
          <p
            style={{
              color: "blue",
              marginTop: "12px",
              fontSize: "18px",
            }}
          >
            修改密码
          </p>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            ref={formRef}
          >
            <Form.Item
              name="mobile"
              rules={[{ required: true, message: "请输入手机号" }]}
            >
              <Input
                prefix={
                  <div className="icon_box">
                    <UserOutlined />
                  </div>
                }
                placeholder="请输入手机号"
              />
            </Form.Item>

            <Form.Item
              name="randomCode"
              rules={[{ required: true, message: "请输入图形验证码" }]}
            >
              <Search
                placeholder="请输入图形验证码"
                enterButton={
                  randomImgUrl && (
                    <img
                      src={randomImgUrl}
                      alt="code"
                      onClick={() => {
                        getRandomImgFn();
                      }}
                      style={{ width: "100px", height: "38px" }}
                    />
                  )
                }
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="smsCode"
              rules={[{ required: true, message: "请输入手机验证码" }]}
            >
              <Search
                placeholder="请输入手机验证码"
                enterButton={
                  <div
                    onClick={() => {
                      getSmsCode();
                    }}
                    style={{ padding: "0 6px" }}
                  >
                    获取验证码
                  </div>
                }
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "请输入密码" }]}
            >
              <Input.Password
                autoComplete="new-password"
                prefix={
                  <div className="icon_box">
                    <UnlockOutlined />
                  </div>
                }
                placeholder="密码长度8位以上至少包含大小写和数字"
              />
            </Form.Item>
            <Form.Item
              name="newPassword"
              rules={[{ required: true, message: "请输入新密码" }]}
            >
              <Input.Password
                prefix={
                  <div className="icon_box">
                    <UnlockOutlined />
                  </div>
                }
                placeholder="请再次输入新密码"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                style={{ height: "40px" }}
              >
                确认
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};
export default PassWord;
