/*
 * @Author: cc
 * @LastEditTime: 2023-07-18 15:03:07
 */
import { useHistory, useLocation } from "react-router-dom";
import { post } from "./fetch";
import store from "../reducers/index";
export const routeConfig = {
  "/train": { show: true },
  "/modifyPassWord": { show: false },
  "/login": { show: false },
  "/play": { show: false },
  "/classList": { show: true },
  "/projectInfo": { show: true },
  "/personal": { show: true },
};
export const HistoryPush = () => {
  let history = useHistory();
  return history;
};
export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
export function IsPC() {
  var userAgentInfo = navigator.userAgent;
  var Agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}
// 设置公共头隐藏
export function changeNavStatus(param: { show: boolean }): void {
  store.dispatch({
    type: "CHANGE_NAVSTATUS",
    ...param,
  });
}
/** 获取cookie
 * @param {*}
 * @return {null/string}
 */
export function getCookie(name: string) {
  var prefix = name + "=";
  var start = document.cookie.indexOf(prefix);

  if (start === -1) {
    return null;
  }

  var end = document.cookie.indexOf(";", start + prefix.length);
  if (end === -1) {
    end = document.cookie.length;
  }

  var value = document.cookie.substring(start + prefix.length, end);
  return unescape(value);
}
function getViodePopNumber() {
  const data = store.getState();
  const { randomPopUpNum } = data.videoStatus;
  return randomPopUpNum;
}

class videoPause {
  count: number;
  constructor() {
    this.count = getViodePopNumber();
  }
  start(video: any, position: any, time: any, videoStatus: any) {
    const {
      onHookTipInfo,
      videoUniqueCode,
      projectId,
      topicId,
      courseId,
      sectionId,
    } = videoStatus;
    if (window.pauseTimer === null && this.count > 0) {
      // 随机数
      let restTime = time - parseInt(position);
      let randonNum = Math.floor(Math.random() * (restTime - 0) + 0);
      window.pauseTimer = setInterval(() => {
        console.log("多少秒之后暂停弹窗", randonNum);
        console.log("剩余弹出次数", this.count);
        if (randonNum <= 0) {
          video.pause();
          this.pause();
          setTimeout(() => {
            let popupUniqueCode = new Date().getTime() + "";
            post(
              "/shop/front/addUserWatchPopupLog",
              {
                projectId,
                topicId,
                courseId,
                sectionId,
                videoUniqueCode,
                popupUniqueCode,
                popupType: 1,
              },
              false
            );
            alert(onHookTipInfo);
            this.count--;
            post(
              "/shop/front/addUserWatchPopupLog",
              {
                projectId,
                topicId,
                courseId,
                sectionId,
                videoUniqueCode,
                popupUniqueCode,
                popupType: 2,
              },
              false
            );
          }, 800);
        } else {
          randonNum--;
        }
      }, 1000);
    }
  }
  pause() {
    console.log("-----清空计时器-----");
    clearInterval(window.pauseTimer);
    window.pauseTimer = null;
  }
  reset() {
    // 设置弹出次数
    this.count = getViodePopNumber();
  }
}
export let ranDomNum = new videoPause();
export const watch = () => {
  const data = store.getState();
  const {
    videoStatus,
    projectType: { type, activityId },
  } = data;
  const {
    courseId,
    sectionId,
    projectId,
    videoType,
    preventOnHookFlag,
    topicId,
  } = videoStatus;
  if (!window.ccPlayerStatus) return;
  const videoDuration = parseInt(window.ccPlayerStatus.getDuration());
  const watchAt = parseInt(window.ccPlayerStatus.getPosition());
  if (!window.ccPlayerStatus.getDuration()) return;
  if (window.ccPlayerStatus.getPosition() === 0) return;
  //获取总时长
  let time = window.ccPlayerStatus.getDuration();
  let video = window.ccPlayerStatus;
  //获取视频进度
  let position = window.ccPlayerStatus.getPosition();
  // 调用随机弹窗的方法
  if (preventOnHookFlag === 2) {
    ranDomNum.start(video, position, time, videoStatus);
  }
  let url = "";
  let param = {
    courseId,
    sectionId,
    projectId: projectId ? projectId : "",
    videoDuration,
    watchAt,
    topicId: topicId ? topicId : "",
  };
  if (videoType === "myself" || videoType === "ourHospital") {
    url = "/shop/institute/course/watch/record";
  } else if (videoType === "public") {
    url = "/shop/course/watch/record";
  } else if (videoType === "topic") {
    url = "/shop/update/project/watch/record";
    param["topicId"] = topicId;
  } else if (type === 2) {
    url = "/shop/update/project/watch/record";
    param["activityId"] = activityId;
  } else {
    url = "/shop/update/project/watch/record";
  } //刷新视频进度
  post(url, param, false);
};

export function preventOpenMoreVideoFn(watchStatus: "1" | "0") {
  const data = store.getState();
  const { preventConcurrentPlayFlag } = data.videoStatus;
  // 0关闭 1开启
  if (preventConcurrentPlayFlag === 0) {
    return;
  }
  if (!window.ccPlayerStatus) return;
  let hashVal = sessionStorage.getItem("hashVal");
  if (!hashVal) {
    // 本地存储一个HashVal
    var timeStamp = new Date().valueOf().toString();
    sessionStorage.setItem("hashVal", timeStamp);
  }
  const { projectId } = data.videoStatus;
  post(
    `/shop/aboutWatchVideoController/watchingVideo`,
    {
      hashVal: sessionStorage.getItem("hashVal"),
      watchStatus,
      projectId,
    },
    false
  ).catch((result) => {
    // 必须是观看状态下
    if (result.code !== 0) {
      ranDomNum.pause();
      window.ccPlayerStatus.pause();
    }
  });
}

/**
 * 阿拉伯数字转中文
 * @param num 数字
 * @returns string
 */
export function intToChinese(num: number) {
  const arr1 = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
  const arr2 = [
    "",
    "十",
    "百",
    "千",
    "万",
    "十",
    "百",
    "千",
    "亿",
    "十",
    "百",
    "千",
    "万",
    "十",
    "百",
    "千",
    "亿",
  ];
  if (!num || isNaN(num)) return "零";
  const numStr = num.toString().split("");
  let result = "";
  for (let i = 0; i < numStr.length; i++) {
    const des_i = numStr.length - 1 - i;
    result = arr2[i] + result;
    const arr1_index = numStr[des_i];
    result = arr1[arr1_index] + result;
  }
  result = result.replace(/零(千|百|十)/g, "零").replace(/十零/g, "十");
  result = result.replace(/零+/g, "零");
  result = result.replace(/零亿/g, "亿").replace(/零万/g, "万");
  result = result.replace(/亿万/g, "亿");
  result = result.replace(/零+$/, "");
  result = result.replace(/^一十/g, "十");
  return result;
}
export function getBrowserName() {
  var u = navigator.userAgent;
  var bws = [
    {
      name: "sgssapp",
      it: /sogousearch/i.test(u),
    },
    {
      name: "wechat",
      it: /MicroMessenger/i.test(u),
    },
    {
      name: "weibo",
      it: !!u.match(/Weibo/i),
    },
    {
      name: "uc",
      it: !!u.match(/UCBrowser/i) || u.indexOf(" UBrowser") > -1,
    },
    {
      name: "sogou",
      it: u.indexOf("MetaSr") > -1 || u.indexOf("Sogou") > -1,
    },
    {
      name: "xiaomi",
      it: u.indexOf("MiuiBrowser") > -1,
    },
    {
      name: "baidu",
      it: u.indexOf("Baidu") > -1 || u.indexOf("BIDUBrowser") > -1,
    },
    {
      name: "360",
      it: u.indexOf("360EE") > -1 || u.indexOf("360SE") > -1,
    },
    {
      name: "2345",
      it: u.indexOf("2345Explorer") > -1,
    },
    {
      name: "ie11",
      it: u.indexOf("Trident") > -1 && u.indexOf("rv:11.0") > -1,
    },
    {
      name: "ie",
      it: u.indexOf("compatible") > -1 && u.indexOf("MSIE") > -1,
    },
    {
      name: "firefox",
      it: u.indexOf("Firefox") > -1,
    },
    {
      name: "qqbrowser",
      it: u.indexOf("MQQBrowser") > -1 && u.indexOf(" QQ") === -1,
    },
    {
      name: "qq",
      it: u.indexOf("QQ") > -1,
    },
    {
      name: "opera",
      it: u.indexOf("Opera") > -1 || u.indexOf("OPR") > -1,
    },
  ];

  for (var i = 0; i < bws.length; i++) {
    if (bws[i].it) {
      return bws[i].name;
    }
  }
  return "other";
}
