/*
 * @Author: changcheng
 * @LastEditTime: 2024-04-18 15:20:39
 */
import React from "react";
import { ExamGroup } from "./topicExamDoubleButton";
import { post } from "../../../../common/fetch";
import { Space, Pagination } from "antd";
import { VideoCameraTwoTone } from "@ant-design/icons";
interface CourseListProps {
  courseName: string;
  courseWatchTimeText: string;
  courseType: string;
  progress: string;
  hasQuestionnaireFlag: number;
  hasExamFlag: number;
  courseOrder: number;
  examStatus: number;
  projectId: number;
  courseId: number;
  courseLock: boolean;
  courseDurationText: string;
  userPaperScoreId: number;
  courseExamShowFlag: number;
  sectionList: Array<{
    id: number;
  }>;
}
interface CourseStateProps {
  courseList: CourseListProps[];
  page: number;
  totalPage: number;
  pageSize: number;
  totalResultSize: number;
}
type propsType = {
  projectId: string;
  topicId: string;
  projectInfo: any;
};
const trainStatus = new Map([
  [0, "未通过"],
  [1, "未通过"],
  [2, "已通过"],
  [4, "未通过"],
  [7, "未通过"],
]);

export class TopicCourseDoubleCourse extends React.Component<
  propsType,
  CourseStateProps
> {
  state = {
    courseList: [],
    page: 1,
    totalPage: 1,
    pageSize: 15,
    totalResultSize: 0,
  };
  // 问卷
  getProjectStarQuestionnaireUrl = async (rowData: CourseListProps) => {
    post(
      `/shop/questionnaire/getProjectTopicCourseStarQuestionnaireUrl`,
      rowData.projectId,
      true
    ).then((res: any) => {
      const { questionnaireUrl } = res.data;
      window.location.href = questionnaireUrl;
    });
  };
  private RenderCourseItem = (rowData: CourseListProps) => {
    return (
      <div className="topicCourseList">
        <p>{rowData.courseName}</p>
        <p>
          {rowData.courseWatchTimeText}·已学习{rowData.progress}
        </p>
        <p className="button_group">
          <ExamGroup
            Id={rowData.projectId}
            courseExamInfo={rowData as any}
            topicId={this.props.topicId}
            projectInfo={this.props.projectInfo}
          />
        </p>
      </div>
    );
  };
  private getMyCourseList = async (currentPage: number) => {
    const { projectId, topicId } = this.props;
    post(
      `/shop/front/project/topic/course/list`,
      {
        page: {
          currentPage: currentPage,
          pageSize: this.state.pageSize,
        },
        projectId,
        topicId,
      },
      true
    ).then((res: any) => {
      const { courseList, page } = res.data;
      const { totalResultSize } = page;
      this.setState({
        page: currentPage,
        courseList: courseList,
        totalResultSize,
      });
    });
  };
  public componentWillMount() {
    this.getMyCourseList(1);
  }
  render() {
    return (
      <>
        <div style={{ flex: 1 }}>
          {this.state.courseList.map((item: CourseListProps, index: number) => (
            <div
              className={
                item.courseLock
                  ? "topicCourseList_mask topicCourseList"
                  : "topicCourseList"
              }
              key={index}
            >
              <h5 style={{ display: "flex", alignItems: "center" }}>
                <VideoCameraTwoTone
                  twoToneColor={item.courseType === "course" ? "" : "red"}
                />
                &emsp;
                <span style={{ color: "#1890ff" }}>{item.courseOrder}</span>
                &emsp;{item.courseName}
              </h5>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Space>
                  {item.courseDurationText !== "00:00:00" && (
                    <span>课程时长：{item.courseDurationText}</span>
                  )}
                  {Number(item.progress) > 0 && (
                    <span>学习进度：{item.progress}%</span>
                  )}
                  {this.props.projectInfo.showCourseExamStatusFlag === 1 &&
                    item.courseExamShowFlag === 1 &&
                    item.hasExamFlag === 1 &&
                    item.courseType === "course" && (
                      <span>
                        &emsp;练习状态:
                        {trainStatus.get(item.examStatus)}
                      </span>
                    )}
                </Space>
                <p className="button_group">
                  <ExamGroup
                    Id={item.projectId}
                    courseExamInfo={item as any}
                    topicId={this.props.topicId}
                    projectInfo={this.props.projectInfo}
                  />
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="pagination-box">
          <h6>共{this.state.totalResultSize}门课程</h6>
          <Pagination
            total={this.state.totalResultSize}
            current={this.state.page}
            defaultPageSize={this.state.pageSize}
            showSizeChanger={false}
            onChange={(page) => {
              this.getMyCourseList(page);
              this.setState({
                page,
              });
            }}
          ></Pagination>
        </div>
      </>
    );
  }
}
