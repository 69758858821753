/*
 * @Author: changcheng
 * @LastEditTime: 2024-03-27 19:08:42
 */
import { SectionListProps } from "../../../../actions/index";
import { Space, Button, Modal, message } from "antd";
import { useHistory } from "react-router-dom";
import { post } from "../../../../common/fetch";
import { ExclamationCircleOutlined } from "@ant-design/icons";
export function ExamGroup(props: {
  Id: string | null;
  courseExamInfo: Partial<SectionListProps>;
  topicId: string;
  projectInfo: any;
}) {
  console.log("props", props);

  const { courseExamInfo, Id, topicId, projectInfo } = props;
  const history = useHistory();
  // 问卷
  const getQuestioUrl = async (item: any) => {
    const CnQuestionStatusMap = new Map([
      ["0", "不可以问卷"],
      ["2", "已完成"],
    ]);
    const { questionnaireStatus, courseId } = item;
    if (CnQuestionStatusMap.get(questionnaireStatus)) {
      message.info(CnQuestionStatusMap.get(questionnaireStatus));
      return;
    }
    post(
      `/shop/questionnaire/getProjectTopicCourseStarQuestionnaireUrl`,
      {
        projectId: Id,
        topicId,
        courseId,
      },
      true
    ).then((res: any) => {
      const { questionnaireUrl } = res.data;
      window.location.href = questionnaireUrl;
    });
  };
  //获取考试链接
  const getCourseExamUrl = async (
    it: Partial<SectionListProps>,
    courseCanExamNum: number,
    courseExamLimitFlag: number
  ) => {
    const { examSystemType } = it;
    // 如果剩余次数是0次，则不跳转，并且不是无限次
    if (courseCanExamNum === 0 && courseExamLimitFlag === 1) {
      return;
    }
    // examSystemType: 1(4.0)|2(5.0)
    if (examSystemType === 2) {
      const { REACT_APP_BASE_URL, REACT_APP_EXAM_URL } = process.env;
      window.location.href = `${REACT_APP_BASE_URL}${REACT_APP_EXAM_URL}/exam-list/examList?fromType=topicCourseExam&projectId=${Id}&topicId=${topicId}&courseId=${it.courseId}`;
    } else {
      const {
        data: { courseExamUrl },
      } = await post(
        `/shop/exam/getProjectTopicCourseExamUrl`,
        {
          projectId: it.projectId,
          topicId,
          courseId: it.courseId,
        },
        true
      );
      window.location.href = courseExamUrl;
    }
  };
  // 考试
  const checkCourseExamFlag = async (it: Partial<SectionListProps>) => {
    // 获取考试剩余次数
    const { courseCanExamNum, courseExamLimitFlag, courseExamLimitNum } =
      await checkCourseExam();
    if (courseExamLimitFlag === 1) {
      Modal.confirm({
        title: "提示",
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            <p>本场共{courseExamLimitNum}次机会</p>
            <p>当前剩余{courseCanExamNum}次</p>
          </div>
        ),
        okText: "确认",
        cancelText: "取消",
        onOk() {
          getCourseExamUrl(it, courseCanExamNum, courseExamLimitFlag);
        },
      });
    } else {
      getCourseExamUrl(it, courseCanExamNum, courseExamLimitFlag);
    }
  };

  const checkCourseExam = async (): Promise<{
    courseCanExamNum: number;
    courseExamLimitFlag: number;
    courseExamLimitNum: number;
  }> => {
    const { courseId } = courseExamInfo;
    const { data } = await post(
      `/shop/exam/checkProjectTopicCourseExam`,
      {
        projectId: Id,
        topicId,
        courseId,
      },
      true
    );
    const { courseCanExamNum, courseExamLimitFlag, courseExamLimitNum } = data;
    return {
      courseCanExamNum,
      courseExamLimitFlag,
      courseExamLimitNum,
    };
  };
  // 播放页面
  const play = () => {
    const { sectionList, courseId } = courseExamInfo;
    const sectionId = sectionList?.[0].id; // 默认选第一个视频
    history.push(
      `/play?projectId=${Id}&courseId=${courseId}&sectionId=${sectionId}&topicId=${topicId}&type=topic`
    );
  };
  /**
   *
   * @param item 项目考试记录
   */
  const examPaperRecord = (item: any) => {
    const { userPaperScoreId } = item;
    const { REACT_APP_BASE_URL, REACT_APP_EXAM_URL } = process.env;
    const examResultUrl = `${REACT_APP_BASE_URL}${REACT_APP_EXAM_URL}/record-list?userPaperScoreId=${userPaperScoreId}&type=projectExam`;
    window.open(examResultUrl);
  };
  return (
    <Space>
      {courseExamInfo.hasQuestionnaireFlag === 1 && (
        <Button
          type="primary"
          onClick={() => {
            getQuestioUrl(courseExamInfo);
          }}
        >
          评价
        </Button>
      )}
      {courseExamInfo.courseExamShowFlag === 1 &&
        courseExamInfo.hasExamFlag === 1 &&
        courseExamInfo.userPaperScoreId &&
        projectInfo.courseAnswerRecordShowFlag === 1 && (
          <Button
            type="primary"
            onClick={() => {
              examPaperRecord(courseExamInfo);
            }}
          >
            最新答题记录
          </Button>
        )}

      {courseExamInfo.courseExamShowFlag === 1 &&
        courseExamInfo.hasExamFlag === 1 && (
          <Button
            type="primary"
            disabled={courseExamInfo.examStatus === 0}
            onClick={() => {
              checkCourseExamFlag(courseExamInfo);
            }}
          >
            练习
          </Button>
        )}
      <Button type="primary" onClick={play}>
        播放
      </Button>
    </Space>
  );
}
